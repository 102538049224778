import React, { useContext } from 'react';
import NavLoginButton from 'components/NavLoginButton/NavLoginButton';
import tellerLogo from 'assets/teller-logo-full.svg';

const Nav = ({ firstLoad, links }) => {
  return (
    <div className="hidden flex-1 md:flex flex-row items-center justify-evenly md:justify-center font-medium font-sans space-x-4 md:space-x-8 lg:space-x-16">
      <a href="/" className="whitespace-nowrap h-full w-16 md:w-20">
        <img
          src={tellerLogo}
          alt="Teller logo"
          className="min-h-full max-h-full"
        />
      </a>
      {links.map((link) => {
        return (
          <a
            key={link.title}
            href={link.url}
            rel="noreferrer"
            target="_blank"
            className="flex-shrink sm:text-sm md:text-base hover:no-underline"
          >
            <div className="hover:text-green-03">{link.title}</div>
          </a>
        );
      })}
      <a
        href="https://app.teller.finance"
        rel="noreferrer"
        target="_blank"
        className=" hover:no-underline"
      >
        <div className="border-2 text-green-03 border-teal py-2 px-7  rounded-full transition duration-200 ease-in-out transform hover:scale-105 my-6">
          App
        </div>
      </a>
      <NavLoginButton />
    </div>
  );
};


export default Nav;
