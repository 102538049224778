import { uniqueId } from 'lodash';
import { ethers } from 'ethers';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import classNames from 'classnames';
import { decode } from 'he';
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import path from 'path'
import * as distributorFile from "../../ABIs/.nftDistribution.json"
import { dappContext } from 'contexts/DappContext/DappContext';

import Banner from 'components/Banner/Banner';
import Container from 'components/Container/Container';
import LazyImage from 'components/LazyImage/LazyImage';
import NavBar from 'components/NavBar/NavBar';
import GreenButton from 'components/GreenButton/GreenButton'
import ProcessingScreen from 'components/ProcessingScreen/ProcessingScreen';
import SecondaryButton from 'components/SecondaryButton/SecondaryButton'
import SuccessScreen from 'components/SuccessScreen/SuccessScreen';


import crystalBall from 'assets/crystal-ball.svg';
import bg from 'assets/wave.svg';
import nft1 from 'assets/nft-tier-1.jpg';
import nft2 from 'assets/nft-tier-2.jpg';
import nft3 from 'assets/nft-tier-3.jpg';
import clock from 'assets/ic20-time.svg';
import commingSoon from 'assets/Coming-soon.png';
import crystalBallGif from 'assets/crystal-ball.gif';
import eth from 'assets/ic24-currency-ethereum.svg';
import rarible from 'assets/ic24-rarible.svg';
import lock from 'assets/ic20-lock.svg';
import check from 'assets/ic16-check.svg';
import PngDiagram from 'assets/diagram.png';

import pdt2utc from 'utils/pdt2utc';

import './LandingPage.scss';

import ClaimNFTModal from "modals/ClaimNFTModal";
import useModal from "hooks/useModal";
import { useclaimableNFTDistributorContract } from 'actions/utils';

const BATCHES = [
  {
    // Start: 9am PDT, 03.27.21 (Saturday)
    // End: 9am PDT, 03.28.21 (Sunday)
    startTimestamp: pdt2utc(Date.UTC(2021, 2, 27, 9, 0, 0)),
    expiryTimestamp: pdt2utc(Date.UTC(2021, 2, 28, 7, 0, 0)),
    price: '1.0',
    src: nft1,
    id: '1',
    details: [
      {
        key: 'NFTs Available',
        values: ['Limited per contribution'],
      },
      {
        key: 'Base Loan Value',
        values: ['2,500 DAI'],
      },
      {
        key: 'Chances & Benefits',
        values: [
          'Share of Alpha launch APY',
          'Chance of increasing max loan',
          '1% of 5,000 DAI loan',
          '0.1% of 100,000 DAI loan',
          '0.01% of 1,000,000 DAI loan',
          '"Accidental Rare" Artwork',
        ],
      },
    ],
  },
  {
    // Start: 3pm PDT, 03.28.21 (Sunday)
    // End: 3pm PDT, 03.30.21 (Tuesday)
    startTimestamp: pdt2utc(Date.UTC(2021, 2, 28, 15, 0, 0)),
    expiryTimestamp: pdt2utc(Date.UTC(2021, 2, 30, 15, 0, 0)),
    price: '5.0',
    src: nft2,
    id: '2',
    details: [
      {
        key: 'NFTs Available',
        values: ['Limited per contribution'],
      },
      {
        key: 'Base Loan Value',
        values: ['12,500 DAI'],
      },
      {
        key: 'Chances & Benefits',
        values: [
          'Share of Alpha launch APY',
          'Chance of increasing max loan',
          '1% of 55,000 DAI loan',
          '0.1% of 250,000 DAI loan',
          '0.05% of 1,000,000 DAI loan',
          '"Rare" Artwork',
        ],
      },
    ],
  },
  {
    // Start: 5pm PDT, 03.30.21 (Tuesday)
    // End: 5pm PDT, 03.31.21 (Wednesday)
    startTimestamp: pdt2utc(Date.UTC(2021, 2, 30, 17, 0, 0)),
    expiryTimestamp: pdt2utc(Date.UTC(2021, 2, 31, 17, 0, 0)),
    price: '10.0',
    src: nft3,
    id: '3',
    details: [
      {
        key: 'NFTs Available',
        values: ['Limited per contribution'],
      },
      {
        key: 'Base Loan Value',
        values: ['25,000 DAI'],
      },
      {
        key: 'Chances & Benefits',
        values: [
          'Share of Alpha launch APY',
          'Chance of increasing max loan',
          '1% of 100,000 DAI loan',
          '0.1% of 500,000 DAI loan',
          '0.10% of 1,000,000 DAI loan',
          '"Rarest animated" Artwork',
        ],
      },
    ],
  },
];

const REWARDS = [
  {
    // Start: 9am PDT, 03.27.21 (Saturday)
    // End: 9am PDT, 03.28.21 (Sunday)
    startTimestamp: pdt2utc(Date.UTC(2021, 2, 27, 9, 0, 0)),
    expiryTimestamp: pdt2utc(Date.UTC(2021, 2, 28, 7, 0, 0)),
    price: '1.0',
    src: crystalBallGif,
    id: '1',
    name: 'Sunny Empire',
    button: true,
    details: [
      {
        key: 'Bio',
        values: ['A kaleidoscopic, hypnotic sun emitting droning melodies till the end of time.'],
      },
      {
        key: 'NFTs Available',
        values: ['1 per staked NFT prior to Nov. 5th'],
      },
    ],
  },
  {
    // Start: 3pm PDT, 03.28.21 (Sunday)
    // End: 3pm PDT, 03.30.21 (Tuesday)
    startTimestamp: pdt2utc(Date.UTC(2021, 2, 28, 15, 0, 0)),
    expiryTimestamp: pdt2utc(Date.UTC(2021, 2, 30, 15, 0, 0)),
    price: '5.0',
    src: commingSoon,
    id: '2',
    name: 'Coming Soon',
    details: [],
  },
  {
    // Start: 5pm PDT, 03.30.21 (Tuesday)
    // End: 5pm PDT, 03.31.21 (Wednesday)
    startTimestamp: pdt2utc(Date.UTC(2021, 2, 30, 17, 0, 0)),
    expiryTimestamp: pdt2utc(Date.UTC(2021, 2, 31, 17, 0, 0)),
    price: '10.0',
    src: commingSoon,
    id: '3',
    name: 'Coming Soon',
    details: [],
  },
];

const defaultNFTAmounts = { 1: 0, 2: 0, 3: 0 }

const LandingPage = () => {
  const history = useHistory();
  const LAUNCH_DATE = BATCHES[0].startTimestamp; //9am PDT, 03.27.21
  const SALE_END_TIME = BATCHES[BATCHES.length - 1].expiryTimestamp; // 11pm PDT, 04.01.21
  const [saleStage, setSaleStage] = useState(null);
  const [processing, setProcessing] = useState('');
  const [transactionHash, setTransactionHash] = useState('');
  const { successScreen, loadingMessage } = copy;
  const {
    address,
    isLoggedIn,
    setTransactions,
    transactions,
  } = useContext(dappContext);
  const scrollToTopOfCardsRef = useRef(null);


  useEffect(() => {
    const timeNow = Date.now();
    let newSaleStage;
    if (timeNow < LAUNCH_DATE) {
      newSaleStage = 1;
    } else if (timeNow > SALE_END_TIME) {
      newSaleStage = 3;
    } else {
      newSaleStage = 2;
    }
    setSaleStage(newSaleStage);
    console.log(`Sale stage calculated: ${newSaleStage}`);
  }, [LAUNCH_DATE, SALE_END_TIME]);

  useEffect(() => {
    if (!isLoggedIn ||!address
      || transactions !== null) return;

    const updateTransactions = async (userAddress) => {
      let etherscanProvider = new ethers.providers.EtherscanProvider();
      let address = '0x95143890162bd671d77ae9b771881a1cb76c29a4';
      let startBlock = 12075108;
      let endBlock = etherscanProvider.getBlockNumber();
      try {
        const transactions = await etherscanProvider
          .getHistory(address, startBlock, endBlock)
          .then((txs) => {
            if (txs && txs.length > 0) {
              const purchases = txs
                .filter((tx) => {
                    return tx.from.toLowerCase() == userAddress.toLowerCase();
                })
                .reduce(
                  (accumulator, currentValue) => {
                    const price = currentValue.value / (1e18).toString();
                    let batchID = BATCHES.find((batch) => batch.price == price)
                      .id;
                    accumulator[batchID] += 1;
                    return accumulator;
                  },
                  defaultNFTAmounts
                );
              return purchases;
            } else {
              return defaultNFTAmounts;
            }
          });
        setTransactions(transactions);
      } catch (err) {
        console.log(err);
      }
    };
    updateTransactions(address);
  }, [isLoggedIn, address]);

  const calculateTimeLeft = (saleStage) => {
    const endTime = saleStage === 1 ? LAUNCH_DATE : SALE_END_TIME;
    const difference = endTime - Date.now();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(saleStage));

  useEffect(() => {
    const interval1 = setInterval(() => {
      setTimeLeft(calculateTimeLeft(saleStage));
    }, 1000);

    return () => {
      clearInterval(interval1);
    };
  }, [saleStage]);

  return (
    <div className='flex bg-white text-center  min-h-screen'>
      {/*<SaleStageSelector setSaleStage={setSaleStage} saleStage={saleStage} />*/}
      <div className='relative flex-1'>
        <Banner />
        <NavBar />
        {!processing && !transactionHash && (
          <>
            <Container className="mt-12 mb-12 text-left">
              <div className="te-landing-page__hero__wrapper">
                <div className="te-landing-page__hero">
                  <div className="te-landing-page__hero__heading">
                    <h1 className="font-bold type-h1">
                      <span className="text-green-02">Fortune</span>
                      <br />
                      <span className="text-green-03">Teller&rsquo;s Sale</span>
                    </h1>
                  </div>
                  <p className="type-p-sm font-light text-black">
                    Fortune Teller NFTs are the first implementation of a real-world asset as a warranty on behalf of a loan. Holders of Fortune Tellers will have exclusive access to Teller's Alpha Launch. Each Fortune Teller NFT will unlock the ability to request an unsecured loan with a max loan size tied to a base loan amount. Higher tier NFTs offer larger base loan amounts.&nbsp;
                    <a
                      className="underline whitespace-nowrap"
                      href="https://medium.com/teller-finance/alpha-launch-5286e239f67a"
                      target="_blank"
                    >
                      Learn more &rarr;
                    </a>
                  </p>
                </div>
                <div className="te-landing-page__hero__cta-buttons">
                  {/*<div className="te-landing-page__hero__cta-button">*/}
                  {/*  <PrimaryButton*/}
                  {/*    className="xs:w-full"*/}
                  {/*    onClick={() => scrollToTopOfCardsRef?.current.scrollIntoView({*/}
                  {/*      behavior: 'smooth'*/}
                  {/*    })}*/}
                  {/*  >*/}
                  {/*    Buy Now*/}
                  {/*  </PrimaryButton>*/}
                  {/*</div>*/}
                  <div className="te-landing-page__hero__cta-button">
                    <SecondaryButton
                      className="xs:w-full"
                      onClick={() => history.push('/art-gallery')}
                    >
                      See Gallery
                    </SecondaryButton>
                  </div>
                </div>
              </div>
            </Container>
            <Container
              bg='bg-white'
              className='flex flex-col items-center justify-center z-20'
              variant='main'
            >
              {saleStage === 1 && <SignUpForm />}
              {/*{saleStage === 3 && (*/}
              {/*  <PrimaryButton icon={rarible} className='w-60'>*/}
              {/*    Buy on Rarible*/}
              {/*  </PrimaryButton>*/}
              {/*)}{' '}*/}
            </Container>
            <div className='relative'>
              {(saleStage === '1' || saleStage === '2') && (
                <img
                  className='absolute w-full z-10 overflow-hidden bg-white 2xl:-mt-36 xl:mt-4 md:mt-4 sm:mt-8 mt-16'
                  src={bg}
                  alt=''
                />
              )}
              {saleStage === '3' && (
                <img
                  className='absolute w-full z-10 overflow-hidden bg-white 2xl:mt-10 xl:mt-24 md:mt-24 sm:mt-32 mt-24'
                  src={bg}
                  alt=''
                />
              )}
            </div>
            {saleStage === 2 || saleStage === 3 && (
              <>
                <div className="mb-8 text-left">
                  <p className="type-h2 text-green-02 gap-5 md:gap-6 lg:gap-16 px-6 md:px-8 lg:px-16 max-w-screen-8xl 8xl:mx-auto">
                    NFT Rewards
                  </p>
                </div>
                <RewardCards
                  REWARDS={REWARDS}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                  scrollToTopOfCardsRef={scrollToTopOfCardsRef}
                />
                <div className="mb-8 text-left">
                  <p className="type-h2 text-green-02 gap-5 md:gap-6 lg:gap-16 px-6 md:px-8 lg:px-16 max-w-screen-8xl 8xl:mx-auto">
                    NFTs for sale
                  </p>
                </div>
                <BatchCards
                  BATCHES={BATCHES}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                  scrollToTopOfCardsRef={scrollToTopOfCardsRef}
                />
                <Container
                  bg="bg-white"
                  className="flex flex-col items-center justify-center z-10"
                  variant="main"
                >
                  {/*<Countdown*/}
                  {/*  eyebrow={saleStage === 1 ? 'Starting In' : 'Time Remaining'}*/}
                  {/*  timeLeft={timeLeft}*/}
                  {/*/>*/}
                </Container>
                <FAQ />
              </>
            )}
            {/*{saleStage === 3 && <RaribleCards BATCHES={BATCHES} />}*/}
          </>
        )}
        {transactionHash && processing === 'txConfirmed' ? (
          <SuccessScreen
            onButtonClick={() => {
              window.location.assign(`https://nft.teller.finance`);
              setTransactionHash('');
              setProcessing('');
            }}
            title={successScreen.title}
            message={
              <div className='flex flex-col align-center justify-start gap-3  '>
                <span
                  className='link underline'
                  onClick={() => {
                    window.open(
                      'https://medium.com/teller-finance/credit-economics-behind-the-fortune-teller-nft-sale-b4bc76b5d574'
                    );
                  }}
                >
                  Learn more
                </span>
                Go to dashboard or click {'  '}
                {'  '} about how to use your NFT.
                <br />
                Your NFT will be available to be claimed after the sale has
                completed.
              </div>
            }
          />
        ) : (
          processing && (
            <ProcessingScreen hash={transactionHash} status={processing} />
          )
        )}
      </div>
    </div>
  );
};

export default LandingPage;

const FAQ = () => {
  const FAQs = [
    {
      question: "How can I use NFT?",
      answer:
        "<p>Each Fortune Teller NFT will unlock the ability to take out one unsecured loan with a max loan size commensurate to a specific base loan amount. NFTs with higher tiers offer larger base loan amounts.</p>",
      hrefText: "Learn More",
      href:
        "https://medium.com/teller-finance/credit-economics-behind-the-fortune-teller-nft-sale-b4bc76b5d574",
      id: "1",
      image: PngDiagram,
    },
    {
      question: "What is the bonus threshold?",
      answer:
        '<p>Each tier has three bonus thresholds which will unlock a “lucky draw” — with a percent chance of increasing one’s max loan size to that pre-determined higher amount. Below is an example of % chances and potential payout.</p><p class="mt-3">The bonus threshold for Tier 1 will be unlocked at 1/(1% for a max $5,000 DAI Loan) = 100 contributions. This means that 1 of the 100 NFTs distributed will upgrade from a $5,000 DAI max loan to as high as $1,000,000 DAI.</p>',
      id: "2",
    },
    {
      id: "3",
      question: "What is the background behind Fortune Tellers sale?",
      answer:
        "<p>Fortune Teller is a digital art collectible series created by collaborating artists globally. Holders of the artwork can access Teller’s Alpha and stake their NFT as a credit to unlock unsecured loans within the protocol. Our sale marks the first NFT liquidity provisioning for DeFi, including future giveaways and protocol rewards.</p>",
      hrefText: "Learn More",
      href:
        "https://medium.com/teller-finance/credit-economics-behind-the-fortune-teller-nft-sale-b4bc76b5d574",
    },
    {
      id: "4",
      question: "How many NFTs are minted per tier?",
      answer:
        "<p>The number of Fortune Teller NFTs minted per category will be commensurate to the contributions received for that tier. Only one tier will be open at a time, with pre-set break times between the closing and opening of tiers. The final number of NFTs minted in that category will be set once the tier is closed.</p>",
    },
    {
      id: "5",
      question: "How will NFTs be distributed?",
      answer:
        '<p>To ensure a fair distribution, the user will not know what Fortune Teller they purchase until the sale period has ended. All NFTs will be generated post-sale and supplied into an NFT index fund (built on <a href="https://nftx.org/" target="_blank">NFTx</a> or <a href="https://nft20.io/" target="_blank">NFTY Museum</a>) to ensure randomness. Purchasers will receive one Fortune Teller Index Fund ERC20 per NFT purchased, which will be redeemable for an underlying Fortune Teller NFT specific to their Tier.</p>',
    },
    {
      id: "6",
      question: "When will the NFTs be distributed?",
      answer:
        "<p>All NFTs will be distributed after the sale completes starting the week of April, 5th, 2021, and all tiers and/or auctions have been closed. NFTs will be distributed via a claims process which will go live on our website.</p>",
    },
    {
      id: "7",
      question: "Will there be a 4th and 5th tier?",
      answer:
        "<p>If the first bonus threshold on each tier is activated, we will open up access for two more tiers — Rare (Tier 4) and Super Rare (Tier 5). These will be the most exclusive NFTs (based on limited quantity).</p>",
    },
  ];
  return (
    <Container className="flex flex-col faq-container text-left mt-24">
      <div>
        <p className="text-green-02 font-bold type-h1">Fortune Teller</p>
        <p className="text-green-03 font-bold type-h1 mb-6">FAQ</p>
      </div>

      {FAQs.map((item, i) => <FAQItem item={item} key={i} />)}
    </Container>
  );
};

const FAQItem = ({ item }) => (
  <div className="faq-drawer">
    <input
      className="faq-drawer__trigger"
      id={`faq-drawer-${item.id}`}
      type="checkbox"
    />

    <label
      className="faq-drawer__title type-p-sm font-medium text-green-02 hover:text-green-02 mb-3"
      htmlFor={`faq-drawer-${item.id}`}
    >
      {item.question}
    </label>
    <div className="faq-drawer__content-wrapper">
      <div className="faq-drawer__content type-p-sm font-light text-black">
        {parse(item.answer)}
        {item.image && (
          <LazyImage
            className="mt-3 lg:w-3/4"
            src={item.image}
          />
        )}
        {item.hrefText && (
          <u className="mt-3">
            <a
              className="hover:text-green-02"
              href={item.href}
              target="_blank"
            >
              {item.hrefText}
            </a>
          </u>
        )}
      </div>
    </div>
  </div>
);

const RaribleCards = ({ BATCHES }) => {
  return (
    <div className='relative z-10 my-20'>
      <div className='flex flex-col sm:hidden overflow-hidden'>
        <div className='flex flex-row items-center justify-center space-x-4 mb-4'>
          {BATCHES.slice(0, 3).map((nft) => (
            <NFTImage src={nft.src} />
          ))}
        </div>
        <div className='flex flex-row items-center justify-center space-x-4'>
          {BATCHES.slice(3).map((nft) => (
            <NFTImage src={nft.src} />
          ))}
        </div>
      </div>
      <Container className='hidden sm:flex'>
        <div className='flex flex-row space-x-4 lg:space-x-10 justify-center'>
          {BATCHES.map((nft, i) => (
            <div className={i % 2 === 0 ? 'mt-9' : ''}>
              <NFTImage src={nft.src} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};
const NFTImage = ({ src }) => {
  return (
    <img
      src={src}
      alt='Teller NFT'
      className='w-1/3 sm:w-full rounded opacity-70'
    />
  );
};
const BatchCards = ({
  BATCHES,
  setProcessing,
  setTransactionHash,
  context,
  scrollToTopOfCardsRef,
}) => {
  const [NFTs, setNFTs] = useState(BATCHES);

  const NFTsFirstRowLG = NFTs.slice(0, 3);
  const NFTsSecondRowLG = NFTs.slice(3);
  const NFTsFirstRowSM = NFTs.slice(0, 2);
  const NFTsSecondRowSM = NFTs.slice(2);

  useEffect(() => {
    BATCHES.forEach((b) => {
      console.log(
        b.id,
        ' start: ',
        new Date(b.startTimestamp).toLocaleString(undefined, {
          timeZone: 'America/Los_Angeles',
        }),
        ' end: ',
        new Date(b.expiryTimestamp).toLocaleString(undefined, {
          timeZone: 'America/Los_Angeles',
        }),
        ' PDT'
      );
    });

    const recalculateNFTdates = () => {
      const timeNow = Date.now();
      const updatedNFTs = NFTs.map((NFT) => {
        const timeTillExpiry = new Date(NFT.expiryTimestamp) - timeNow;
        const timeTillStart = new Date(NFT.startTimestamp) - timeNow;

        NFT.expired = Boolean(timeTillExpiry < 0);
        NFT.locked = Boolean(timeTillStart > 0);
        NFT.timeLeft = {
          hours: NFT.expired
            ? 0
            : Math.round(timeTillExpiry / (1000 * 60 * 60)),
          minutes: NFT.expired
            ? 0
            : Math.floor((timeTillExpiry / 1000 / 60) % 60),
        };
        NFT.timeTillStart = {
          hours: NFT.expired
              ? 0
              : Math.round(timeTillStart / (1000 * 60 * 60)),
          minutes: NFT.expired
              ? 0
              : Math.floor((timeTillStart / 1000 / 60) % 60),
        };
        return NFT;
      });
      setNFTs(updatedNFTs);
    };

    recalculateNFTdates();
    const interval = setInterval(() => {
      recalculateNFTdates();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="hidden md:flex flex-col mb-24 relative z-1" style={{zIndex: 1}}>
        <Container
          className="items-center justify-center z-10 mb-8"
          variant="cards-1"
        >
          {NFTsFirstRowLG.map((nft, k) => (
            <>
              {nft.timeLeft && nft.timeTillStart && (
                <BatchCard
                  key={`BatchCard-${k}`}
                  nft={nft}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                />
              )}
            </>
          ))}
        </Container>
        <Container
          className="items-center justify-center z-10 mb-8"
          variant="cards-2"
        >
          {NFTsSecondRowLG.map((nft, k) => (
            <>
              {nft.timeLeft && nft.timeTillStart && (
                <BatchCard
                  key={`BatchCard2-${k}`}
                  nft={nft}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                />
              )}
            </>
          ))}
        </Container>
      </div>
      <div className="md:hidden flex flex-col mb-24">
        <Container
          className="items-center justify-center z-10 mb-8"
          variant="cards-1"
        >
          {NFTsFirstRowSM.map((nft, k) => (
            <>
              {nft.timeLeft && nft.timeTillStart && (
                <BatchCard
                  key={`BatchCard3-${k}`}
                  nft={nft}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                />
              )}
            </>
          ))}
        </Container>
        <Container
          className="items-center justify-center z-10 mb-8"
          variant="cards-2"
        >
          <span ref={scrollToTopOfCardsRef} />
          {NFTsSecondRowSM.map((nft, k) => (
            <>
              {nft.timeLeft && nft.timeTillStart && (
                <BatchCard
                  key={`BatchCard4-${k}`}
                  nft={nft}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                />
              )}
            </>
          ))}
        </Container>
      </div>
    </>
  );
};

const BatchCard = ({ nft, setProcessing, setTransactionHash }) => {
  const {
    provider,
    notify,
    gnosisAddress,
    wallet,
    address,
    isLoggedIn,
    transactions,
    setTransactions,
  } = useContext(dappContext);

  const [showLoggedOutWarning, setShowLoggedOutWarning] = useState(false);

  useEffect(() => {
    if (isLoggedIn);
    setShowLoggedOutWarning(false);
  }, [isLoggedIn]);
  const completePurchase = useCallback(async () => {
    /** Load in signer, send ETH */
    const signer = provider.getSigner();

    let hash;
    let response;
    try {
      response = await signer.sendTransaction({
        to: gnosisAddress,
        from: address,
        value: ethers.utils.parseEther(nft.price),
        gasLimit: 60000,
      });
      hash = response.hash;
    } catch (e) {
      // Signature denied
      if (e.code === 4001) {
        notify.notification({
          type: 'error',
          message: 'Signature rejected',
          autoDismiss: 12000,
          onclick: () => {},
          eventCode: '4001',
        });
        setTransactionHash('');
        setProcessing('');
        return;
      }
      throw e;
    }

    setTransactionHash(hash);
    setProcessing('processing');

    /** Emitter memes */
    const { emitter } = notify.hash(response.hash);

    emitter.on('txSpeedUp', (e) => {
      setTransactionHash(response.hash);
      setProcessing('txSpeedUp');
      console.log(response.title, ' transaction was sped up, new hash: ', hash);
    });

    emitter.on('txConfirmed', (e) => {
      setProcessing('txConfirmed');
      console.log(response.title, ' transaction confirmed: ', hash);
      const updatedTransactions = transactions
        ? { ...transactions }
        : defaultNFTAmounts;
      const price = nft.price / 1e18;
      let batchID = BATCHES.find((batch) => batch.price == price).id;
      updatedTransactions[batchID] += 1;
      setTransactions(updatedTransactions);
    });

    emitter.on('txCancel', (e) => {
      setProcessing('txCancel');
      return {
        autoDismiss: 15000,
      };
    });

    emitter.on('all', (event) => {
      console.log(response.hash, ' event: ', event.eventCode);
      return {
        autoDismiss: 15000,
      };
    });

    // setTransactionHash(response.hash);

    return await response.wait();
  }, [
    provider,
    gnosisAddress,
    notify,
    setProcessing,
    setTransactionHash,
    address,
    nft,
  ]);

  return (
    <div
      style={{ boxShadow: '3px 3px 20px rgba(87, 94, 96, 0.2)' }}
      className={`
      z-20 rounded px-5 py-6 col-span-6 sm:col-span-3 md:col-span-2 lg:col-span-4 flex-1 ${
        nft.expired && 'opacity-50 cursor-default'
      }`}
    >
      <div className="mb-3">
        <p className="type-h3 text-green-02">
          Tier&nbsp;
          {nft.id}
        </p>
        <p className="flex items-center justify-center type-p-lg text-grey-01">
          <img
            src={nft.locked ? lock : clock}
            alt="Clock"
            className="h-6 mr-1"
          />
          {nft.expired && <span>Closed</span>}
          {!nft.expired && !nft.locked && (
            <span>
              {nft.timeLeft.hours >= 1
                ? `${nft.timeLeft.hours} hrs`
                : `${nft.timeLeft.minutes} mins`}{' '}
              left
            </span>
          )}
          {!nft.expired && nft.locked && (
            <span>
              <b>Unlocking in </b>
              {nft.timeTillStart.hours >= 1
                ? `${nft.timeTillStart.hours} hrs`
                : `${nft.timeTillStart.minutes} mins`}{' '}
            </span>
          )}
        </p>
      </div>

      {transactions && transactions[nft.id] > 0 && (
        <div className="flex flex-row justify-between type-p-lg text-grey-01 mb-3">
          <div className="flex flex-row items-center">
            <img src={check} className="h-6 mr-1" />
            <div>Purchased: {transactions[nft.id]}</div>
          </div>
          <div></div>
        </div>
      )}

      <div className="shiny">
        <img src={nft.src} alt="Teller NFT" className="w-full rounded" />
      </div>

      <div className="-mt-5 z-50">
        {/*<PrimaryButton*/}
        {/*  disabled={nft.expired || nft.locked}*/}
        {/*  className="w-40 z-50 relative"*/}
        {/*  onClick={() => {*/}
        {/*    if (!isLoggedIn) {*/}
        {/*      setShowLoggedOutWarning(true);*/}
        {/*      return;*/}
        {/*    }*/}
        {/*    setProcessing('loading');*/}
        {/*    completePurchase();*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {`Buy Tier ${nft.id}`}*/}
        {/*</PrimaryButton>*/}
        {showLoggedOutWarning && (
          <div className="type-caption text-grey-02 mt-2">
            Please connect your wallet
          </div>
        )}
      </div>

      <p className="my-8 text-center">
        <span className="type-p-sm uppercase text-grey-01">
          Price:
        </span>
        <br />
        <span className="text-4xl text-green-03">
          {nft.price} ETH
        </span>
      </p>

      {nft.details.map(({ key, values }) => (
        <React.Fragment key={uniqueId()}>
          <hr className="my-4" />
          <dl className="flex align-start justify-between type-p-xs">
            <dt className="text-left">{key}</dt>
            <dd className="text-right font-light">
              {values.map((v, i) => (
                <p
                  className={classNames({ 'mt-2': i !== 0 })}
                  key={uniqueId()}
                >
                  {decode(v)}
                </p>
              ))}
            </dd>
          </dl>
        </React.Fragment>
      ))}
    </div>
  );
};

const RewardCards = ({
  REWARDS,
  setProcessing,
  setTransactionHash,
  context,
  scrollToTopOfCardsRef,
}) => {
  const [NFTs, setNFTs] = useState(REWARDS);

  const NFTsFirstRowLG = NFTs.slice(0, 3);
  const NFTsSecondRowLG = NFTs.slice(3);
  const NFTsFirstRowSM = NFTs.slice(0, 2);
  const NFTsSecondRowSM = NFTs.slice(2);

  useEffect(() => {
    REWARDS.forEach((b) => {
      console.log(
        b.id,
        ' start: ',
        new Date(b.startTimestamp).toLocaleString(undefined, {
          timeZone: 'America/Los_Angeles',
        }),
        ' end: ',
        new Date(b.expiryTimestamp).toLocaleString(undefined, {
          timeZone: 'America/Los_Angeles',
        }),
        ' PDT'
      );
    });

    const recalculateNFTdates = () => {
      const timeNow = Date.now();
      const updatedNFTs = NFTs.map((NFT) => {
        const timeTillExpiry = new Date(NFT.expiryTimestamp) - timeNow;
        const timeTillStart = new Date(NFT.startTimestamp) - timeNow;

        NFT.expired = Boolean(timeTillExpiry < 0);
        NFT.locked = Boolean(timeTillStart > 0);
        NFT.timeLeft = {
          hours: NFT.expired
            ? 0
            : Math.round(timeTillExpiry / (1000 * 60 * 60)),
          minutes: NFT.expired
            ? 0
            : Math.floor((timeTillExpiry / 1000 / 60) % 60),
        };
        NFT.timeTillStart = {
          hours: NFT.expired
              ? 0
              : Math.round(timeTillStart / (1000 * 60 * 60)),
          minutes: NFT.expired
              ? 0
              : Math.floor((timeTillStart / 1000 / 60) % 60),
        };
        return NFT;
      });
      setNFTs(updatedNFTs);
    };

    recalculateNFTdates();
    const interval = setInterval(() => {
      recalculateNFTdates();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="hidden md:flex flex-col mb-24">
        <Container
          className="items-center justify-center z-10 mb-8"
          variant="cards-1"
        >
          {NFTsFirstRowLG.map((nft, k) => (
            <>
              {nft.timeLeft && nft.timeTillStart && (
                <RewardCard
                  key={`BatchCard-${k}`}
                  nft={nft}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                />
              )}
            </>
          ))}
        </Container>
        <Container
          className="items-center justify-center z-20 mb-8"
          variant="cards-2"
        >
          {NFTsSecondRowLG.map((nft, k) => (
            <>
              {nft.timeLeft && nft.timeTillStart && (
                <RewardCard
                  key={`BatchCard2-${k}`}
                  nft={nft}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                />
              )}
            </>
          ))}
        </Container>
      </div>
      <div className="md:hidden flex flex-col mb-24">
        <Container
          className="items-center justify-center z-20 mb-8"
          variant="cards-1"
        >
          {NFTsFirstRowSM.map((nft, k) => (
            <>
              {nft.timeLeft && nft.timeTillStart && (
                <RewardCard
                  key={`BatchCard3-${k}`}
                  nft={nft}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                />
              )}
            </>
          ))}
        </Container>
        <Container
          className="items-center justify-center z-20 mb-8"
          variant="cards-2"
        >
          <span ref={scrollToTopOfCardsRef} />
          {NFTsSecondRowSM.map((nft, k) => (
            <>
              {nft.timeLeft && nft.timeTillStart && (
                <RewardCard
                  key={`BatchCard4-${k}`}
                  nft={nft}
                  setProcessing={setProcessing}
                  setTransactionHash={setTransactionHash}
                />
              )}
            </>
          ))}
        </Container>
      </div>
    </>
  );
};

const RewardCard = ({ nft, name }) => {
  const {
    provider,
    notify,
    gnosisAddress,
    wallet,
    address,
    isLoggedIn,
    transactions,
    setTransactions,
  } = useContext(dappContext);
  const { closeModal, modalOpened, setModalOpened } = useModal();
  const [userClaim, setUserClaim] = useState(null);
  const [isClaimed, setIsClaimed] = useState(null);
  const details = merkleDetails()
  const openClaimModal = () => {
    if (modalOpened) return;
    setModalOpened(true);
  };
  const claimableNFTDistributorContract = useclaimableNFTDistributorContract();

  useEffect(() => {
    if (!claimableNFTDistributorContract || !address ) return null;
    let isMounted = true;
    (async () => {
      const claim = details.claims[address]
      if (claim) {
        setUserClaim(claim)
        const claimed = await claimableNFTDistributorContract.methods.isClaimed(details.root, claim.index).call()
        setIsClaimed(claimed)
      }      
    })();
    return () => {
      isMounted = false;
    };
  }, [claimableNFTDistributorContract, address]);

  // console.log({ address, userClaim, isClaimed})
  return (
  <>
    {
      <div
        style={{ boxShadow: '3px 3px 20px rgba(87, 94, 96, 0.2)' }}
        className={`
        z-20 rounded px-5 py-6 col-span-6 sm:col-span-3 md:col-span-2 lg:col-span-4 flex-1 ${
          nft.expired && 'cursor-default'
        }`}
      >
        <div className="mb-3">
          <p className="type-h3 text-green-03">
            {nft.name}
          </p>
        </div>

        {transactions && transactions[nft.id] > 0 && (
          <div className="flex flex-row justify-between type-p-lg text-grey-01 mb-3">
            <div className="flex flex-row items-center">
              <img src={check} className="h-6 mr-1" />
              <div>Purchased: {transactions[nft.id]}</div>
            </div>
            <div></div>
          </div>
        )}

        <div className="shiny">
          <img src={nft.src} alt="Teller NFT" className="w-full rounded" />
        </div>

        {nft.button && address && userClaim && !isClaimed  &&  (
          <p className="-my-6 text-center mb-6">
            <div className="te-landing-page__hero__cta-button">
              <GreenButton
                // CTA={openClaimModal}
                className="bg-green-02 xs:w-full relative"
                onClick={openClaimModal}
              >
                Claim Now
              </GreenButton>
            </div>
          </p>
          )}
          {nft.button && address && userClaim && isClaimed  &&  (
          <p className="-my-6 text-center mb-6">
              <div className="te-landing-page__hero__cta-button">
                <GreenButton
                // CTA={openClaimModal}
                  disabled={true}
                  className="bg-green-03 xs:w-full relative"
                  onClick={openClaimModal}
                >
                  NFT claimed
                </GreenButton>
            </div>
          </p>
        )}

        {nft.details.map(({ key, values }) => (
          <React.Fragment key={uniqueId()}>
            <hr className="my-4" />
            <dl className="flex align-start justify-between type-p-xs">
              <dt className="text-left">{key}</dt>
              <dd className="text-right font-light">
                {values.map((v, i) => (
                  <p
                    className={classNames({ 'mt-2': i !== 0 })}
                    key={uniqueId()}
                  >
                    {decode(v)}
                  </p>
                ))}
              </dd>
            </dl>
          </React.Fragment>
        ))}
      </div>
    }
    {modalOpened && <ClaimNFTModal closeModal={closeModal} merkleDetails={details} address={address} setIsClaimed={setIsClaimed} />}
  </>
  );
};

const merkleDetails = () => {
  const distributions = JSON.parse(JSON.stringify(distributorFile))
  const claims = {}
  Object.entries(distributions.default.claims).map((claim) => {
    claims[claim[0].toLowerCase()] = claim[1];
  })
  const root = distributions.default.merkleRoot
  const tokenTotal = distributions.default.tokenTotal

  return {
    claims,
    root,
    tokenTotal
  }
}

const SignUpForm = () => {
  const [userEmail, setUserEmail] = useState(null);
  const message = false;
  const loading = false;
  const handleChange = (e) => {
    setUserEmail(e.target.value);
  };
  const CTA_DISABLED = Boolean(!userEmail || message === 'Success' || loading);

  return (
    <form
      className='js-cm-form'
      id='subForm'
      action='https://www.createsend.com/t/subscribeerror?description='
      method='post'
      data-id='2BE4EF332AA2E32596E38B640E9056198CEF9C9EDFB8C5CEEBB8021A3E5A908E8875237AC5D21935E9A5F144D1F2A32D7BEFAC640100F1029BA4030B914A14E8'
    >
      <div className='flex flex-row flex-wrap md:flex-nowrap ml-12'>
        <input
          value={userEmail || ''}
          onChange={handleChange}
          className='js-cm-email-input qa-input-emai bg-teal-light bg-opacity-25 rounded-full py-4 pl-6 pr-16 text-black relative z-0 outline-none mb-2 -ml-12
              flex-1 block min-w-min sm:max-w-xs'
          placeholder='your@email.com'
          autoComplete='Email'
          aria-label='Email'
          id='fieldEmail'
          maxLength='200'
          required=''
          type='email'
          name='cm-jlkdiyu-jlkdiyu'
        />
        <div className='flex-1 sm:flex-initial block min-w-min sm:max-w-xs text-center sm:w-min'>
          <CTA className={`px-6 -ml-12 z-10 relative`} disabled={CTA_DISABLED}>
            <button
              disabled={CTA_DISABLED}
              type={CTA_DISABLED ? '' : `submit`}
              className={CTA_DISABLED ? 'cursor-default' : `cursor-pointer`}
            >
              Join the waitlist
            </button>
          </CTA>
        </div>
      </div>
    </form>
  );
};

const Hero = ({ saleStage }) => {
  return (
    <Container
      className='flex flex-col items-center justify-center bg-cover'
      variant='main'
    >
      <img
        src={crystalBall}
        alt="teller crystal ball"
        className="self-center mb-6 sm:pt-20 pt-16"
        height={160}
      />
      <p className="text-green-02 font-bold type-h1">Our Fortune </p>
      <p className="text-green-03 font-bold type-h1 mb-4">Teller’s Sale</p>

      <p
        className={`type-p-sm text-black ${
          saleStage === 2 ? 'sm:mb-10' : 'mb-10'
        } font-light`}
      >
        Fortune Teller is a digital art collectible series created by
        collaborating artists globally. Holders of the artwork can access
        Teller’s Alpha and stake their NFT as a credit to unlock unsecured loans
        within the protocol. Join our sale and participate in the first NFT
        liquidity provisioning for DeFi, including future giveaways and protocol
        rewards.
        {` `}
        <u className='text-green-02 font-medium'>
          <a
            href='https://medium.com/teller-finance/credit-economics-behind-the-fortune-teller-nft-sale-b4bc76b5d574'
            target='_blank'
            rel='noreferrer'
          >
            Learn More
          </a>
        </u>
        {` `}
      </p>
      <div className='mouse_scroll shade'>
        <div className='mouse'>
          <div className='wheel'></div>
        </div>
        <div>
          <span className='m_scroll_arrows unu'></span>
          <span className='m_scroll_arrows doi'></span>
          <span className='m_scroll_arrows trei'></span>
        </div>
      </div>
    </Container>
  );
};

const ISDEV = process.env.REACT_APP_ISDEV || true;
const SaleStageSelector = ({ setSaleStage, saleStage }) => {
  return (
    <>
      {ISDEV && (
        <div className='cursor-pointer fixed right-0 bottom-0 z-20 font-bold m-10 flex flex-row'>
          <div
            className={`p-2 ${
              saleStage === 1 && 'border rounded border-green-01 '
            }`}
            onClick={() => {
              setSaleStage(1);
            }}
          >
            1
          </div>{' '}
          <div
            className={`p-2 ${
              saleStage === 2 && 'border rounded border-green-01 '
            }`}
            onClick={() => {
              setSaleStage(2);
            }}
          >
            2
          </div>
          <div
            className={`p-2 ${
              saleStage === 3 && 'border rounded border-green-01 '
            }`}
            onClick={() => {
              setSaleStage(3);
            }}
          >
            3
          </div>
        </div>
      )}
    </>
  );
};

const copy = {
  successScreen: { title: 'Purchase successful' },
  loadingMessage: { title: 'Submitting purchase' },
};

const CTA = ({ children, className = '', disabled = false }) => {
  return (
    <div
      className={`${className} rounded-full text-white ${
        disabled
          ? 'opacity-80 bg-tangerine-02 hover:bg-tangerine-02 cursor-default'
          : 'cursor-pointer bg-tangerine-02 hover:bg-tangerine-hover'
      } py-4 whitespace-nowrap`}
    >
      {children}
    </div>
  );
};
