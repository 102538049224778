import { useNotifyContext } from "contexts/notifyContext";
import { useTransactionsContext } from "contexts/transactionsContext";
import { useNotificationsContext } from "contexts/notificationsContext";


/**
 * @param title Type of transaction. Used for knowing what data to update
 * @param onSuccess Optional - Callback function to be called after the transaction succeeded. (Include the UI updates that trigger success screen here)
 * @param setLoading Optional - Callback function to be called when transaction starts. (Include the UI updates that trigger loading screen here)
 * @param onError Optional - Callback function to be called if error was detected
 * @param onHash Optional - Callback function to be called when hash was detected
 * @param successCheckFunction Optional - Fuction to be called after transaction succeeded, to add an extra check before state changes to success. We don't need it in most cases
 */
export const useTransactionHandler = (
  title,
  onSuccess = (hash) => null,
  setLoading = (hash) => null,
  onError = () => null,
  onHash = () => null,
  successCheckFunction = () => true,
) => {
  const { notify } = useNotifyContext();
  const { addTransaction } = useTransactionsContext();
  const { addNotification } = useNotificationsContext();

  const onHashBase = (hash) => {
    setLoading(hash);
    onHash(hash);
    const { emitter } = notify.hash(hash);
    emitter.on("txSpeedUp", (e) => {
      onSpeedupBase(e.hash);
    });

    emitter.on("txConfirmed", (e) => {
      if (e.hash == hash) return;
      if (successCheckFunction) {
        const successCheck = async () => {
          const stateChanged = await successCheckFunction();
          if (stateChanged) {
            onSuccessBase(e.hash);
          } else {
            setTimeout(() => {
              successCheck();
            }, 2000);
          }
        };
        successCheck();
      } else {
        onSuccessBase(e.hash);
      }
    });

    emitter.on("txCancel", (e) => {
      onCancelBase(e.hash);
      return {
        /**
         * The number of milliseconds before the notification automatically hides or 0 for no
         *  */
        autoDismiss: 15000,
      };
    });
    emitter.on("all", (event) => {
      console.log(title, " event: ", event.eventCode);
      return {
        /**
         * The number of milliseconds before the notification automatically hides or 0 for no
         *  */
        autoDismiss: 15000,
      };
    });
  };

  const onCancelBase = (hash) => {
    onError();
    console.log(title, " transaction cancelled, hash: ", hash);
    setLoading(null);
    addNotification({ copy: "Transaction cancelled", variant: "error" });
  };

  const onSpeedupBase = (hash) => {
    onHash(hash);
    console.log(title, " transaction was sped up, new hash: ", hash);
  };
  const onSuccessBase = (hash) => {
    console.log(title, " transaction successful, hash: ", hash);
    addTransaction({ type: title, hash });
    setLoading(null);
    onSuccess(hash);
  };

  const onErrorBase = (message) => {
    onError();
    console.log(title, " transaction error, message: ", message);
    setLoading(null);
    addNotification({ copy: `Error`, variant: "error" });
  };

  return { onSuccess: onSuccessBase, onHash: onHashBase, onError: onErrorBase };
};
