import React, { useEffect, useState } from "react";
import Container from "components/Container/Container";

const Banner = () => {
    return (
      <>
        <Container bg="bg-tangerine-02">
          <div
            className="text-white type-p-lg"
            style={{ margin: "18px 0 18px 0" }}
          >
            It’s ready - claim your Fortune Teller’s NFT through our app!
            Redeem <a href = "https://app.teller.finance"><u>here</u></a>.
          </div>
        </Container>
      </>
    );
};

export default Banner;
