import { Link } from 'react-router-dom';
import React from 'react';

import PrimaryButton from 'components/PrimaryButton/PrimaryButton';
import LoadingAnimation from './LoadingAnimation';

import './success-screen.scss';

const SuccessScreen = ({
  title,
  message,
  onButtonClick,
  fullScreen = true,
  CTA = "Go to home page",
}) => {
  return (
    <div
      className={`align-self-center flex items-center justify-center flex-col ${
        fullScreen
          ? "cards-container success-screen xs:my-6 sm:my-10 md:my-24 lg:self-start lg:justify-start"
          : "py-2"
      } "
       `}
    >
      <div className="flex items-center	 justify-center flex-col">
        <div className="text-5xl xs:text-4xl m-4">
          <LoadingAnimation isStopped={false} animation="success" />
        </div>
        <div
          className={`${
            fullScreen ? "text-5xl xs:text-4xl" : "text-4xl xs:text-3xl"
          } font-medium`}
        >
          {title}
        </div>
        <div className="text-gray m-3">{message}</div>
        <PrimaryButton onClick={onButtonClick}>
          {CTA}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SuccessScreen;
