import { NetworkNameEnum } from 'contexts/DappContext/DappContext';

// import { AvailableCollateralTokens, AvailableDapps, AvailableLendingTokens } from "contexts/types";
export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

export const globalDecimals = 1000000000000000000;

export const allContractAddresses = {
  localhost: {
    // @dev Remember to update the addresses here from a local fork running in the dev env
    ClaimableFortuneTeller: "0x44197C570A9F15c7913D54917fE7a849e95eA58b",
    ClaimableNFTDistributor: "0xcc7d7e0d5176C0E877e6f5BcC616a6FDE20b4711",
    TellerDiamond: "0x449f661c53aE0611a24c2883a910A563A7e42489",
    TellerNFT: "0x2746a08337B208ed32e0c71F013A087Eb07D61f2",
    NFTDistributor: "0xA642375Cc15249A81da9c435fB4eDD8A9343ce7F",
    TDAI: "0xE5aE32Be9a0c5c77478fC0c80C68a53f74a85de9",
    PriceAggregator: "0xd47F69331Bf231e47B9E7c16f72b177B16b7207A",
    tokens: {
      DAI: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      USDC: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      LINK: "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
      wBTC: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      WETH: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      // Aave
      AAVE: "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
      ADAI: "0x27F8D03b3a2196956ED754baDc28D73be8830A6e", // amDAI
      // Pool Together
      PCDAI: "0xfecfa775643eb8c0f755491ba4569e501764da51",
      // MATIC
      WMATIC: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    },
  },
  polygon: {
    TellerDiamond: "0x449f661c53aE0611a24c2883a910A563A7e42489",
    TellerNFT: "0x2746a08337B208ed32e0c71F013A087Eb07D61f2",
    NFTDistributor: "0xA642375Cc15249A81da9c435fB4eDD8A9343ce7F",
    TDAI: "0x4A0B4836d0190a7Db2B7ae20B649fAF70b400e35",
    PriceAggregator: "0xE5aE32Be9a0c5c77478fC0c80C68a53f74a85de9",
    tokens: {
      DAI: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      USDC: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      LINK: "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
      wBTC: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      USDT: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      WETH: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      ETH: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      // Aave
      AAVE: "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
      ADAI: "0x27F8D03b3a2196956ED754baDc28D73be8830A6e", // amDAI
      // Pool Together
      PCDAI: "0xfecfa775643eb8c0f755491ba4569e501764da51",
      // MATIC
      WMATIC: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    },
  },
  polygon_mumbai: {
    TellerDiamond: "0xc229a8Bd91df6109ad9ceAD8AB897B352769B8eb",
    TellerNFT: "0x8328E342e0F16Adbda29cAEf570f3119B90A6E4f",
    NFTDistributor: "0xB94906FFA833B4857779318C330a80e54047FfD3",
    TDAI: "0x02474d0a8506703F579f0bD1e38301c76c0C5083",
    PriceAggregator: "",
    tokens: {
      DAI: "0x001B3B4d0F3714Ca98ba10F6042DaEbF0B1B7b6F",
      USDC: "0x2058A9D7613eEE744279e3856Ef0eAda5FCbaA7e",
      LINK: "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
      wBTC: "0x0d787a4a1548f673ed375445535a6c7A1EE56180",
      USDT: "0xBD21A10F619BE90d6066c941b04e340841F1F989",
      WETH: "0x3C68CE8504087f89c640D02d133646d98e64ddd9",
      // Aave
      AAVE: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      ADAI: "0x639cB7b21ee2161DF9c882483C9D55c90c20Ca3e", // amDAI
      // Pool Together
      PCDAI: "0xfecfa775643eb8c0f755491ba4569e501764da51",
      // MATIC
      WMATIC: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
    },
  },
  ropsten: {
    NFTDistributor: "",
    TDAI: "",
    TellerDiamond: "",
    TellerNFT: "",
    TellerNFTDictionary: "",
    LendingPool: "0xBf984040ad76cda8f5514BC29F451458EC7bcb80",
    Lenders: "0x16dEa6C4CbD07732Da0B0174A9df97B6aDF80d2D",
    TokenCollateralLoans: "0xdDCA72AF6Ab0c281499cfdf508ad95C3F3A14CA4",
    EtherCollateralLoans: "0xBff73C7aED1C5C1267863f41196449e99F4Eb50a",
    LoanTermsConsensus: "0x2B0AaCcdF372458de62b9F7726AB2E49A13134d2",
    InterestConsensus: "0x0A04f75262D3E065f35617f1AF957800dA10d925",
    Escrow: "0xE8Ce266eB095CaF1E42c4390b99e45458E08eF0c",
    ChainlinkPairAggregator: "0x7456Df6bd3C520e135f09c2d01F310975d6E1B81",
    ATMGovernance: "0x40741Ed603A53A063471b1436D3742adb5F680e7",
    TLRToken: "0xB419dF00BAb2767Ae41d24c2A1a7740d43F847E3",
    Uniswap: "0xAb52a75D9921c375aa55AD4d8CEFf0a3D46E2b85",
    Compound: "0xE167A7C25D9Ec73A8D6CcC74C5e125A1cf546DF8",
    EscrowFactory: "0xf2D8cAA3987d8d8A9Ff40CAE78A9212DE5Fd90ED",
    ChainlinkPairAggregatorRegistry: "0xBa41ED6fDff508F9816061C83cEd4416Eae3DD16",
    MarketsState: "0x6ae28f68Fc45f3A0aE2d1Ce960574af7a64c4167",
    ATMSettings: "0x6dB186a550e2873389Bc2036fe0AaA6aa9e0C30f",
    ATMFactory: "0x8a7D9419EdfCfE017D0CCd8db94821E04b65B99a",
    MarketFactory: "0xb438437a7547da334Af96A1cf552C3b9854E2A31",
    TTokenRegistry: "0x218101ddA7731A1765878b9aCb2a9f408e48A8A6",
    Settings: "0x43C0C7DE99278574aB6CCf6d8A744e291Cae3a0D",
    Settings_Proxy: "0x9DaBEDd013686870D097bfEc7a79032423ba2Cd5",
    EscrowFactory_Proxy: "0xB9ED38f01E6025ac2d337Df7746fDDb936d19733",
    ChainlinkPairAggregatorRegistry_Proxy: "0x514e36540dA457736d75f22d38A8345375Dd9cBB",
    MarketsState_Proxy: "0xE70874Ef191e031879931a4641BD7494e79e0b45",
    ATMSettings_Proxy: "0x4EDf8fc0eF937a0Df126e1F66a01f99299d23695",
    ATMFactory_Proxy: "0x3c2Aa53805879F42aC5fdcF908C31c9d82B6Fd59",
    MarketFactory_Proxy: "0xE560dBf3Ee2215927bcCd594445EBd25bF31EB75",
    TTokenRegistry_Proxy: "0x7cB6A52FDeC9F93e5cA6eB85aAd57C68844a968F",
    LogicVersionsRegistry: "0x4cF4820Bb0995B7E90976C2D6a438650B5637b9A",
    LogicVersionsRegistry_Proxy: "0xa9cE32238f8FbF4791434894ad1596b226aE19e6",
    Uniswap_Proxy: "0x8a9cB9D3e779dbBB53Ba1F1d45C82eFD85a446f9",
    Compound_Proxy: "0x75f0aAc4ede78b24B463B1583Be6ea6d93cE49E8",
    TDAI: "0xA1CF9E7bAb20728C6Cad26bc49d47436e00ABC5C",
    TUSDC: "0x0fA4d25B0b1A3A898941dAbea54F7AB61a073830",
    ETH_Loans_tDAI_Proxy: "0x8d1dA48949DBEa380088e1afa5c29922C25e2B89",
    ETH_Lenders_tDAI_Proxy: "0x6745Cfb750C9473D6b4c0A1bE834a47836cb0d51",
    ETH_LendingPool_tDAI_Proxy: "0x29a3Dbc9e5406210d4585aF1a4Fb30FA2c6ee79f",
    ETH_LoanTermsConsensus_tDAI_Proxy: "0xc7bE6644AB950c3fB4BB6A8E51f63c282cBc8d41",
    ETH_InterestConsensus_tDAI_Proxy: "0xE6f355FC07DE0a6F74Ae4C0145F7a1EA7Adb3055",
    ETH_ChainlinkPairAggregator_tDAI_Proxy: "0x0D740beaA912d4ed01e17c7054B1F9B4a4105aF2",
    LINK_Loans_tDAI_Proxy: "0x28059596170059737008B733750D9d3Ca570494C",
    LINK_Lenders_tDAI_Proxy: "0xF7fF431F4d4e6017adad88d8d39A8991883c6c07",
    LINK_LendingPool_tDAI_Proxy: "0xBB17a57B29a982ed80c5f1618BFD388083196803",
    LINK_LoanTermsConsensus_tDAI_Proxy: "0x596805E674D02DF034cDA266C5a34965E7DDa92C",
    LINK_InterestConsensus_tDAI_Proxy: "0x0946fFCF4dA21C243B09C0E7A50977fD3F0cd691",
    LINK_ChainlinkPairAggregator_tDAI_Proxy: "0xE554adD5e0b4777681F06777F58592303D03dced",
    ETH_Loans_tUSDC_Proxy: "0x137B32F8Cd223eB748e9954605A5023FA4363066",
    ETH_Lenders_tUSDC_Proxy: "0xa1eDD9c28381176038e1FD0F2845E17505C75d05",
    ETH_LendingPool_tUSDC_Proxy: "0x4fAB12ac0bf955Bd432a5Fd8072c931Bd18477e3",
    ETH_LoanTermsConsensus_tUSDC_Proxy: "0x59B74ac1e232100b6593f6A543fb00Ab765C40ab",
    ETH_InterestConsensus_tUSDC_Proxy: "0xD2a5776f8ac637Cb81d28F80429AA49073F0F25b",
    ETH_ChainlinkPairAggregator_tUSDC_Proxy: "0x529587ECA7747b976F7672eC665663CA867a8b6b",
    LINK_Loans_tUSDC_Proxy: "0x9341c050AFE9614c28B8A2e13776d09ebE64A5F8",
    LINK_Lenders_tUSDC_Proxy: "0xdA1A9DacfE6cCEDFc41B68b717b60104f6ea1770",
    LINK_LendingPool_tUSDC_Proxy: "0xcdC34B465FE5FeEEdf09481F1D0E8e5C5BacD706",
    LINK_LoanTermsConsensus_tUSDC_Proxy: "0x32A58F91286a7F9c260a98754dbC6a6d879292B3",
    LINK_InterestConsensus_tUSDC_Proxy: "0x7bC7D6EDD2e26F4d3DEc2e56065A61400fcFb43D",
    LINK_ChainlinkPairAggregator_tUSDC_Proxy: "0x0168CF9b4992b5F38F659C5a3313342AF9EcFbb9",
    tokens: {
      ETH: "",
      // DAI used by Compound.finance
      DAI: "0xc2118d4d90b274016cB7a54c03EF52E6c537D957",
      // USDC used by Compound.finance
      USDC: "0x0D9C8723B343A8368BebE0B5E89273fF8D712e3C",
      // LINK Faucet: https://ropsten.chain.link/
      LINK: "0x20fE562d797A42Dcb3399062AE9546cd06f63280",
      // TODO confirm token addresses
      SNX: "0x65bAd0f094f2Cce90a2BD757372932CdaF079213",
      MKR: "0x626e11f94bf498d79b3afcd06cdaf8577f19d794",
      YFI: "0xbeade56abd45cab4f84b239af784149a29311741",
      LEND: "0x73317bccdd6186b705c07f8306d5121011f84698",
      WBTC: "0x06E728D7907C164649427D2ACFD4c81669D453Bf",
      USDT: "0x7c619efddb1b941ec124508421d0034436ed00c1",
      WETH: "",
    },
  },
  rinkeby: {
    NFTDistributor: "0x21680971fb8016bAF5dc4a24621685645F387E34",
    TDAI: "0xd93aa60Fc5C10Fb46Fdd6824a9ae07c73C2019cd",
    TellerDiamond: "0xFc63d690b510BFF24b19059A2297B912747c7c18",
    TellerNFT: "0xebB6327a31a145B8d1937bf55c57544077245798",
    TellerNFTDictionary: "",
    TToken: "0xd93aa60Fc5C10Fb46Fdd6824a9ae07c73C2019cd",
    // Old
    LendingPool: "0xF1743209a10923d484b5e9B65e1Caf4eF7f6C372",
    TokenCollateralLoans: "0xf98880D3d29c0337fb457d2adc1443849e4C0d59",
    EtherCollateralLoans: "0x61504A238e2DD5f6a6D027384816d8C944fe78b0",
    LoanTermsConsensus: "0x76F35684665469FB1Bce0F2Abe0F34e3791ee3c9",
    Escrow: "0x24466d41060edcB9477328910D7b0dc7e7388394",
    ChainlinkAggregator: "0x7B2B06392a2487e55b00458a26683b7b006D6FB9",
    ATMGovernance: "",
    ATMLiquidityMining: "",
    TLRToken: "",
    Uniswap: "0x972f302AA9345673356B91C72899FAD98a08d53e",
    Compound: "0x0D7ab3109b01CdE4C5DdFFDBD235cDF02a4087F0",
    EscrowFactory: "0xE390A8694C31a8Bd5ea65eB68306db67dc0e2387",
    MarketsState: "",
    ATMSettings: "",
    ATMFactory: "",
    MarketFactory: "0xc1bD851539137370dCEEd3b1F2A82168C0A54888",
    Settings: "0xa539eF542C8E2093bf99E055864B21A861aAf99e",
    Settings_Proxy: "0xb4B3CAE6289C44F92a72b4648a9345615962875A",
    EscrowFactory_Proxy: "0xcC583069126567628C4EECdE03F9C19B8245a777",
    ChainlinkAggregator_Proxy: "0xb617cC4b544eAc37960e2956B0d4f5C08b4DD4ac",
    MarketsState_Proxy: "",
    ATMSettings_Proxy: "",
    ATMFactory_Proxy: "",
    MarketFactory_Proxy: "0x5C647c89d4f18029CbF359F22e3698628B918B0B",
    LogicVersionsRegistry: "0xC73fa56aBa61DDCD4ca7EAf5407503b5ab18ec4f",
    LogicVersionsRegistry_Proxy: "0x429B0d95647596706f00A87DbD8043FF298Fb21B",
    Uniswap_Proxy: "0x69E9b836a6a201e2D1733f05ca26cF60E37F5e17",
    Compound_Proxy: "0x015B0867d1B88FD7bB35F88ae487FD03fBB0ABba",
    ETH_Loans_tDAI_Proxy: "0x957173849f5412dD06C73D8aDA4c40187FCC4Cfc",
    ETH_LendingPool_tDAI_Proxy: "0xaC7DCf61bE64110d7b47fb83a5332EA4055C3DC6",
    ETH_LoanTermsConsensus_tDAI_Proxy: "0x90B222f99B400b04e4E8CbFD5174B14646d4Fe1D",
    LINK_Loans_tDAI_Proxy: "",
    LINK_LendingPool_tDAI_Proxy: "",
    LINK_LoanTermsConsensus_tDAI_Proxy: "",
    ETH_Loans_tUSDC_Proxy: "",
    ETH_LendingPool_tUSDC_Proxy: "",
    ETH_LoanTermsConsensus_tUSDC_Proxy: "",
    LINK_Loans_tUSDC_Proxy: "",
    LINK_LendingPool_tUSDC_Proxy: "",
    LINK_LoanTermsConsensus_tUSDC_Proxy: "",
    tokens: {
      ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      DAI: "0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa",
      USDC: "0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b",
      LINK: "0x01BE23585060835E02B77ef475b0Cc51aA1e0709",
      // TODO confirm token addresses
      SNX: "0x3cA27cEEDA9A200f50C3Cf3802C6c60d43867977",
      MKR: "0xc42da14b1c0ae7d4dd3946633f1046c3d46f3101",
      YFI: "0x62289c64a943416205142bdccf1d752792a687e3",
      LEND: "0x5373cdced6dd1b1cbf3ca2c85b8eab57052df7a4",
      WBTC: "0x0014f450b8ae7708593f4a46f8fa6e5d50620f96",
      USDT: "0x1a37dd375096820a5fde14342720102c07100f26",
      WETH: "0xc778417e063141139fce010982780140aa0cd5ab",
    },
  },
  kovan: {
    TellerDiamond: "0xc229a8Bd91df6109ad9ceAD8AB897B352769B8eb",
    PriceAggregator: "0x6f1C9Fa5D9EE52C3DD848D1Ac2eeB4f569a5003E",
    TellerNFT: "0xA0DBaBE1C4B98DfBDb8a19e9b9b2d221f0C4fA07",
    TellerNFT_V2: "0x6aE4CD408D334C7f8DBc73D05fA596151FF621fF",
    TellerNFTDistributor: "0xB94906FFA833B4857779318C330a80e54047FfD3",
    TellerNFTDictionary: "0xF270070Dc4AAF37823Fa96c823e17cb291d8D57A",
    NFTDistributor: "0xb0e4C97eD0C603e1d8302987d79334b59738061d",
    TToken_V2_Alpha: "0xc0f29519F3ff31E01C6a92067Af12a139cA39Da2",
    LendingPool: "0xA425622e07DBAdce38cc6081A89f395117d6d7a6",
    Lenders: "0xcFBF7274E07859e678B4C9EA5611CE2bde593710",
    TokenCollateralLoans: "0xE00CfE489FfD7aE536b0AE4f55B53990EAb1d004",
    EtherCollateralLoans: "0xF95BA345c5073bF4A17721A3c6B2675b26bBd7C8",
    LoanTermsConsensus: "0xb0Ff2e7a42531201B908fdEb226B34c52B3D779f",
    InterestConsensus: "0xf1b63184914b8Ed239dE8D54c14AcC5ED3426022",
    Escrow: "0x5E6810f067FF58B07A7A8C8beCf95de3011B6A5A",
    ChainlinkAggregator: "0xb7ba5Fe56Ca3D19608B874045404B313EC2452d0",
    ATMGovernance: "0xf428D3b0B5E7000206Da07e16B4580de1E13eA19",
    ATMLiquidityMining: "0x5C679465cC1661B60D1A27056C1263681F7503Ba",
    TLRToken: "0x2ea4784da9B0F761eB1aa99165d924550aB8f6d7",
    Uniswap: "0x389Aa048D5874783765285587202aD189C4EC932",
    Compound: "0xDb604FF6E5B669A78f8482726F3592AC42Cc7D8a",
    EscrowFactory: "0x00dF236bF8409AEE5b6E4975315eF62dCD98249E",
    MarketsState: "0x05d5d0A30D789956890019366B99d33224531FC6",
    ATMSettings: "0x93F5d012C16fB343d256502eec7275ae7F2EAcA6",
    ATMFactory: "0x99fD1b202751688bd398bD96c0b5b3eEb2270A05",
    MarketFactory: "0x4954349d12b0EFC136251A5ac48cCc090ed16591",
    TTokenRegistry: "0xe6f93BF1aFb66C47fEfDdCa638156a48601257d5",
    Settings: "0xbe804098333e872b904Be066D2133c1e07eF8acE",
    Settings_Proxy: "0x6FAB7Fd3dB164Ac43f70D8618477580A9cB6B729",
    EscrowFactory_Proxy: "0xA4953Fb96fCfc15a2788a9467291a4Ba6362DD83",
    ChainlinkAggregator_Proxy: "0xB359B9c2e88a90D768E1047060E950D9Cd56e067",
    MarketsState_Proxy: "0xc4507A340295119dcC1840FAb8fF09bA33D728f9",
    ATMSettings_Proxy: "0x8Fcd6CF82200bEbAAedEf5b42F4A7AB1d6970Fc2",
    ATMFactory_Proxy: "0x1c6e0cAd8fB3C1BF50c9b81CcB658A7e703668c6",
    MarketFactory_Proxy: "0x114ACc923009D6840C49C85E1891435a17864D80",
    TTokenRegistry_Proxy: "0xBe7832bF68d06B31f9ec4ec1F915CeDBD89DE2A5",
    LogicVersionsRegistry: "0x78116bc1Fa33AA1Db80410e9eBCa235db846075F",
    LogicVersionsRegistry_Proxy: "0x72038EF3a32A1eB155516E02912406E7fE0a9578",
    Uniswap_Proxy: "0x73266ce27F50696Be846675FC5bF1EBd87894843",
    Compound_Proxy: "0xa0f19090180a0eC1a904994eE4957D1F49d7A4AF",
    TDAI: "0xDf8839FF0484785795672eB6a380B5DA84aBc6C9",
    TUSDC: "0xB776cfC6D4668985138C94001182e3613B15F86B",
    ETH_Loans_tDAI_Proxy: "0xb7075B797daf126b9D9A3777cC977163a098754E",
    ETH_Lenders_tDAI_Proxy: "0x791F685dBD826c06751d4854F4Ba405Ae5274150",
    ETH_LendingPool_tDAI_Proxy: "0x95882C84df13f7b7898d4A5290ad09C2b19B20b6",
    ETH_LoanTermsConsensus_tDAI_Proxy: "0x5A093fAcF592717E89b359da769aB79f0DF9ccB9",
    ETH_InterestConsensus_tDAI_Proxy: "0xcc271E2d0FAf38aEA7464678B3c6ccbE5F422052",
    LINK_Loans_tDAI_Proxy: "0xAc4885ABe0902Bcf00e0003815b5742E2788810f",
    LINK_Lenders_tDAI_Proxy: "0x684FAE061c5113dB3388eAce8b3E5C4380EcE848",
    LINK_LendingPool_tDAI_Proxy: "0x0B9f2900040CF67f4BD09410A102e758e00D18f8",
    LINK_LoanTermsConsensus_tDAI_Proxy: "0x8C8f7F06A55266775DC7478e1a29d92E437f1C3f",
    LINK_InterestConsensus_tDAI_Proxy: "0x0f52247a5d12B9F38Bb3C2202B9796c2D716A971",
    ETH_Loans_tUSDC_Proxy: "0xa77b0C7f331CC55D8a46fDD70a4acD957749337F",
    ETH_Lenders_tUSDC_Proxy: "0x475659F7B63722F8a96be2cEB78835B1E6da31Ff",
    ETH_LendingPool_tUSDC_Proxy: "0x16eFef0Df1C51175836469e2ec8CfB9bCFDDC5dd",
    ETH_LoanTermsConsensus_tUSDC_Proxy: "0xAd87dA3F2Fa6F33d7C465051291c0c7AD3574986",
    ETH_InterestConsensus_tUSDC_Proxy: "0xf827d5173e1aABCDF5153E2ed22ee7f5795EF56b",
    LINK_Loans_tUSDC_Proxy: "0xC25daB71d4058EC6AF4325137a952A1ADc3716a7",
    LINK_Lenders_tUSDC_Proxy: "0x9d6F7E4FF93Ac7001AfB3F2ef5C8E400fFDbAA05",
    LINK_LendingPool_tUSDC_Proxy: "0x0CfaADCD56A9420cb0A85c10AA2A9D8de1B3b22D",
    LINK_LoanTermsConsensus_tUSDC_Proxy: "0x2b38ec6b6e2c40833f009808b51E3cb17c09D8ae",
    LINK_InterestConsensus_tUSDC_Proxy: "0x09cEe46C82869B31C2BfAC6bbe44869Cb08B3f41",
    tokens: {
      ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      DAI: "0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa",
      USDC: "0xb7a4f3e9097c08da09517b5ab877f7a917224ede",
      LINK: "0xa36085F69e2889c224210F603D836748e7dC0088",
      WETH: "0xd0a1e359811322d97991e03f863a0c30c2cf029c",
      YFI: "0xf2d645d45f0a46cdfa080595df1d6c9d733296c3",
      USDT: "0xf3e0d7bf58c5d455d31ef1c2d5375904df525105",
    },
  },
  mainnet: {
    ClaimableFortuneTeller: "0x5D665D31005b4035b63ae240C9beF28D9668fB8d",
    ClaimableNFTDistributor: "0xA1Ed58d90512A498Bb3ebeE1b1532F35408e8078",
    NFTDistributor: "0x058F447199025e9ACF52E4A1473f4Ad9cC44D299",
    TDAI: "0xFb65E61b9706d7317FBD3D49dA8E5Fdf7A179873",
    TellerDiamond: "0xc14D994fe7C5858c93936cc3bD42bb9467d6fB2C",
    TellerNFT: "0x2ceB85a2402C94305526ab108e7597a102D6C175",
    TellerNFTDictionary: "0x72733102AB139FB0367cc29D492c955A7c736079",
    TellerNFT_V2: "0x8f9bbbB0282699921372A134b63799a48c7d17FC",
    PriceAggregator: "0x5D665D31005b4035b63ae240C9beF28D9668fB8d",
    TToken: "0xFb65E61b9706d7317FBD3D49dA8E5Fdf7A179873",
    LendingPool: "0xDf3ECB5c90DE10ADE6B4C0ded4c04e4FD18c4110",
    TokenCollateralLoans: "0x5e914E42a1538949B3166ad36Dee9968186952d1",
    EtherCollateralLoans: "0xd96e1C18e41C7C7be6cb14fC9D8C5b077884C596",
    LoanTermsConsensus: "0x9D7adEb4BB96167fc92616B1Ea9c1116778829B5",
    Escrow: "0xd72B4A041Ce0FfA4d9D78489E4D60d5ec33EEc9d",
    ChainlinkAggregator: "0x3e102AfF4eDE75f492B31c6134E688163477c125",
    ATMGovernance: "",
    ATMLiquidityMining: "",
    TLRToken: "",
    Uniswap: "0x17C5f005C896FB7F9E91b227544b44426Bd182CE",
    Compound: "0xb286728C982B474A3CA792e778f73C91f83B8a42",
    EscrowFactory: "0x18E50d6E538E2Ae048A18178B170C7C15a8FBBCF",
    MarketsState: "",
    ATMSettings: "",
    ATMFactory: "",
    MarketFactory: "0x71e5283c7FFdf752f38cC66621A87804fc0c6756",
    Settings: "0x8Ce76F65e35E75d7aF9c984bB60c2eEb2e2a468d",
    Settings_Proxy: "0xF3c125B8400373F215Ce50a1f9E35c2f7a2312F3",
    EscrowFactory_Proxy: "0x0303650D815a8a94e58c16BD1F9c64F2C0c6517D",
    ChainlinkAggregator_Proxy: "0x9ebaF1a9A0AAe0Fe7f49FA4a98382c065E145a74",
    MarketsState_Proxy: "",
    ATMSettings_Proxy: "",
    ATMFactory_Proxy: "",
    MarketFactory_Proxy: "0x60bd5B3888647764139649a0Ea694970927FC83d",
    LogicVersionsRegistry: "0x7EF9747F161B12E25d24A0C5c0ca27D891ED20a5",
    LogicVersionsRegistry_Proxy: "0xEBEDb019AC48B822bD66fDe798D83303d8335A3A",
    Uniswap_Proxy: "0x36369FC9A3de9509703442b4c5CF4BA492B561B3",
    Compound_Proxy: "0xbda6568Ae38f983d01772EcE40389f896aAA3f8C",
    ETH_Loans_tDAI_Proxy: "0x014a9F2397bee928126E9a66E1a3BB277B19eB2A",
    ETH_LendingPool_tDAI_Proxy: "0xC06C47C51Ba596278b025cedEC82Cd716C2CD465",
    ETH_LoanTermsConsensus_tDAI_Proxy: "0x0bdD7A30A18796c97D27Bb4605E9964F3E2b4EA1",
    LINK_Loans_tDAI_Proxy: "",
    LINK_LendingPool_tDAI_Proxy: "",
    LINK_LoanTermsConsensus_tDAI_Proxy: "",
    ETH_Loans_tUSDC_Proxy: "",
    ETH_LendingPool_tUSDC_Proxy: "",
    ETH_LoanTermsConsensus_tUSDC_Proxy: "",
    LINK_Loans_tUSDC_Proxy: "",
    LINK_LendingPool_tUSDC_Proxy: "",
    LINK_LoanTermsConsensus_tUSDC_Proxy: "",
    tokens: {
      ETH: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      // See https://etherscan.io/token/0x6b175474e89094c44da98b954eedeac495271d0f
      DAI: "0x6b175474e89094c44da98b954eedeac495271d0f",
      // See https://etherscan.io/token/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48
      USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      // See https://etherscan.io/token/0x514910771af9ca656af840dff83e8264ecf986ca
      LINK: "0x514910771af9ca656af840dff83e8264ecf986ca",
      SNX: "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
      MKR: "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
      YFI: "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
      AAVE: "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
      WBTC: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
      USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      WETH: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    },
  },
};

export const dataProviderUrl = process.env.REACT_APP_DATA_PROVIDER_URL;
export const craURLs = {
  arrowhead: process.env.CRA_URLS_ARROWHEAD || "",
};

export default {
  allContractAddresses,
  craURLs,
  dataProviderUrl,
  globalDecimals,
};

export const NETWORKS_CONFIG = {
  [NetworkNameEnum.kovan]: {
    collateralTokens: ["DAI", "USDC", "USDT", "ETH"],
    lendingTokens: ["DAI", "USDC", "USDT", "ETH"],
    firstBlock: "27425101",
    networkSupported: true,
    dapps: ["compound", "uniswap"],
  },
  [NetworkNameEnum.mainnet]: {
    collateralTokens: ["DAI", "USDC", "USDT", "ETH", "WBTC", "LINK"],
    lendingTokens: ["DAI", "USDC", "USDT", "ETH", "WBTC", "LINK"],
    firstBlock: "12877778",
    networkSupported: true,
    dapps: ["compound", "uniswap"],
  },
  [NetworkNameEnum.rinkeby]: {
    collateralTokens: [],
    lendingTokens: [],
    firstBlock: "",
    networkSupported: false,
    dapps: [],
  },
  [NetworkNameEnum.ropsten]: {
    collateralTokens: [],
    lendingTokens: [],
    firstBlock: "",
    networkSupported: false,
    dapps: [],
  },
  [NetworkNameEnum.polygon]: {
    collateralTokens: ["USDT", "DAI", "MATIC", "USDC", "ETH", "WBTC"],
    lendingTokens: ["USDT", "DAI", "MATIC", "USDC", "ETH", "WBTC"],
    firstBlock: "19659224",
    networkSupported: true,
    dapps: ["aave", "pooltogether", "sushiswap"],
  },
  [NetworkNameEnum.polygon_mumbai]: {
    collateralTokens: [],
    lendingTokens: [],
    firstBlock: "",
    networkSupported: false,
    dapps: ["aave", "pooltogether", "sushiswap"],
  },
  [NetworkNameEnum.unknown]: {
    collateralTokens: [],
    lendingTokens: [],
    firstBlock: "",
    networkSupported: false,
    dapps: [],
  },
};
