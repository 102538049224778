import { Transition } from '@headlessui/react';
import React, { useState } from 'react';

import NavLoginButton from 'components/NavLoginButton/NavLoginButton';
import tellerLogo from 'assets/teller-logo-full.svg';

const MobileNav = ({ links }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="md:hidden sticky flex flex-1">
      <div className="relative flex items-center justify-between h-16 flex-1">
        <div className="items-center justify-center md:items-stretch md:justify-start">
          <a href="https://www.teller.finance/">
            <img
              src={tellerLogo}
              alt="Teller logo"
              className="block h-8 w-auto"
            />
          </a>
        </div>

        <div className="flex flex-row">
          <NavLoginButton />
          <div className="md:ml-8 ml-4 flex items-center md:hidden">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {!menuOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      <Transition
        show={menuOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute my-16 py-6 bg-white md:hidden text-center mx-auto left-0 right-0">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a href="/" rel="noreferrer" target="_blank">
              <span className="inline-block px-3 py-2 font-medium hover:text-green-03 text-green-03">
                Fortune sale
              </span>
            </a>
            {links.map((link) => {
              return (
                <a
                  key={link.title}
                  href={link.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="block px-3 py-2 font-medium hover:text-green-03 whitespace-nowrap">
                    {link.title}
                  </div>
                </a>
              );
            })}
            <a
              href="https://app.teller.finance"
              rel="noreferrer"
              target="_blank"
            >
              <div className="mx-auto block my-4 border-2 text-green-03 border-teal py-2 px-7 rounded-full w-min">
                App
              </div>
            </a>
          </div>
        </div>
      </Transition>
    </nav>
  );
};

export default MobileNav;
