export const wallets = [
  { walletName: "metamask", preferred: true },
  // {
  //   walletName: "walletConnect",
  //   infuraKey: process.env.REACT_APP_INFURA_API_KEY,
  //   preferred: true,
  // },
  {
    walletName: "walletLink",
    rpcUrl: process.env.REACT_APP_RPC_URL,
    label: "Coinbase",
    appName: "Teller Finance",
    preferred: true,
  },
  {
    walletName: "trust",
    preferred: true,
    rpcUrl: process.env.REACT_APP_RPC_URL,
  },
  // {
  //   walletName: "coinbase",
  //   preferred: true,
  //   rpcUrl: process.env.REACT_APP_RPC_URL,
  // },
];
