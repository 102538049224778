import { ethers } from "ethers";

export const getNetworkName = (network: string) => {
  let networkName = null;

  if (network == "4") {
    networkName = "rinkeby";
  } else if (network === "42") {
    networkName = "kovan";
  } else if (network == "1") {
    networkName = "mainnet";
  }

  return networkName;
};

export const getEtherscanLink = (
  hash: string,
  network: string,
  isAddress = false
) => {
  const type = isAddress ? "address" : "tx";
  const networkName = getNetworkName(network);
  if (networkName === "mainnet") return `https://etherscan.io/${type}/${hash}`;
  else return `https://${networkName}.etherscan.io/${type}/${hash}`;
};

export const completePurchase = (
  nft,
  provider,
  gnosisAddress,
  transactionHandler
) => async () => {
  const signer = provider.getSigner();
  console.log({ provider, signer });
  const params = [
    {
      from: signer.getAddress(),
      to: gnosisAddress,
      value: ethers.utils.parseUnits(nft.price, "ether").toHexString(),
    },
  ];

  return new Promise((resolve, reject) =>
    signer
      .sendTransaction(params)
      .on("data", console.log)
      .on("transactionHash", (e: any) => {
        transactionHandler.onHash(e);
      })
      .on("receipt", (e: any) => {
        transactionHandler.onSuccess(e.transactionHash);
        resolve(e);
      })
      .on("error", (e: any) => {
        transactionHandler.onError(e.message);
      })
  );
};
