import { RiPlayCircleFill } from 'react-icons/ri';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import LazyImage from 'components/LazyImage/LazyImage';
import 'utils/lazyLoad';

import './Thumbnail.scss';

const Thumbnail = ({
  artist,
  isForVideo,
  mediaIndex,
  onClick,
  sectionIndex,
  thumbnail,
}) => {

  useEffect(() => {
    document.lazyLoadInstance.update();
  }, []);

  return (
    <figure className="te-gallery__thumbnail__wrapper">
      <div className="te-gallery__thumbnail">
        <LazyImage
          src={thumbnail}
          onClick={() => onClick(sectionIndex, mediaIndex)}
        />
        {isForVideo && (
          <span
            className="te-gallery__thumbnail__video-icon"
            aria-hidden="true"
          >
            <RiPlayCircleFill />
          </span>
        )}
      </div>
      <figcaption className="te-gallery__thumbnail__by-line">
        <Link to={{
          pathname: '/artist-preview',
          search: `artist=${artist.id}`,
        }}>
          {`by ${artist.name}`}
        </Link>
      </figcaption>
    </figure>
  );
};

export default Thumbnail;
