import Notify from "bnc-notify";
import Onboard from "bnc-onboard";
import { ethers } from "ethers";
import { addresses } from "./addresses";
import { blockNativeOptions } from "./block-native";
import { defaultChainId } from "./default-chain-id";
import { wallets } from "./wallets";
import Web3 from "web3";

const { createContext, useState, useMemo, useEffect } = require("react");

export const dappContext = createContext({
  web3: null,
  notify: null,
  wallet: null,
  provider: null,
  address: null,
  balance: null,
  network: null,
  networkDetails: {id: null, name: null},
  isLoggedIn: false,
  walletSelect: (name) => {},
  walletReset: () => {},
  walletCheck: () => {},
  getState: () => {},
  transactions: null,
  setTransactions: null,
});

export const DappContextProvider = ({ children }) => {
  const [web3, setWeb3] = useState(null);
  const [address, setAddress] = useState();
  const [balance, setBalance] = useState();
  const [network, setNetwork] = useState();
  const [networkDetails, setNetworkDetails] = useState();
  const [wallet, setWallet] = useState();
  const [triedAutoload, setTriedAutoload] = useState(false);
  const [transactions, setTransactions] = useState(null);

  const notify = useMemo(() => {
    return Notify({
      dappId: process.env.REACT_APP_NOTIFY_ID,
      networkId: network ?? defaultChainId,
      desktopPosition: "topRight",
      txStallConfirmedTimeout: 20000,
    });
  }, [network]);

  const onboard = useMemo(
    () =>
      Onboard({
        networkId: network ?? defaultChainId,
        walletSelect: {
          wallets,
        },
        walletCheck: [{ checkName: "connect" }, { checkName: "network" }],
        ...blockNativeOptions,
        subscriptions: {
          address: async (address) => {
            localStorage.setItem(
              "selectedWallet-1",
              onboard.getState().wallet.name
            );
            setAddress(address);
          },
          balance: async (balance) => {
            setBalance(balance);
          },
          network: async (network) => {
            setNetwork(network);
            setNetworkDetails({id: network, name: mapNetworkIDToName(network)})
            onboard.config({ networkId: network });
            await onboard.walletCheck();
            console.log(notify);
            // notify.config({ networkId: network });
            // console.log({ network, onboard });
          },
          wallet: async (wallet) => {
            if (wallet && wallet.instance && wallet.provider) {
              await onboard.walletCheck();
              console.log(wallet);
              setWallet(wallet);
            } else {
              setWallet(wallet);
            }
            const web3 = new Web3(wallet.provider);
            setWeb3(web3);
          },
        },
      }),
    []
  );

  const mapNetworkIDToName = (id) => {
    if (id == 1) return NetworkNameEnum.mainnet;
    if (id == 3) return NetworkNameEnum.ropsten;
    if (id == 4) return NetworkNameEnum.rinkeby;
    if (id == 42) return NetworkNameEnum.kovan;
    if (id == 31337) return NetworkNameEnum.mainnet;
    if (id == 137) return NetworkNameEnum.polygon;
    else return NetworkNameEnum.unknown;
  };
  /** Eagerly trying to use previously connected wallet */
  useEffect(() => {
    if (!triedAutoload && onboard) {
      setTriedAutoload(true);
      const previouslySelectedWallet = localStorage.getItem("selectedWallet-1");
      if (previouslySelectedWallet) {
        onboard.walletSelect(previouslySelectedWallet);
      }
    }
  }, [onboard, triedAutoload]);

  const provider = useMemo(
    () =>
      !!wallet &&
      !!wallet.provider &&
      !!network &&
      new ethers.providers.Web3Provider(wallet.provider, network),
    [wallet, network]
  );

  const gnosisAddress = useMemo(() => addresses.Gnosis[network], [network]);
  const linkAddress = useMemo(() => addresses.Link[network], [network]);

  const isLoggedIn = useMemo(() => {
    return !!provider && ethers.utils.isAddress(address);
  }, [provider, address]);

  return (
    <dappContext.Provider
      value={{
        web3,
        notify,
        wallet,
        provider,
        address,
        balance,
        network,
        networkDetails,
        gnosisAddress,
        linkAddress,
        isLoggedIn,
        walletSelect: onboard.walletSelect,
        walletReset: onboard.walletReset,
        walletCheck: onboard.walletCheck,
        getState: onboard.getState,
        transactions,
        setTransactions,
      }}
    >
      {children}
    </dappContext.Provider>
  );
};

export const NetworkNameEnum = {
    kovan: "kovan",
    mainnet: "mainnet",
    rinkeby: "rinkeby",
    ropsten: "ropsten",
    polygon: "polygon",
    unknown: "unknown",
}
