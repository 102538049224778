const { createContext, useState, useCallback } = require("react");

export const modalContext = createContext({
  aModalIsOpen: false,
  logOut: false,
  setShowModal(name, open) {},
});

export const ModalContextProvider = ({ children }) => {
  const [modals, setModals] = useState({
    aModalIsOpen: false,
    logOut: false,
  });

  const setShowModal = useCallback(
    (name, open) => {
      if (modals[name] === open) return;
      setModals({
        ...modals,
        aModalIsOpen: open,
        [name.toString()]: open,
      });
    },
    [modals, setModals]
  );

  return (
    <modalContext.Provider value={{ ...modals, setShowModal }}>
      {children}
    </modalContext.Provider>
  );
};
