import React from 'react';
import classNames from 'classnames';

const PrimaryButton = ({
  children,
  className = '',
  disabled = false,
  icon = undefined,
  onClick = () => {},
  type = '',
}) => (
  <button
    style={{ minWidth: '152px' }}
    type={disabled ? '' : type}
    onClick={() => !disabled && onClick()}
    className={classNames(
      className,
      'z-50 primarybutton hover:border-none rounded',
      { 'cursor-default disabled text-white bg-tangerine-disabled': disabled },
      { 'cursor-pointer text-white bg-tangerine-02 hover:bg-tangerine-hover': !disabled },
    )}
  >
    <div
      style={{ padding: '13px 0px 13px 0px' }}
      className="mx-auto font-medium whitespace-nowrap type-button-sm flex flex-row items-center text-center w-max"
    >
      {icon && <img src={icon} alt="Icon" className="h-6 mr-1" />}
      {children}
    </div>
  </button>
);

export default PrimaryButton;