import React, { useContext } from 'react';

import { dappContext } from 'contexts/DappContext/DappContext';
import { modalContext } from 'contexts/ModalContext';
import { formatWalletAddress } from 'utils/format-wallet-address';

const NavLoginButton = () => {
  const {
    isLoggedIn,
    walletSelect,
    address,
    provider,
    network,
    notify,
    wallet,
    walletReset,
  } = useContext(dappContext);
  const { setShowModal } = useContext(modalContext);

  return (
    <div
      className="text-lg xs:text-md md:text-base text-teal font-medium login-button py-3 md:py-3 px-4 md:px-5 menu-button bg-teal-lightest rounded-full"
      style={{
        marginLeft: '12px',
      }}
    >
      {!isLoggedIn ? (
        <div onClick={() => walletSelect()}>
          <span>{'Connect Wallet'}</span>
        </div>
      ) : (
        <div onClick={() => setShowModal('logOut', true)}>
          {formatWalletAddress(address)}
        </div>
      )}
    </div>
  );
};

export default NavLoginButton;
