import { ethers } from "ethers";
import { useNetworkName } from "hooks/use-network-name";
import { useMemo } from "react";

export const useEtherscanLink = (value) => {
  const networkName = useNetworkName();
  return useMemo(
    () =>
      `https://${
        networkName === "mainnet" ? "" : networkName + "."
      }etherscan.io/${
        ethers.utils.isAddress(value) ? "address" : "tx"
      }/${value}`,
    [networkName, value]
  );
};
