import classNames from 'classnames';
import React, { useEffect } from 'react';
import 'utils/lazyLoad';

const LazyVideo = ({ className, src, videoRef, ...rest }) => {
  const ext = src.split('.').pop();
  const type = `video/${ext}`;

  useEffect(() => {
    document.lazyLoadInstance.update();
  }, []);

  return (
    <video
      ref={videoRef && videoRef}
      className={classNames(className, 'js-lazyLoad')}
      data-src={src}
      {...rest}
    >
      <source
        data-src={src}
        type={type}
      />
    </video>
  );
};

LazyVideo.defaultProps = {
  className: undefined,
};

export default LazyVideo;
