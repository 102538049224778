import cAnimal from 'assets/gallery/corbin/corbin-animal-v1.1.1.png';
import cFemale from 'assets/gallery/corbin/corbin-female-v1.1.1.png';
import cMale from 'assets/gallery/corbin/corbin-male-v1.1.1.png';
import cRobot from 'assets/gallery/corbin/corbin-robot-v1.1.1.png';
import cSkeleton from 'assets/gallery/corbin/corbin-skeleton-v1.1.1.png';
import cExtra from 'assets/gallery/corbin/corbin-extra-v1.0.1.png';

import nAnimal from 'assets/gallery/nathan/nathan-animal-v1.1.1.png';
import nFemale from 'assets/gallery/nathan/nathan-female-v1.1.1.png';
import nMale from 'assets/gallery/nathan/nathan-male-v1.1.1.png';
import nRobot from 'assets/gallery/nathan/nathan-robot-v1.1.1.png';
import nSkeleton from 'assets/gallery/nathan/nathan-skeleton-v1.1.1.png';
import nExtra from 'assets/gallery/nathan/nathan-extra-v1.0.1.png';

import pAnimal from 'assets/gallery/prince/osinachi-animal-v1.0.1.png';
import pFemale from 'assets/gallery/prince/osinachi-female-v1.0.1.png';
import pMale from 'assets/gallery/prince/osinachi-male-v1.0.1.png';
import pRobot from 'assets/gallery/prince/osinachi-robot-v1.0.1.png';
import pSkeleton from 'assets/gallery/prince/osinachi-skeleton-v1.0.1.png';
import pExtra from 'assets/gallery/prince/osinachi-extra-v1.0.1.png';

import dAnimal from 'assets/gallery/durk/durk-animal-v1.1.1.mp4';
import dFemale from 'assets/gallery/durk/durk-female-v1.1.1.mp4';
import dMale from 'assets/gallery/durk/durk-male-v1.1.1.mp4';
import dRobot from 'assets/gallery/durk/durk-robot-v1.1.1.mp4';
import dSkeleton from 'assets/gallery/durk/durk-skeleton-v1.1.1.mp4';
import dExtra from 'assets/gallery/durk/durk-extra-v1.0.1.png';

import hAnimal from 'assets/gallery/dylan/dylan-animal-v1.0.1.mp4';
import hFemale from 'assets/gallery/dylan/dylan-female-v1.0.1.mp4';
import hMale from 'assets/gallery/dylan/dylan-male-v1.0.1.mp4';
import hRobot from 'assets/gallery/dylan/dylan-robot-v1.0.1.mp4';
import hSkeleton from 'assets/gallery/dylan/dylan-skeleton-v1.0.1.mp4';
import hExtra from 'assets/gallery/dylan/dylan-extra-v1.0.1.png';

export const corbin = [
  { id: 'animal', src: cAnimal },
  { id: 'female', src: cFemale },
  { id: 'male', src: cMale },
  { id: 'robot', src: cRobot },
  { id: 'skeleton', src: cSkeleton },
  { id: 'cExtra', src: cExtra },
];

export const nathan = [
  { id: 'animal', src: nAnimal },
  { id: 'female', src: nFemale },
  { id: 'male', src: nMale },
  { id: 'robot', src: nRobot },
  { id: 'skeleton', src: nSkeleton },
  { id: 'extra', src: nExtra },
];

export const prince = [
  { id: 'animal', src: pAnimal },
  { id: 'female', src: pFemale },
  { id: 'male', src: pMale },
  { id: 'robot', src: pRobot },
  { id: 'skeleton', src: pSkeleton },
  { id: 'extra', src: pExtra },
];

export const durk = [
  { type: 'video', id: 'animal', src: dAnimal },
  { type: 'video', id: 'female', src: dFemale },
  { type: 'video', id: 'male', src: dMale },
  { type: 'video', id: 'robot', src: dRobot },
  { type: 'video', id: 'skeleton', src: dSkeleton },
  { type: 'image', id: 'extra', src: dExtra },
];

export const dylan = [
  { type: 'video', id: 'animal', src: hAnimal },
  { type: 'video', id: 'female', src: hFemale },
  { type: 'video', id: 'male', src: hMale },
  { type: 'video', id: 'robot', src: hRobot },
  { type: 'video', id: 'skeleton', src: hSkeleton },
  { type: 'image', id: 'extra', src: hExtra },
];

const gallery = [
  { id: 'corbin', items: corbin },
  { id: 'nathan', items: nathan },
  { id: 'prince', items: prince },
  { id: 'durk', items: durk },
  { id: 'dylan', items: dylan },
];

export default gallery;
