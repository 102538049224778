import { useContext, useMemo } from "react";
import { allContractAddresses } from "constants/constants";
import { dappContext } from 'contexts/DappContext/DappContext';

export const useAddress = (id, type) => {
  const {
    networkDetails
  } = useContext(dappContext);

  return useMemo(() => {
    if (!networkDetails?.name) return null;
    const res =
      type == "contract"
        ? allContractAddresses[networkDetails.name][id]
        : allContractAddresses[networkDetails.name].tokens[id];
    return res;
  }, [networkDetails, type, id]);
};

export const useContract = (contractName, ABI, type, options, address) => {
  const {
    web3
  } = useContext(dappContext);
  const contractAddress = useAddress(contractName, type);
  return useMemo(() => {
    if (!contractAddress || !ABI || !web3) return null;
    return new web3.eth.Contract(ABI, contractAddress, options);
  }, [contractAddress, web3, ABI, options]);
};
