import { find, get, uniqueId } from 'lodash';
import { Player } from 'video-react';
import { Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import Banner from 'components/Banner/Banner';
import Container from 'components/Container/Container';
import NavBar from 'components/NavBar/NavBar';
import NavLoginButton from 'components/NavLoginButton/NavLoginButton';

import { ARTISTS } from 'pages/ArtGalleryPage/ArtGalleryPage';

import gallery from './Gallery';

const NFTCanvas = ({ BATCHES }) => (
  BATCHES.map(({ src, type }) => (
    <div style={{ boxShadow: '3px 3px 20px rgba(87, 94, 96, 0.2)' }} key={uniqueId()}>
      <div className="shiny">
        {type === 'video'
          ? <NFTVideo src={src} />
          : <NFTImage src={src} />
        }
      </div>
    </div>
  ))
);

const NFTVideo = ({ src }) => (
  <Player
    playsinline={true}
    loop={true}
    controls={false}
    muted={false}
    src={src}
    aspectRatio={"1:1"}
  />
);

const NFTImage = ({ src }) => (
  <img
    src={src}
    alt="Teller NFT"
  />
);

const ArtistPage = () => {
  const location = useLocation();
  const drawerPrefix = 'faq-drawer-';
  const drawerToggleRefs = useRef(new Array());

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const artistId = searchParams.get('artist');
    const toggleRef = find(drawerToggleRefs.current, ({ dataset }) => dataset.artistId === artistId);

    if (toggleRef) {
      toggleRef.checked = true;

      setTimeout(() => {
        toggleRef.nextElementSibling.scrollIntoView(true);
      }, 100)
    }
  }, [drawerToggleRefs, location]);

  return (
    <div className="flex bg-white text-center  min-h-screen">
      <div className="relative flex-1">
        <Banner />
        <NavBar />
        <Container className="flex flex-col faq-container text-left">
          <div className="my-8 mr-24" >
            <p className="text-green-02 font-bold type-h1 mb-3">Fortune Teller</p>
            <p className="text-green-03 font-bold type-h1 mb-6">Artist Gallery Teaser</p>
            <div className="faq-drawer__content type-p-sm font-light text-black">
              <p className="mb-2">
                Teller collaborated with five different artists on the thematization of our artworks. Each artist created five unique pieces of art. Each piece, in turn, boasts five to seven permutations. All Tellers NFTs share explicit characteristics also components (e.g., background, anthropomorphic subject, etc.).
              </p>
              <p>
                Each artist adhered to a set of pre-selected styles while imbuing the work with varying degrees of rarity. Below you will find collections of 5 out of the 25 to 35 NFTs created by each artist. Please note, the collection previews have been organized per artist.
              </p>
            </div>
          </div>

          {ARTISTS.map((artist, i) => {
            const drawerId = `${drawerPrefix}${artist.id}`;
            const artistGallery = find(gallery, ['id', artist.id]);
            const BATCHES = get(artistGallery, 'items');

            return (
              <div className="faq-drawer" key={i}>
                <input
                  ref={(el) => drawerToggleRefs.current.push(el)}
                  className="faq-drawer__trigger"
                  id={drawerId}
                  data-artist-id={artist.id}
                  type="checkbox"
                />

                <label
                  className="faq-drawer__title type-p-sm font-medium text-green-02 hover:text-green-02 mb-3"
                  htmlFor={drawerId}
                >
                  {artist.name}
                </label>
                <div className="faq-drawer__content-wrapper">
                  <div className="faq-drawer__content type-p-sm font-light text-black mb-2">
                    <span>
                      &#128073;
                      <a
                        className="hover:text-green-02"
                        href={artist.url}
                        target="_blank"
                      >
                        Artist portfolio
                      </a>
                    </span>
                  </div>
                  <div className="flex flex-auto grid lg:grid-cols-3 gap-4 md:col-span-2">
                    <NFTCanvas BATCHES={BATCHES} />
                  </div>
                </div>
              </div>
            );
          })}
        </Container>
      </div>
    </div>
  );
};

export default ArtistPage;
