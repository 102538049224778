import getWeb3Notify from "utils/web3Notify";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { dappContext } from 'contexts/DappContext/DappContext';
const defaultNotifyState = {
  notify: null,
  setNotify: () => null,
};

export const NotifyContext =
  createContext(defaultNotifyState);

export const NotifyContextProvider = ({ children }) => {
  const [notify, setNotify] = useState(null);
  const {
    networkDetails
  } = useContext(dappContext);
  console.log({networkDetails, notify})
  useEffect(() => {
    if (!networkDetails?.id) return;
    (async () => {
      const notify = await getWeb3Notify(Number(networkDetails.id));
      setNotify(notify);
    })();
  }, [networkDetails]);

  const value = useMemo(() => {
    return {
      notify,
      setNotify,
    };
  }, [notify]);

  return <NotifyContext.Provider value={value}>{children}</NotifyContext.Provider>;
};

export const useNotifyContext = () => {
  const context = useContext(NotifyContext);
  if (context === undefined) {
    throw new Error("useNotify must be used within a useNotifyProvider");
  }
  return context;
};
