import React from "react";
import animationData from "../../assets/processing_animation.json";
import Lottie from "react-lottie";
const ProcessingAnimation = ({ height = 200 }) => {
    return (<div className="-my-2 primarybutton" style={{ cursor: "default !important" }}>
      <Lottie options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    }} width={215} height={height} isClickToPauseDisabled={true}/>
    </div>);
};
export default ProcessingAnimation;
