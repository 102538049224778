import { find, uniqueId } from 'lodash';
// import { tns } from 'tiny-slider/src/tiny-slider';
import classNames from 'classnames';
import { RiCloseLine } from 'react-icons/ri';
import React, { useEffect, useRef, useState } from 'react';
// import 'tiny-slider/src/tiny-slider.scss';

import Banner from 'components/Banner/Banner';
import Container from 'components/Container/Container';
import LazyImage from 'components/LazyImage/LazyImage';
import LazyVideo from 'components/LazyVideo/LazyVideo';
import NavBar from 'components/NavBar/NavBar';
import NavLoginButton from 'components/NavLoginButton/NavLoginButton';

import Thumbnail from './components/Thumbnail/Thumbnail';

import './ArtGalleryPage.scss';

import Tier1NathanAnimalV1Expand from 'assets/gallery/tier1/nathan_animal_v1_expand.jpg';
import Tier1NathanAnimalV1Thumbnail from 'assets/gallery/tier1/nathan_animal_v1_thumbnail.jpg';
import Tier1NathanAnimalV4Expand from 'assets/gallery/tier1/nathan_animal_v4_expand.jpg';
import Tier1NathanAnimalV4Thumbnail from 'assets/gallery/tier1/nathan_animal_v4_thumbnail.jpg';
import Tier1NathanFemaleV1Expand from 'assets/gallery/tier1/nathan_female_v1_expand.jpg';
import Tier1NathanFemaleV1Thumbnail from 'assets/gallery/tier1/nathan_female_v1_thumbnail.jpg';
import Tier1NathanFemaleV4Expand from 'assets/gallery/tier1/nathan_female_v4_expand.jpg';
import Tier1NathanFemaleV4Thumbnail from 'assets/gallery/tier1/nathan_female_v4_thumbnail.jpg';
import Tier1NathanMaleV1Expand from 'assets/gallery/tier1/nathan_male_v1_expand.jpg';
import Tier1NathanMaleV1Thumbnail from 'assets/gallery/tier1/nathan_male_v1_thumbnail.jpg';
import Tier1NathanMaleV4Expand from 'assets/gallery/tier1/nathan_male_v4_expand.jpg';
import Tier1NathanMaleV4Thumbnail from 'assets/gallery/tier1/nathan_male_v4_thumbnail.jpg';
import Tier1NathanRobotV1Expand from 'assets/gallery/tier1/nathan_robot_v1_expand.jpg';
import Tier1NathanRobotV1Thumbnail from 'assets/gallery/tier1/nathan_robot_v1_thumbnail.jpg';
import Tier1NathanRobotV4Expand from 'assets/gallery/tier1/nathan_robot_v4_expand.jpg';
import Tier1NathanRobotV4Thumbnail from 'assets/gallery/tier1/nathan_robot_v4_thumbnail.jpg';
import Tier1NathanSkeletonV1Expand from 'assets/gallery/tier1/nathan_skeleton_v1_expand.jpg';
import Tier1NathanSkeletonV1Thumbnail from 'assets/gallery/tier1/nathan_skeleton_v1_thumbnail.jpg';
import Tier1NathanSkeletonV4Expand from 'assets/gallery/tier1/nathan_skeleton_v4_expand.jpg';
import Tier1NathanSkeletonV4Thumbnail from 'assets/gallery/tier1/nathan_skeleton_v4_thumbnail.jpg';

import Tier1CorbinAnimalV1Expanded from 'assets/gallery/tier1/Corbin-animal-v1-expanded.jpg';
import Tier1CorbinAnimalV1Thumbnail from 'assets/gallery/tier1/corbin_animal_v1-thumbnail.jpg';
import Tier1CorbinFemaleV1Expanded from 'assets/gallery/tier1/Corbin-female-v1-expanded.jpg';
import Tier1CorbinFemaleV1Thumbnail from 'assets/gallery/tier1/Corbin-female-v1-thumbnail.jpg';
import Tier1CorbinMaleV1Expanded from 'assets/gallery/tier1/corbin-male-v1-expanded.jpg';
import Tier1CorbinMaleV1Thumbnail from 'assets/gallery/tier1/corbin-male-v1-thumbnail.jpg';
import Tier1CorbinRobotV1Expanded from 'assets/gallery/tier1/corbin-robot-v1-expanded.jpg';
import Tier1CorbinRobotV1Thumbnail from 'assets/gallery/tier1/corbin-robot-v1-thumbnail.jpg';
import Tier1CorbinSkeletonV1Expanded from 'assets/gallery/tier1/corbin-skeleton-v1-expanded.jpg';
import Tier1CorbinSkeletonV1Thumbnail from 'assets/gallery/tier1/corbin_skeleton_v1-thumbnail.jpg';

import Tier1DurkBullV5Expanded from 'assets/gallery/tier1/durk-Bull-v5-expanded.jpg';
import Tier1DurkBullV5Thumbnail from 'assets/gallery/tier1/durk-Bull-v5-thumbnail.jpg';
import Tier1DurkFemaleV5Expanded from 'assets/gallery/tier1/durk-female-v5-expanded.jpg';
import Tier1DurkFemaleV5Thumbnail from 'assets/gallery/tier1/durk-female-v5-thumbnail.jpg';
import Tier1DurkMaleV5Expanded from 'assets/gallery/tier1/durk-male-v5-expanded.jpg';
import Tier1DurkMaleV5Thumbnail from 'assets/gallery/tier1/durk-male-v5-thumbnail.jpg';
import Tier1DurkRobotV5Expanded from 'assets/gallery/tier1/durk-robot-v5-expanded.jpg';
import Tier1DurkRobotV5Thumbnail from 'assets/gallery/tier1/durk-robot-v5-thumbnail.jpg';
import Tier1DurkSkeletonV5Expanded from 'assets/gallery/tier1/durk-skeleton-v5-expanded.jpg';
import Tier1DurkSkeletonV5Thumbnail from 'assets/gallery/tier1/durk-skeleton-v5-thumbnail.jpg';

import Tier1DylanBullV1Expanded from 'assets/gallery/tier1/dylan-bull-v1-expanded.mp4';
import Tier1DylanBullV1Thumbnail from 'assets/gallery/tier1/dylan-bull-v1-thumbnail.jpg';
import Tier1DylanFemaleV1Expanded from 'assets/gallery/tier1/dylan-female-v1-expanded.mp4';
import Tier1DylanFemaleV1Thumbnail from 'assets/gallery/tier1/dylan-female-v1-thumbnail.jpg';
import Tier1DylanMaleV1Expanded from 'assets/gallery/tier1/dylan-male-v1-expanded.mp4';
import Tier1DylanMaleV1Thumbnail from 'assets/gallery/tier1/dylan-male-v1-thumbnail.jpg';
import Tier1DylanRobotV1Expanded from 'assets/gallery/tier1/dylan-robot-v1-expanded.mp4';
import Tier1DylanRobotV1Thumbnail from 'assets/gallery/tier1/dylan-robot-v1-thumbnail.jpg';
import Tier1DylanSkeletonV1Expanded from 'assets/gallery/tier1/dylan-skeleton-v1-expanded.mp4';
import Tier1DylanSkeletonV1Thumbnail from 'assets/gallery/tier1/dylan-skeleton-v1-thumbnail.jpg';

import Tier1OsinachiAnimalV1Expanded from 'assets/gallery/tier1/osinachi-animal-v1-expanded.jpg';
import Tier1OsinachiAnimalV1Thumbnail from 'assets/gallery/tier1/osinachi-animal-v1-thumbnail.jpg';
import Tier1OsinachiAnimalV4Expanded from 'assets/gallery/tier1/osinachi-animal-v4-expanded.jpg';
import Tier1OsinachiAnimalV4Thumbnail from 'assets/gallery/tier1/osinachi-animal-v4-thumbnail.jpg';
import Tier1OsinachiFemaleV1Expanded from 'assets/gallery/tier1/osinachi-female-v1-expanded.jpg';
import Tier1OsinachiFemaleV1Thumbnail from 'assets/gallery/tier1/osinachi-female-v1-thumbnail.jpg';
import Tier1OsinachiFemaleV4Expanded from 'assets/gallery/tier1/osinachi-female-v4-expanded.jpg';
import Tier1OsinachiFemaleV4Thumbnail from 'assets/gallery/tier1/osinachi-female-v4-thumbnail.jpg';
import Tier1OsinachiMaleV4Expanded from 'assets/gallery/tier1/osinachi-male-v4-expanded.jpg';
import Tier1OsinachiMaleV4Thumbnail from 'assets/gallery/tier1/osinachi-male-v4-thumbnail.jpg';
import Tier1OsinachiRobotV1Expanded from 'assets/gallery/tier1/osinachi-robot-v1-expanded.jpg';
import Tier1OsinachiRobotV1Thumbnail from 'assets/gallery/tier1/osinachi-robot-v1-thumbnail.jpg';
import Tier1OsinachiRobotV4Expanded from 'assets/gallery/tier1/osinachi-robot-v4-expanded.jpg';
import Tier1OsinachiRobotV4Thumbnail from 'assets/gallery/tier1/osinachi-robot-v4-thumbnail.jpg';
import Tier1OsinachiSkeletonMainExpanded from 'assets/gallery/tier1/osinachi-skeleton-main-expanded.jpg';
import Tier1OsinachiSkeletonMainThumbnail from 'assets/gallery/tier1/osinachi-skeleton-main-thumbnail.jpg';
import Tier1OsinachiSkeletonV1Expanded from 'assets/gallery/tier1/osinachi-skeleton-v1-expanded.jpg';
import Tier1OsinachiSkeletonV1Thumbnail from 'assets/gallery/tier1/osinachi-skeleton-v1-thumbnail.jpg';

import Tier2DurkBullV3Expanded from 'assets/gallery/tier2/durk-Bull-v3-expanded.jpg'
import Tier2DurkBullV3Thumbnail from 'assets/gallery/tier2/durk-Bull-v3-thumbnail.jpg'
import Tier2DurkBullV6Expanded from 'assets/gallery/tier2/durk-Bull-v6-expanded.jpg'
import Tier2DurkBullV6Thumbnail from 'assets/gallery/tier2/durk-Bull-v6-thumbnail.jpg'
import Tier2DurkFemaleV3Expanded from 'assets/gallery/tier2/durk-female-v3-expanded.jpg'
import Tier2DurkFemaleV3Thumbnail from 'assets/gallery/tier2/durk-female-v3-thumbnail.jpg'
import Tier2DurkFemaleV6Expanded from 'assets/gallery/tier2/durk-female-v6-expanded.jpg'
import Tier2DurkFemaleV6Thumbnail from 'assets/gallery/tier2/durk-female-v6-thumbnail.jpg'
import Tier2DurkMaleV3Expanded from 'assets/gallery/tier2/durk-male-v3-expanded.jpg'
import Tier2DurkMaleV3Thumbnail from 'assets/gallery/tier2/durk-male-v3-thumbnail.jpg'
import Tier2DurkMaleV6Expanded from 'assets/gallery/tier2/durk-male-v6-expanded.jpg'
import Tier2DurkMaleV6Thumbnail from 'assets/gallery/tier2/durk-male-v6-thumbnail.jpg'
import Tier2DurkRobotV3Expanded from 'assets/gallery/tier2/durk-robot-v3-expanded.jpg'
import Tier2DurkRobotV3Thumbnail from 'assets/gallery/tier2/durk-robot-v3-thumbnail.jpg'
import Tier2DurkRobotV6Expanded from 'assets/gallery/tier2/durk-robot-v6-expanded.jpg'
import Tier2DurkRobotV6Thumbnail from 'assets/gallery/tier2/durk-robot-v6-thumbnail.jpg'
import Tier2DurkSkeletonV3Expanded from 'assets/gallery/tier2/durk-skeleton-v3-expanded.jpg'
import Tier2DurkSkeletonV3Thumbnail from 'assets/gallery/tier2/durk-skeleton-v3-thumbnail.jpg'
import Tier2DurkSkeletonV6Expanded from 'assets/gallery/tier2/durk-skeleton-v6-expanded.jpg'
import Tier2DurkSkeletonV6Thumbnail from 'assets/gallery/tier2/durk-skeleton-v6-thumbnail.jpg'

import Tier2CorbinAnimalV2Expanded from 'assets/gallery/tier2/Corbin-animal-v2-expanded.jpg';
import Tier2CorbinAnimalV2Thumbnail from 'assets/gallery/tier2/corbin_animal_v2-thumbnail.jpg';
import Tier2CorbinFemaleV2Expanded from 'assets/gallery/tier2/Corbin-female-v2-expanded.jpg';
import Tier2CorbinFemaleV2Thumbnail from 'assets/gallery/tier2/Corbin-female-v2-thumbnail.jpg';
import Tier2CorbinMaleV2Expanded from 'assets/gallery/tier2/corbin-male-v2-expanded.jpg';
import Tier2CorbinMaleV2Thumbnail from 'assets/gallery/tier2/corbin-male-v2-thumbnail.jpg';
import Tier2CorbinRobotV2Expanded from 'assets/gallery/tier2/corbin-robot-v2-expanded.jpg';
import Tier2CorbinRobotV2Thumbnail from 'assets/gallery/tier2/corbin-robot-v2-thumbnail.jpg';
import Tier2CorbinSkeletonV2Expanded from 'assets/gallery/tier2/corbin-skeleton-v2-expanded.jpg';
import Tier2CorbinSkeletonV2Thumbnail from 'assets/gallery/tier2/corbin_skeleton_v2-thumbnail.jpg';

import Tier2ExpandedDylanBullV3 from 'assets/gallery/tier2/expanded-dylan-bull-v3.mp4';
import Tier2ThumbnailDylanBullV3 from 'assets/gallery/tier2/thumbnail-dylan-bull-v3.jpg';
import Tier2ExpandedDylanFemaleV3 from 'assets/gallery/tier2/expanded-dylan-female-v3.mp4';
import Tier2ThumbnailDylanFemaleV3 from 'assets/gallery/tier2/thumbnail-dylan-female-v3.jpg';
import Tier2ExpandedDylanMaleV3 from 'assets/gallery/tier2/expanded-dylan-male-v3.mp4';
import Tier2ThumbnailDylanMaleV3 from 'assets/gallery/tier2/thumbnail-dylan-male-v3.jpg';
import Tier2ExpandedDylanRobotV3 from 'assets/gallery/tier2/expanded-dylan-robot-v3.mp4';
import Tier2ThumbnailDylanRobotV3 from 'assets/gallery/tier2/thumbnail-dylan-robot-v3.jpg';
import Tier2ExpandedDylanSkeletonV3 from 'assets/gallery/tier2/expanded-dylan-skeleton-v3.mp4';
import Tier2ThumbnailDylanSkeletonV3 from 'assets/gallery/tier2/thumbnail-dylan-skeleton-v3.jpg';

import Tier2NathanAnimalV2Expanded from 'assets/gallery/tier2/nathan_animal_v2-expanded.jpg';
import Tier2NathanAnimalV2Thumbnail from 'assets/gallery/tier2/nathan_animal_v2-thumbnail.jpg';
import Tier2NathanAnimalV3Expanded from 'assets/gallery/tier2/nathan_animal_v3-expanded.jpg';
import Tier2NathanAnimalV3Thumbnail from 'assets/gallery/tier2/nathan_animal_v3-thumbnail.jpg';
import Tier2NathanAnimalV6Expanded from 'assets/gallery/tier2/nathan_animal_v6-expanded.jpg';
import Tier2NathanAnimalV6Thumbnail from 'assets/gallery/tier2/nathan_animal_v6-thumbnail.jpg';
import Tier2NathanAnimalV7Expanded from 'assets/gallery/tier2/nathan_animal_v7-expanded.jpg';
import Tier2NathanAnimalV7Thumbnail from 'assets/gallery/tier2/nathan_animal_v7-thumbnail.jpg';
import Tier2NathanFemaleV2Expanded from 'assets/gallery/tier2/nathan_female_v2-expanded.jpg';
import Tier2NathanFemaleV2Thumbnail from 'assets/gallery/tier2/nathan_Female_v2-thumbnail.jpg';
import Tier2NathanFemaleV3Expanded from 'assets/gallery/tier2/nathan_female_v3-expanded.jpg';
import Tier2NathanFemaleV3Thumbnail from 'assets/gallery/tier2/nathan_Female_v3-thumbnail.jpg';
import Tier2NathanFemaleV4Expanded from 'assets/gallery/tier2/nathan_female_v4-expanded.jpg';
import Tier2NathanFemaleV4Thumbnail from 'assets/gallery/tier2/nathan_Female_v4-thumbnail.jpg';
import Tier2NathanFemaleV6Expanded from 'assets/gallery/tier2/nathan_female_v6-expanded.jpg';
import Tier2NathanFemaleV6Thumbnail from 'assets/gallery/tier2/nathan_Female_v6-thumbnail.jpg';
import Tier2NathanFemaleV7Expanded from 'assets/gallery/tier2/nathan_female_v7-expanded.jpg';
import Tier2NathanFemaleV7Thumbnail from 'assets/gallery/tier2/nathan_Female_v7-thumbnail.jpg';
import Tier2NathanMaleV2Expanded from 'assets/gallery/tier2/nathan_male_v2-expanded.jpg';
import Tier2NathanMaleV2Thumbnail from 'assets/gallery/tier2/nathan_male_v2-thumbnail.jpg';
import Tier2NathanMaleV3Expanded from 'assets/gallery/tier2/nathan_male_v3-expanded.jpg';
import Tier2NathanMaleV3Thumbnail from 'assets/gallery/tier2/nathan_male_v3-thumbnail.jpg';
import Tier2NathanMaleV4Expanded from 'assets/gallery/tier2/nathan_male_v4-expanded.jpg';
import Tier2NathanMaleV4Thumbnail from 'assets/gallery/tier2/nathan_male_v4-thumbnail.jpg';
import Tier2NathanMaleV6Expanded from 'assets/gallery/tier2/nathan_male_v6-expanded.jpg';
import Tier2NathanMaleV6Thumbnail from 'assets/gallery/tier2/nathan_male_v6-thumbnail.jpg';
import Tier2NathanMaleV7Expanded from 'assets/gallery/tier2/nathan_male_v7-expanded.jpg';
import Tier2NathanMaleV7Thumbnail from 'assets/gallery/tier2/nathan_male_v7-thumbnail.jpg';
import Tier2NathanRobotV2Expanded from 'assets/gallery/tier2/nathan_robot_v2-expanded.jpg';
import Tier2NathanRobotV2Thumbnail from 'assets/gallery/tier2/nathan_Robot_v2-thumbnail.jpg';
import Tier2NathanRobotV3Expanded from 'assets/gallery/tier2/nathan_robot_v3-expanded.jpg';
import Tier2NathanRobotV3Thumbnail from 'assets/gallery/tier2/nathan_Robot_v3-thumbnail.jpg';
import Tier2NathanRobotV4Expanded from 'assets/gallery/tier2/nathan_robot_v4-expanded.jpg';
import Tier2NathanRobotV4Thumbnail from 'assets/gallery/tier2/nathan_Robot_v4-thumbnail.jpg';
import Tier2NathanRobotV6Expanded from 'assets/gallery/tier2/nathan_robot_v6-expanded.jpg';
import Tier2NathanRobotV6Thumbnail from 'assets/gallery/tier2/nathan_Robot_v6-thumbnail.jpg';
import Tier2NathanRobotV7Expanded from 'assets/gallery/tier2/nathan_robot_v7-expanded.jpg';
import Tier2NathanRobotV7Thumbnail from 'assets/gallery/tier2/nathan_Robot_v7-thumbnail.jpg';
import Tier2NathanSkeletonV2Expanded from 'assets/gallery/tier2/nathan_skeleton_v2-expanded.jpg';
import Tier2NathanSkeletonV2Thumbnail from 'assets/gallery/tier2/nathan_Skeleton_v2-thumbnail.jpg';
import Tier2NathanSkeletonV3Expanded from 'assets/gallery/tier2/nathan_skeleton_v3-expanded.jpg';
import Tier2NathanSkeletonV3Thumbnail from 'assets/gallery/tier2/nathan_Skeleton_v3-thumbnail.jpg';
import Tier2NathanSkeletonV4Expanded from 'assets/gallery/tier2/nathan_skeleton_v4-expanded.jpg';
import Tier2NathanSkeletonV6Expanded from 'assets/gallery/tier2/nathan_skeleton_v6-expanded.jpg';
import Tier2NathanSkeletonV6Thumbnail from 'assets/gallery/tier2/nathan_Skeleton_v6-thumbnail.jpg';
import Tier2NathanSkeletonV7Expanded from 'assets/gallery/tier2/nathan_skeleton_v7-expanded.jpg';
import Tier2NathanSkeletonV7Thumbnail from 'assets/gallery/tier2/nathan_Skeleton_v7-thumbnail.jpg';

import Tier2OsinachiAnimalV2Expanded from 'assets/gallery/tier2/osinachi-animal-v2-expanded.jpg';
import Tier2OsinachiAnimalV2Thumbnail from 'assets/gallery/tier2/osinachi-animal-v2-thumbnail.jpg';
import Tier2OsinachiAnimalV3Expanded from 'assets/gallery/tier2/osinachi-animal-v3-expanded.jpg';
import Tier2OsinachiAnimalV3Thumbnail from 'assets/gallery/tier2/osinachi-animal-v3-thumbnail.jpg';
import Tier2OsinachiAnimalV5Expanded from 'assets/gallery/tier2/osinachi-animal-v5-expanded.jpg';
import Tier2OsinachiAnimalV5Thumbnail from 'assets/gallery/tier2/osinachi-animal-v5-thumbnail.jpg';
import Tier2OsinachiAnimalV7Expanded from 'assets/gallery/tier2/osinachi-animal-v7-expanded.jpg';
import Tier2OsinachiAnimalV7Thumbnail from 'assets/gallery/tier2/osinachi-animal-v7-thumbnail.jpg';
import Tier2OsinachiFemaleV2Expanded from 'assets/gallery/tier2/osinachi-female-v2-expanded.jpg';
import Tier2OsinachiFemaleV2Thumbnail from 'assets/gallery/tier2/osinachi-female-v2-thumbnail.jpg';
import Tier2OsinachiFemaleV3Expanded from 'assets/gallery/tier2/osinachi-female-v3-expanded.jpg';
import Tier2OsinachiFemaleV3Thumbnail from 'assets/gallery/tier2/osinachi-female-v3-thumbnail.jpg';
import Tier2OsinachiFemaleV5Expanded from 'assets/gallery/tier2/osinachi-female-v5-expanded.jpg';
import Tier2OsinachiFemaleV6Thumbnail from 'assets/gallery/tier2/osinachi-female-v6-thumbnail.jpg';
import Tier2OsinachiFemaleV7Expanded from 'assets/gallery/tier2/osinachi-female-v7-expanded.jpg';
import Tier2OsinachiFemaleV7Thumbnail from 'assets/gallery/tier2/osinachi-female-v7-thumbnail.jpg';
import Tier2OsinachiMaleV2Expanded from 'assets/gallery/tier2/osinachi-male-v2-expanded.jpg';
import Tier2OsinachiMaleV2Thumbnail from 'assets/gallery/tier2/osinachi-male-v2-thumbnail.jpg';
import Tier2OsinachiMaleV3Expanded from 'assets/gallery/tier2/osinachi-male-v3-expanded.jpg';
import Tier2OsinachiMaleV3Thumbnail from 'assets/gallery/tier2/osinachi-male-v3-thumbnail.jpg';
import Tier2OsinachiMaleV6Expanded from 'assets/gallery/tier2/osinachi-male-v6-expanded.jpg';
import Tier2OsinachiMaleV6Thumbnail from 'assets/gallery/tier2/osinachi-male-v6-thumbnail.jpg';
import Tier2OsinachiMaleV7Expanded from 'assets/gallery/tier2/osinachi-male-v7-expanded.jpg';
import Tier2OsinachiMaleV7Thumbnail from 'assets/gallery/tier2/osinachi-male-v7-thumbnail.jpg';
import Tier2OsinachiRobotV2Expanded from 'assets/gallery/tier2/osinachi-robot-v2-expanded.jpg';
import Tier2OsinachiRobotV2Thumbnail from 'assets/gallery/tier2/osinachi-robot-v2-thumbnail.jpg';
import Tier2OsinachiRobotV3Expanded from 'assets/gallery/tier2/osinachi-robot-v3-expanded.jpg';
import Tier2OsinachiRobotV3Thumbnail from 'assets/gallery/tier2/osinachi-robot-v3-thumbnail.jpg';
import Tier2OsinachiRobotV5Expanded from 'assets/gallery/tier2/osinachi-robot-v5-expanded.jpg';
import Tier2OsinachiRobotV5Thumbnail from 'assets/gallery/tier2/osinachi-robot-v5-thumbnail.jpg';
import Tier2OsinachiRobotV7Expanded from 'assets/gallery/tier2/osinachi-robot-v7-expanded.jpg';
import Tier2OsinachiRobotV7Thumbnail from 'assets/gallery/tier2/osinachi-robot-v7-thumbnail.jpg';
import Tier2OsinachiSkeletonV2Expanded from 'assets/gallery/tier2/osinachi-skeleton-v2-expanded.jpg';
import Tier2OsinachiSkeletonV2Thumbnail from 'assets/gallery/tier2/osinachi-skeleton-v2-thumbnail.jpg';
import Tier2OsinachiSkeletonV3Expanded from 'assets/gallery/tier2/osinachi-skeleton-v3-expanded.jpg';
import Tier2OsinachiSkeletonV3Thumbnail from 'assets/gallery/tier2/osinachi-skeleton-v3-thumbnail.jpg';
import Tier2OsinachiSkeletonV5Expanded from 'assets/gallery/tier2/osinachi-skeleton-v5-expanded.jpg';
import Tier2OsinachiSkeletonV5Thumbnail from 'assets/gallery/tier2/osinachi-skeleton-v5-thumbnail.jpg';
import Tier2OsinachiSkeletonV7Expanded from 'assets/gallery/tier2/osinachi-skeleton-v7-expanded.jpg';
import Tier2OsinachiSkeletonV7Thumbnail from 'assets/gallery/tier2/osinachi-skeleton-v7-thumbnail.jpg';

import Tier3DylanBullV2Expanded from 'assets/gallery/tier3/dylan-bull-v2-expanded.mp4';
import Tier3DylanBullV2Thumbnail from 'assets/gallery/tier3/dylan-bull-v2-thumbnail.jpg';
import Tier3DylanBullV4Expanded from 'assets/gallery/tier3/dylan-bull-v4-expanded.mp4';
import Tier3DylanBullV4Thumbnail from 'assets/gallery/tier3/dylan-bull-v4-thumbnail.jpg';
import Tier3DylanBullV5Expanded from 'assets/gallery/tier3/dylan-bull-v5-expanded.mp4';
import Tier3DylanBullV5Thumbnail from 'assets/gallery/tier3/dylan-bull-v5-thumbnail.jpg';
import Tier3DylanFemaleV2Expanded from 'assets/gallery/tier3/dylan-female-v2-expanded.mp4';
import Tier3DylanFemaleV2Thumbnail from 'assets/gallery/tier3/dylan-female-v2-thumbnail.jpg';
import Tier3DylanFemaleV4Expanded from 'assets/gallery/tier3/dylan-female-v4-expanded.mp4';
import Tier3DylanFemaleV4Thumbnail from 'assets/gallery/tier3/dylan-female-v4-thumbnail.jpg';
import Tier3DylanMaleV2Expanded from 'assets/gallery/tier3/dylan-male-v2-expanded.mp4';
import Tier3DylanMaleV2Thumbnail from 'assets/gallery/tier3/dylan-male-v2-thumbnail.jpg';
import Tier3DylanMaleV4Expanded from 'assets/gallery/tier3/dylan-male-v4-expanded.mp4';
import Tier3DylanMaleV4Thumbnail from 'assets/gallery/tier3/dylan-male-v4-thumbnail.jpg';
import Tier3DylanMaleV5Expanded from 'assets/gallery/tier3/dylan-male-v5-expanded.mp4';
import Tier3DylanMaleV5Thumbnail from 'assets/gallery/tier3/dylan-male-v5-thumbnail.jpg';
import Tier3DylanRobotV2Expanded from 'assets/gallery/tier3/dylan-robot-v2-expanded.mp4';
import Tier3DylanRobotV2Thumbnail from 'assets/gallery/tier3/dylan-robot-v2-thumbnail.jpg';
import Tier3DylanRobotV4Expanded from 'assets/gallery/tier3/dylan-robot-v4-expanded.mp4';
import Tier3DylanRobotV4Thumbnail from 'assets/gallery/tier3/dylan-robot-v4-thumbnail.jpg';
import Tier3DylanRobotV5Expanded from 'assets/gallery/tier3/dylan-robot-v5-expanded.mp4';
import Tier3DylanRobotV5Thumbnail from 'assets/gallery/tier3/dylan-robot-v5-thumbnail.jpg';
import Tier3DylanSkeletonV2Expanded from 'assets/gallery/tier3/dylan-skeleton-v2-expanded.mp4';
import Tier3DylanSkeletonV2Thumbnail from 'assets/gallery/tier3/dylan-skeleton-v2-thumbnail.jpg';
import Tier3DylanSkeletonV3Expanded from 'assets/gallery/tier3/dylan-skeleton-v3-expanded.mp4';
import Tier3DylanSkeletonV3Thumbnail from 'assets/gallery/tier3/dylan-skeleton-v3-thumbnail.jpg';
import Tier3DylanSkeletonV4Expanded from 'assets/gallery/tier3/dylan-skeleton-v4-expanded.mp4';
import Tier3DylanSkeletonV4Thumbnail from 'assets/gallery/tier3/dylan-skeleton-v4-thumbnail.jpg';

import Tier3CorbinAnimalV3Expanded from 'assets/gallery/tier3/Corbin-animal-v3-expanded.jpg';
import Tier3CorbinAnimalV4Expanded from 'assets/gallery/tier3/Corbin-animal-v4-expanded.jpg';
import Tier3CorbinAnimalV5Expanded from 'assets/gallery/tier3/Corbin-animal-v5-expanded.jpg';
import Tier3CorbinFemaleV3Expanded from 'assets/gallery/tier3/Corbin-female-v3-expanded.jpg';
import Tier3CorbinFemaleV3Thumbnail from 'assets/gallery/tier3/Corbin-female-v3-thumbnail.jpg';
import Tier3CorbinFemaleV4Expanded from 'assets/gallery/tier3/Corbin-female-v4-expanded.jpg';
import Tier3CorbinFemaleV4Thumbnail from 'assets/gallery/tier3/Corbin-female-v4-thumbnail.jpg';
import Tier3CorbinFemaleV5Expanded from 'assets/gallery/tier3/Corbin-female-v5-expanded.jpg';
import Tier3CorbinFemaleV5Thumbnail from 'assets/gallery/tier3/Corbin-female-v5-thumbnail.jpg';
import Tier3CorbinMaleV3Expanded from 'assets/gallery/tier3/corbin-male-v3-expanded.jpg';
import Tier3CorbinMaleV3Thumbnail from 'assets/gallery/tier3/corbin-male-v3-thumbnail.jpg';
import Tier3CorbinMaleV4Expanded from 'assets/gallery/tier3/corbin-male-v4-expanded.jpg';
import Tier3CorbinMaleV4Thumbnail from 'assets/gallery/tier3/corbin-male-v4-thumbnail.jpg';
import Tier3CorbinMaleV5Expanded from 'assets/gallery/tier3/corbin-male-v5-expanded.jpg';
import Tier3CorbinMaleV5Thumbnail from 'assets/gallery/tier3/corbin-male-v5-thumbnail.jpg';
import Tier3CorbinRobotV3Expanded from 'assets/gallery/tier3/corbin-robot-v3-expanded.jpg';
import Tier3CorbinRobotV3Thumbnail from 'assets/gallery/tier3/corbin-robot-v3-thumbnail.jpg';
import Tier3CorbinRobotV4Expanded from 'assets/gallery/tier3/corbin-robot-v4-expanded.jpg';
import Tier3CorbinRobotV4Thumbnail from 'assets/gallery/tier3/corbin-robot-v4-thumbnail.jpg';
import Tier3CorbinRobotV5Expanded from 'assets/gallery/tier3/corbin-robot-v5-expanded.jpg';
import Tier3CorbinRobotV5Thumbnail from 'assets/gallery/tier3/corbin-robot-v5-thumbnail.jpg';
import Tier3CorbinSkeletonV3Expanded from 'assets/gallery/tier3/corbin-skeleton-v3-expanded.jpg';
import Tier3CorbinSkeletonV4Expanded from 'assets/gallery/tier3/corbin-skeleton-v4-expanded.jpg';
import Tier3CorbinSkeletonV5Expanded from 'assets/gallery/tier3/corbin-skeleton-v5-expanded.jpg';
import Tier3CorbinAnimalV3Thumbnail from 'assets/gallery/tier3/corbin_animal_v3-thumbnail.jpg';
import Tier3CorbinAnimalV4Thumbnail from 'assets/gallery/tier3/corbin_animal_v4-thumbnail.jpg';
import Tier3CorbinAnimalV5Thumbnail from 'assets/gallery/tier3/corbin_animal_v5-thumbnail.jpg';
import Tier3CorbinSkeletonV3Thumbnail from 'assets/gallery/tier3/corbin_skeleton_v3-thumbnail.jpg';
import Tier3CorbinSkeletonV4Thumbnail from 'assets/gallery/tier3/corbin_skeleton_v4-thumbnail.jpg';
import Tier3CorbinSkeletonV5Thumbnail from 'assets/gallery/tier3/corbin_skeleton_v5-thumbnail.jpg';

import Tier3OsinachiAnimalMainExpanded from 'assets/gallery/tier3/osinachi-animal-main-expanded.jpg';
import Tier3OsinachiAnimalMainThumbnail from 'assets/gallery/tier3/osinachi-animal-main-thumbnail.jpg';
import Tier3OsinachiAnimalV6Expanded from 'assets/gallery/tier3/osinachi-animal-v6-expanded.jpg';
import Tier3OsinachiAnimalV6Thumbnail from 'assets/gallery/tier3/osinachi-animal-v6-thumbnail.jpg';
import Tier3OsinachiFemaleMainExpanded from 'assets/gallery/tier3/osinachi-female-main-expanded.jpg';
import Tier3OsinachiFemaleMainThumbnail from 'assets/gallery/tier3/osinachi-female-main-thumbnail.jpg';
import Tier3OsinachiFemaleV6Expanded from 'assets/gallery/tier3/osinachi-female-v6-expanded.jpg';
import Tier3OsinachiFemaleV6Thumbnail from 'assets/gallery/tier3/osinachi-female-v6-thumbnail.jpg';
import Tier3OsinachiMaleMainExpanded from 'assets/gallery/tier3/osinachi-male-main-expanded.jpg';
import Tier3OsinachiMaleMainThumbnail from 'assets/gallery/tier3/osinachi-male-main-thumbnail.jpg';
import Tier3OsinachiMaleV1Expanded from 'assets/gallery/tier3/osinachi-male-v1-expanded.jpg';
import Tier3OsinachiMaleV1Thumbnail from 'assets/gallery/tier3/osinachi-male-v1-thumbnail.jpg';
import Tier3OsinachiRobotMainExpanded from 'assets/gallery/tier3/osinachi-robot-main-expanded.jpg';
import Tier3OsinachiRobotMainThumbnail from 'assets/gallery/tier3/osinachi-robot-main-thumbnail.jpg';
import Tier3OsinachiRobotV6Expanded from 'assets/gallery/tier3/osinachi-robot-v6-expanded.jpg';
import Tier3OsinachiRobotV6Thumbnail from 'assets/gallery/tier3/osinachi-robot-v6-thumbnail.jpg';
import Tier3OsinachiSkeletonV4Expanded from 'assets/gallery/tier3/osinachi-skeleton-v4-expanded.jpg';
import Tier3OsinachiSkeletonV4Thumbnail from 'assets/gallery/tier3/osinachi-skeleton-v4-thumbnail.jpg';
import Tier3OsinachiSkeletonV6Expanded from 'assets/gallery/tier3/osinachi-skeleton-v6-expanded.jpg';
import Tier3OsinachiSkeletonV6Thumbnail from 'assets/gallery/tier3/osinachi-skeleton-v6-thumbnail.jpg';

import Tier3NathanFemaleV5Thumbnail from 'assets/gallery/tier3/nathan_Female_v5-thumbnail.jpg';
import Tier3NathanRobotV5Thumbnail from 'assets/gallery/tier3/nathan_Robot_v5-thumbnail.jpg';
import Tier3NathanSkeletonV5Thumbnail from 'assets/gallery/tier3/nathan_Skeleton_v5-thumbnail.jpg';
import Tier3NathanAnimalV4Expanded from 'assets/gallery/tier3/nathan_animal_v4-expanded.jpg';
import Tier3NathanAnimalV4Thumbnail from 'assets/gallery/tier3/nathan_animal_v4-thumbnail.jpg';
import Tier3NathanFemaleV5Expanded from 'assets/gallery/tier3/nathan_female_v5-expanded.jpg';
import Tier3NathanMaleV5Expanded from 'assets/gallery/tier3/nathan_male_v5-expanded.jpg';
import Tier3NathanMaleV5Thumbnail from 'assets/gallery/tier3/nathan_male_v5-thumbnail.jpg';
import Tier3NathanRobotV5Expanded from 'assets/gallery/tier3/nathan_robot_v5-expanded.jpg';
import Tier3NathanSkeletonV5Expanded from 'assets/gallery/tier3/nathan_skeleton_v5-expanded.jpg';

import Tier3DurkBullV1Expanded from 'assets/gallery/tier3/durk-Bull-v1-expanded.jpg';
import Tier3DurkBullV1Thumbnail from 'assets/gallery/tier3/durk-bull-v1-thumbnail.jpg';
import Tier3DurkBullV2Expanded from 'assets/gallery/tier3/durk-Bull-v2-expanded.jpg';
import Tier3DurkBullV2Thumbnail from 'assets/gallery/tier3/durk-bull-v2-thumbnail.jpg';
import Tier3DurkBullV4Expanded from 'assets/gallery/tier3/durk-Bull-v4-expanded.jpg';
import Tier3DurkBullV4Thumbnail from 'assets/gallery/tier3/durk-bull-v4-thumbnail.jpg';
import Tier3DurkBullV7Expanded from 'assets/gallery/tier3/durk-Bull-v7-expanded.jpg';
import Tier3DurkBullV7Thumbnail from 'assets/gallery/tier3/durk-bull-v7-thumbnail.jpg';
import Tier3DurkFemaleV1Expanded from 'assets/gallery/tier3/durk-female-v1-expanded.jpg';
import Tier3DurkFemaleV1Thumbnail from 'assets/gallery/tier3/durk-female-v1-thumbnail.jpg';
import Tier3DurkFemaleV2Expanded from 'assets/gallery/tier3/durk-female-v2-expanded.jpg';
import Tier3DurkFemaleV2Thumbnail from 'assets/gallery/tier3/durk-female-v2-thumbnail.jpg';
import Tier3DurkFemaleV4Expanded from 'assets/gallery/tier3/durk-female-v4-expanded.jpg';
import Tier3DurkFemaleV4Thumbnail from 'assets/gallery/tier3/durk-female-v4-thumbnail.jpg';
import Tier3DurkFemaleV7Expanded from 'assets/gallery/tier3/durk-female-v7-expanded.jpg';
import Tier3DurkFemaleV7Thumbnail from 'assets/gallery/tier3/durk-female-v7-thumbnail.jpg';
import Tier3DurkMaleV1Expanded from 'assets/gallery/tier3/durk-male-v1-expanded.jpg';
import Tier3DurkMaleV1Thumbnail from 'assets/gallery/tier3/durk-male-v1-thumbnail.jpg';
import Tier3DurkMaleV2Expanded from 'assets/gallery/tier3/durk-male-v2-expanded.jpg';
import Tier3DurkMaleV2Thumbnail from 'assets/gallery/tier3/durk-male-v2-thumbnail.jpg';
import Tier3DurkMaleV4Expanded from 'assets/gallery/tier3/durk-male-v4-expanded.jpg';
import Tier3DurkMaleV4Thumbnail from 'assets/gallery/tier3/durk-male-v4-thumbnail.jpg';
import Tier3DurkMaleV7Expanded from 'assets/gallery/tier3/durk-male-v7-expanded.jpg';
import Tier3DurkMaleV7Thumbnail from 'assets/gallery/tier3/durk-male-v7-thumbnail.jpg';
import Tier3DurkRobotV1Expanded from 'assets/gallery/tier3/durk-robot-v1-expanded.jpg';
import Tier3DurkRobotV1Thumbnail from 'assets/gallery/tier3/durk-robot-v1-thumbnail.jpg';
import Tier3DurkRobotV2Expanded from 'assets/gallery/tier3/durk-robot-v2-expanded.jpg';
import Tier3DurkRobotV2Thumbnail from 'assets/gallery/tier3/durk-robot-v2-thumbnail.jpg';
import Tier3DurkRobotV4Expanded from 'assets/gallery/tier3/durk-robot-v4-expanded.jpg';
import Tier3DurkRobotV4Thumbnail from 'assets/gallery/tier3/durk-robot-v4-thumbnail.jpg';
import Tier3DurkRobotV7Expanded from 'assets/gallery/tier3/durk-robot-v7-expanded.jpg';
import Tier3DurkRobotV7Thumbnail from 'assets/gallery/tier3/durk-robot-v7-thumbnail.jpg';
import Tier3DurkSkeletonV1Expanded from 'assets/gallery/tier3/durk-skeleton-v1-expanded.jpg';
import Tier3DurkSkeletonV1Thumbnail from 'assets/gallery/tier3/durk-skeleton-v1-thumbnail.jpg';
import Tier3DurkSkeletonV2Expanded from 'assets/gallery/tier3/durk-skeleton-v2-expanded.jpg';
import Tier3DurkSkeletonV2Thumbnail from 'assets/gallery/tier3/durk-skeleton-v2-thumbnail.jpg';
import Tier3DurkSkeletonV4Expanded from 'assets/gallery/tier3/durk-skeleton-v4-expanded.jpg';
import Tier3DurkSkeletonV4Thumbnail from 'assets/gallery/tier3/durk-skeleton-v4-thumbnail.jpg';
import Tier3DurkSkeletonV7Expanded from 'assets/gallery/tier3/durk-skeleton-v7-expanded.jpg';
import Tier3DurkSkeletonV7Thumbnail from 'assets/gallery/tier3/durk-skeleton-v7-thumbnail.jpg';

import Tier3SEBullSmall720p from 'assets/gallery/tier3/SE_Bull small-720p.mp4';
import Tier3ThumbnailBullVideo from 'assets/gallery/tier3/thumbnail_Bull_video.jpg';
import Tier3SEFemaleSmall720p from 'assets/gallery/tier3/SE_Female small-720p.mp4';
import Tier3ThumbnailFemaleVideo from 'assets/gallery/tier3/thumbnail-Female_video.jpg';
import Tier3SEMaleSmall720p from 'assets/gallery/tier3/SE_Male small-720p.mp4';
import Tier3ThumbnailMaleVideo from 'assets/gallery/tier3/thumbnail_Male_video.jpg';
import Tier3SERobotSmall720p from 'assets/gallery/tier3/SE_Robot small-720p.mp4';
import Tier3ThumbnailRobotVideo from 'assets/gallery/tier3/thumbnail_Robot_video.jpg';
import Tier3SESkeletonSmall720p from 'assets/gallery/tier3/SE_Skeleton small-720p.mp4';
import Tier3ThumbnailSkeletonVideo from 'assets/gallery/tier3/thumbnail-Skeleton_video.jpg';

const SECTIONS = [
  {
    isEnabled: true,
    title: 'Tier 1',
    media: [
      {
        type: 'image',
        media: Tier1NathanAnimalV1Expand,
        thumbnail: Tier1NathanAnimalV1Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanAnimalV4Expand,
        thumbnail: Tier1NathanAnimalV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanFemaleV1Expand,
        thumbnail: Tier1NathanFemaleV1Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanFemaleV4Expand,
        thumbnail: Tier1NathanFemaleV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanMaleV1Expand,
        thumbnail: Tier1NathanMaleV1Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanMaleV4Expand,
        thumbnail: Tier1NathanMaleV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanRobotV1Expand,
        thumbnail: Tier1NathanRobotV1Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanRobotV4Expand,
        thumbnail: Tier1NathanRobotV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanSkeletonV1Expand,
        thumbnail: Tier1NathanSkeletonV1Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1NathanSkeletonV4Expand,
        thumbnail: Tier1NathanSkeletonV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier1CorbinAnimalV1Expanded,
        thumbnail: Tier1CorbinAnimalV1Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier1CorbinFemaleV1Expanded,
        thumbnail: Tier1CorbinFemaleV1Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier1CorbinMaleV1Expanded,
        thumbnail: Tier1CorbinMaleV1Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier1CorbinRobotV1Expanded,
        thumbnail: Tier1CorbinRobotV1Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier1CorbinSkeletonV1Expanded,
        thumbnail: Tier1CorbinSkeletonV1Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier1DurkBullV5Expanded,
        thumbnail: Tier1DurkBullV5Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier1DurkFemaleV5Expanded,
        thumbnail: Tier1DurkFemaleV5Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier1DurkMaleV5Expanded,
        thumbnail: Tier1DurkMaleV5Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier1DurkRobotV5Expanded,
        thumbnail: Tier1DurkRobotV5Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier1DurkSkeletonV5Expanded,
        thumbnail: Tier1DurkSkeletonV5Thumbnail,
        artist: 'durk',
      },
      {
        type: 'video',
        media: Tier1DylanBullV1Expanded,
        thumbnail: Tier1DylanBullV1Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier1DylanFemaleV1Expanded,
        thumbnail: Tier1DylanFemaleV1Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier1DylanMaleV1Expanded,
        thumbnail: Tier1DylanMaleV1Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier1DylanRobotV1Expanded,
        thumbnail: Tier1DylanRobotV1Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier1DylanSkeletonV1Expanded,
        thumbnail: Tier1DylanSkeletonV1Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'image',
        media: Tier1OsinachiAnimalV1Expanded,
        thumbnail: Tier1OsinachiAnimalV1Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier1OsinachiAnimalV4Expanded,
        thumbnail: Tier1OsinachiAnimalV4Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier1OsinachiFemaleV1Expanded,
        thumbnail: Tier1OsinachiFemaleV1Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier1OsinachiFemaleV4Expanded,
        thumbnail: Tier1OsinachiFemaleV4Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier1OsinachiMaleV4Expanded,
        thumbnail: Tier1OsinachiMaleV4Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier1OsinachiRobotV1Expanded,
        thumbnail: Tier1OsinachiRobotV1Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier1OsinachiRobotV4Expanded,
        thumbnail: Tier1OsinachiRobotV4Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier1OsinachiSkeletonMainExpanded,
        thumbnail: Tier1OsinachiSkeletonMainThumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier1OsinachiSkeletonV1Expanded,
        thumbnail: Tier1OsinachiSkeletonV1Thumbnail,
        artist: 'prince',
      },
    ]
  },
  {
    isEnabled: true,
    title: 'Tier 2',
    media: [
      {
        type: 'image',
        media: Tier2DurkBullV3Expanded,
        thumbnail: Tier2DurkBullV3Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkBullV6Expanded,
        thumbnail: Tier2DurkBullV6Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkFemaleV3Expanded,
        thumbnail: Tier2DurkFemaleV3Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkFemaleV6Expanded,
        thumbnail: Tier2DurkFemaleV6Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkMaleV3Expanded,
        thumbnail: Tier2DurkMaleV3Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkMaleV6Expanded,
        thumbnail: Tier2DurkMaleV6Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkRobotV3Expanded,
        thumbnail: Tier2DurkRobotV3Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkRobotV6Expanded,
        thumbnail: Tier2DurkRobotV6Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkSkeletonV3Expanded,
        thumbnail: Tier2DurkSkeletonV3Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2DurkSkeletonV6Expanded,
        thumbnail: Tier2DurkSkeletonV6Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier2CorbinAnimalV2Expanded,
        thumbnail: Tier2CorbinAnimalV2Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier2CorbinFemaleV2Expanded,
        thumbnail: Tier2CorbinFemaleV2Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier2CorbinMaleV2Expanded,
        thumbnail: Tier2CorbinMaleV2Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier2CorbinRobotV2Expanded,
        thumbnail: Tier2CorbinRobotV2Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier2CorbinSkeletonV2Expanded,
        thumbnail: Tier2CorbinSkeletonV2Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'video',
        media: Tier2ExpandedDylanBullV3,
        thumbnail: Tier2ThumbnailDylanBullV3,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier2ExpandedDylanFemaleV3,
        thumbnail: Tier2ThumbnailDylanFemaleV3,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier2ExpandedDylanMaleV3,
        thumbnail: Tier2ThumbnailDylanMaleV3,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier2ExpandedDylanRobotV3,
        thumbnail: Tier2ThumbnailDylanRobotV3,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier2ExpandedDylanSkeletonV3,
        thumbnail: Tier2ThumbnailDylanSkeletonV3,
        artist: 'dylan',
      },
      {
        type: 'image',
        media: Tier2NathanAnimalV2Expanded,
        thumbnail: Tier2NathanAnimalV2Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanAnimalV3Expanded,
        thumbnail: Tier2NathanAnimalV3Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanAnimalV6Expanded,
        thumbnail: Tier2NathanAnimalV6Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanAnimalV7Expanded,
        thumbnail: Tier2NathanAnimalV7Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanFemaleV2Expanded,
        thumbnail: Tier2NathanFemaleV2Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanFemaleV3Expanded,
        thumbnail: Tier2NathanFemaleV3Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanFemaleV4Expanded,
        thumbnail: Tier2NathanFemaleV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanFemaleV6Expanded,
        thumbnail: Tier2NathanFemaleV6Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanFemaleV7Expanded,
        thumbnail: Tier2NathanFemaleV7Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanMaleV2Expanded,
        thumbnail: Tier2NathanMaleV2Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanMaleV3Expanded,
        thumbnail: Tier2NathanMaleV3Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanMaleV4Expanded,
        thumbnail: Tier2NathanMaleV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanMaleV6Expanded,
        thumbnail: Tier2NathanMaleV6Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanMaleV7Expanded,
        thumbnail: Tier2NathanMaleV7Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanRobotV2Expanded,
        thumbnail: Tier2NathanRobotV2Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanRobotV3Expanded,
        thumbnail: Tier2NathanRobotV3Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanRobotV4Expanded,
        thumbnail: Tier2NathanRobotV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanRobotV6Expanded,
        thumbnail: Tier2NathanRobotV6Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanRobotV7Expanded,
        thumbnail: Tier2NathanRobotV7Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanSkeletonV2Expanded,
        thumbnail: Tier2NathanSkeletonV2Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanSkeletonV3Expanded,
        thumbnail: Tier2NathanSkeletonV3Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanSkeletonV6Expanded,
        thumbnail: Tier2NathanSkeletonV6Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2NathanSkeletonV7Expanded,
        thumbnail: Tier2NathanSkeletonV7Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier2OsinachiAnimalV2Expanded,
        thumbnail: Tier2OsinachiAnimalV2Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiAnimalV3Expanded,
        thumbnail: Tier2OsinachiAnimalV3Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiAnimalV5Expanded,
        thumbnail: Tier2OsinachiAnimalV5Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiAnimalV7Expanded,
        thumbnail: Tier2OsinachiAnimalV7Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiFemaleV2Expanded,
        thumbnail: Tier2OsinachiFemaleV2Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiFemaleV3Expanded,
        thumbnail: Tier2OsinachiFemaleV3Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiFemaleV5Expanded,
        thumbnail: Tier2OsinachiFemaleV6Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiFemaleV7Expanded,
        thumbnail: Tier2OsinachiFemaleV7Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiMaleV2Expanded,
        thumbnail: Tier2OsinachiMaleV2Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiMaleV3Expanded,
        thumbnail: Tier2OsinachiMaleV3Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiMaleV6Expanded,
        thumbnail: Tier2OsinachiMaleV6Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiMaleV7Expanded,
        thumbnail: Tier2OsinachiMaleV7Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiRobotV2Expanded,
        thumbnail: Tier2OsinachiRobotV2Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiRobotV3Expanded,
        thumbnail: Tier2OsinachiRobotV3Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiRobotV5Expanded,
        thumbnail: Tier2OsinachiRobotV5Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiRobotV7Expanded,
        thumbnail: Tier2OsinachiRobotV7Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiSkeletonV2Expanded,
        thumbnail: Tier2OsinachiSkeletonV2Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiSkeletonV3Expanded,
        thumbnail: Tier2OsinachiSkeletonV3Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiSkeletonV5Expanded,
        thumbnail: Tier2OsinachiSkeletonV5Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier2OsinachiSkeletonV7Expanded,
        thumbnail: Tier2OsinachiSkeletonV7Thumbnail,
        artist: 'prince',
      },
    ],
  },
  {
    isEnabled: true,
    title: 'Tier 3',
    media: [
      {
        type: 'video',
        media: Tier3DylanBullV2Expanded,
        thumbnail: Tier3DylanBullV2Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanBullV4Expanded,
        thumbnail: Tier3DylanBullV4Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanBullV5Expanded,
        thumbnail: Tier3DylanBullV5Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanFemaleV2Expanded,
        thumbnail: Tier3DylanFemaleV2Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanFemaleV4Expanded,
        thumbnail: Tier3DylanFemaleV4Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanMaleV2Expanded,
        thumbnail: Tier3DylanMaleV2Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanMaleV4Expanded,
        thumbnail: Tier3DylanMaleV4Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanMaleV5Expanded,
        thumbnail: Tier3DylanMaleV5Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanRobotV2Expanded,
        thumbnail: Tier3DylanRobotV2Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanRobotV4Expanded,
        thumbnail: Tier3DylanRobotV4Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanRobotV5Expanded,
        thumbnail: Tier3DylanRobotV5Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanSkeletonV2Expanded,
        thumbnail: Tier3DylanSkeletonV2Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanSkeletonV3Expanded,
        thumbnail: Tier3DylanSkeletonV3Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'video',
        media: Tier3DylanSkeletonV4Expanded,
        thumbnail: Tier3DylanSkeletonV4Thumbnail,
        artist: 'dylan',
      },
      {
        type: 'image',
        media: Tier3CorbinAnimalV3Expanded,
        thumbnail: Tier3CorbinAnimalV3Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinAnimalV4Expanded,
        thumbnail: Tier3CorbinAnimalV4Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinAnimalV5Expanded,
        thumbnail: Tier3CorbinAnimalV5Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinFemaleV3Expanded,
        thumbnail: Tier3CorbinFemaleV3Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinFemaleV4Expanded,
        thumbnail: Tier3CorbinFemaleV4Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinFemaleV5Expanded,
        thumbnail: Tier3CorbinFemaleV5Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinMaleV3Expanded,
        thumbnail: Tier3CorbinMaleV3Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinMaleV4Expanded,
        thumbnail: Tier3CorbinMaleV4Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinMaleV5Expanded,
        thumbnail: Tier3CorbinMaleV5Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinRobotV3Expanded,
        thumbnail: Tier3CorbinRobotV3Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinRobotV4Expanded,
        thumbnail: Tier3CorbinRobotV4Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinRobotV5Expanded,
        thumbnail: Tier3CorbinRobotV5Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinSkeletonV3Expanded,
        thumbnail: Tier3CorbinSkeletonV3Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinSkeletonV4Expanded,
        thumbnail: Tier3CorbinSkeletonV4Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3CorbinSkeletonV5Expanded,
        thumbnail: Tier3CorbinSkeletonV5Thumbnail,
        artist: 'corbin',
      },
      {
        type: 'image',
        media: Tier3OsinachiAnimalMainExpanded,
        thumbnail: Tier3OsinachiAnimalMainThumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiAnimalV6Expanded,
        thumbnail: Tier3OsinachiAnimalV6Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiFemaleMainExpanded,
        thumbnail: Tier3OsinachiFemaleMainThumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiFemaleV6Expanded,
        thumbnail: Tier3OsinachiFemaleV6Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiMaleMainExpanded,
        thumbnail: Tier3OsinachiMaleMainThumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiMaleV1Expanded,
        thumbnail: Tier3OsinachiMaleV1Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiRobotMainExpanded,
        thumbnail: Tier3OsinachiRobotMainThumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiRobotV6Expanded,
        thumbnail: Tier3OsinachiRobotV6Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiSkeletonV4Expanded,
        thumbnail: Tier3OsinachiSkeletonV4Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3OsinachiSkeletonV6Expanded,
        thumbnail: Tier3OsinachiSkeletonV6Thumbnail,
        artist: 'prince',
      },
      {
        type: 'image',
        media: Tier3NathanFemaleV5Expanded,
        thumbnail: Tier3NathanFemaleV5Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier3NathanRobotV5Expanded,
        thumbnail: Tier3NathanRobotV5Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier3NathanAnimalV4Expanded,
        thumbnail: Tier3NathanAnimalV4Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier3NathanMaleV5Expanded,
        thumbnail: Tier3NathanMaleV5Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier3NathanSkeletonV5Expanded,
        thumbnail: Tier3NathanSkeletonV5Thumbnail,
        artist: 'nathan',
      },
      {
        type: 'image',
        media: Tier3DurkBullV1Expanded,
        thumbnail: Tier3DurkBullV1Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkBullV2Expanded,
        thumbnail: Tier3DurkBullV2Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkBullV4Expanded,
        thumbnail: Tier3DurkBullV4Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkBullV7Expanded,
        thumbnail: Tier3DurkBullV7Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkFemaleV1Expanded,
        thumbnail: Tier3DurkFemaleV1Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkFemaleV2Expanded,
        thumbnail: Tier3DurkFemaleV2Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkFemaleV4Expanded,
        thumbnail: Tier3DurkFemaleV4Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkFemaleV7Expanded,
        thumbnail: Tier3DurkFemaleV7Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkMaleV1Expanded,
        thumbnail: Tier3DurkMaleV1Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkMaleV2Expanded,
        thumbnail: Tier3DurkMaleV2Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkMaleV4Expanded,
        thumbnail: Tier3DurkMaleV4Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkMaleV7Expanded,
        thumbnail: Tier3DurkMaleV7Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkRobotV1Expanded,
        thumbnail: Tier3DurkRobotV1Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkRobotV2Expanded,
        thumbnail: Tier3DurkRobotV2Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkRobotV4Expanded,
        thumbnail: Tier3DurkRobotV4Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkRobotV7Expanded,
        thumbnail: Tier3DurkRobotV7Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkSkeletonV1Expanded,
        thumbnail: Tier3DurkSkeletonV1Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkSkeletonV2Expanded,
        thumbnail: Tier3DurkSkeletonV2Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkSkeletonV4Expanded,
        thumbnail: Tier3DurkSkeletonV4Thumbnail,
        artist: 'durk',
      },
      {
        type: 'image',
        media: Tier3DurkSkeletonV7Expanded,
        thumbnail: Tier3DurkSkeletonV7Thumbnail,
        artist: 'durk',
      },
      {
        type: 'video',
        media: Tier3SEBullSmall720p,
        thumbnail: Tier3ThumbnailBullVideo,
        artist: 'durk',
      },
      {
        type: 'video',
        media: Tier3SEFemaleSmall720p,
        thumbnail: Tier3ThumbnailFemaleVideo,
        artist: 'durk',
      },
      {
        type: 'video',
        media: Tier3SEMaleSmall720p,
        thumbnail: Tier3ThumbnailMaleVideo,
        artist: 'durk',
      },
      {
        type: 'video',
        media: Tier3SERobotSmall720p,
        thumbnail: Tier3ThumbnailRobotVideo,
        artist: 'durk',
      },
      {
        type: 'video',
        media: Tier3SESkeletonSmall720p,
        thumbnail: Tier3ThumbnailSkeletonVideo,
        artist: 'durk',
      },
    ],
  },
];

export const ARTISTS = [
  {
    id: 'corbin',
    name: 'Corbin Bell',
    url: 'https://pixelactiv.ist/',
  },
  {
    id: 'nathan',
    name: 'Nathan Walker',
    url: 'https://www.alltheprettycolors.com/',
  },
  {
    id: 'prince',
    name: 'Prince Jacon Osinachi',
    url: 'https://osinachiart.com/',
  },
  {
    id: 'durk',
    name: 'Durk van der Meer',
    url: 'https://www.durkatwork.com/about',
  },
  {
    id: 'dylan',
    name: 'Dylan Has',
    url: 'http://has.design/',
  },
];

const ArtGalleryPage = () => {
  // const sliderRef = useRef(null);
  const videoRef = useRef(null);
  const [modalMedia, setModalMedia] = useState(null);
  const [isModalVisible, setModalVisibility] = useState(false);

  const handleThumbnailClick = (sectionIndex, mediaIndex) => {

    if (modalMedia?.type === 'video') {
      videoRef.current.play();
    }

    setModalMedia(SECTIONS[sectionIndex].media[mediaIndex]);
    setModalVisibility(true);
  };

  const handleModalClose = () => {

    if (modalMedia?.type === 'video') {
      videoRef.current.pause();
    }

    setModalVisibility(false);
    setModalMedia(null);
  };

  return (
    <div className="flex bg-white text-center min-h-screen">
      <div className="relative flex-1">
        <Banner />
        <NavBar />
        <Container className="flex flex-col faq-container text-left">
          <div className="te-gallery">
            {SECTIONS.map(({ isEnabled, title, media }, i) => (
              <section className="te-gallery__section" key={uniqueId()}>
                <h2 className="te-gallery__section__title">
                  {title}
                </h2>
                {isEnabled
                  ? (
                    <ul className="te-gallery__thumbnails">
                      {media.map(({ thumbnail, type, artist }, j) => (
                        <li key={uniqueId()}>
                          <Thumbnail
                            artist={find(ARTISTS, ({ id }) => id === artist)}
                            isForVideo={type === 'video'}
                            onClick={(sectionIndex, mediaIndex) => handleThumbnailClick(sectionIndex, mediaIndex)}
                            mediaIndex={j}
                            sectionIndex={i}
                            thumbnail={thumbnail}
                          />
                        </li>
                      ))}
                    </ul>
                  )
                  : (
                    <p className="te-gallery__coming-soon-message">
                      Coming soon&hellip;
                      <br />
                      <span className="font-light text-gray-dark">
                        Artwork will be presented at closing of tier
                      </span>
                    </p>
                  )
                }
              </section>
            ))}
          </div>
        </Container>
        <div
          className={classNames('te-gallery__modal__platform', {
            'is-visible': isModalVisible,
          })}
        >
          <button
            className="te-gallery__modal__bg"
            onClick={handleModalClose}
            aria-hidden="true"
          />
          <button
            className="te-gallery__modal__close-button"
            onClick={handleModalClose}
          >
            <RiCloseLine />
            <span className="sr-only">
              close
            </span>
          </button>
          <div className="te-gallery__modal__stage">
            <div className="te-gallery__modal__wrapper">
              <div className="te-gallery__modal">
                <div className="te-gallery__modal__media">
                  {modalMedia?.type === 'image' && (
                    <LazyImage src={modalMedia.media} />
                  )}
                  {modalMedia?.type === 'video' && (
                    <LazyVideo
                      videoRef={videoRef}
                      src={modalMedia.media}
                      controls
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtGalleryPage;
