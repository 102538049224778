import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { dappContext } from "contexts/DappContext/DappContext";
import { modalContext } from "contexts/ModalContext";
import { useEtherscanLink } from "hooks/use-etherscan-link";
import { useContext, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { formatWalletAddress } from "utils/format-wallet-address";

export const LogOutModal = () => {
  const { setShowModal, logOut } = useContext(modalContext);
  const {
    address,
    walletReset,
    walletSelect,
    walletCheck,
    wallet,
  } = useContext(dappContext);

  const switchAccount = async () => {
    const select = await walletSelect();
    if (!select) return await walletReset();
    setShowModal("logOut", false);
  };

  const show = useMemo(() => logOut, [logOut]);

  const etherscanLink = useEtherscanLink(address);

  return (
    <Modal
      show={show}
      centered
      backdropClassName="modal-backdrop"
      style={{ backgroundColor: "white" }}
      dialogClassName="warning-modal border-none rounded-lg"
      onHide={() => {}}
    >
      <Modal.Header className=" flex flex-row justify-between p-4">
        <div></div>
        <div className="text-2xl xs:text-xl font-medium text-center">
          Account
        </div>
        <div
          className="pointer text-2xl xs:text-xl font-medium text-center"
          onClick={() => setShowModal("logOut", false)}
        >
          x
        </div>
      </Modal.Header>
      <Modal.Body className="modal-body text-center m-auto my-5">
        <div className="my-5">
          <div className="mt-5 mb-3 opacity-80 rounded-lg">
            {wallet && `Connected with ${wallet.name}`}
          </div>
          <div className="text-lg xs:text-md text-teal font-medium login-button py-3 md:py-3 px-4 md:px-5 menu-button bg-teal-lightest rounded-full">
            {address && formatWalletAddress(address)}
          </div>
          <div className="mt-3 mb-5">
            <a
              target="_blank"
              rel="noreferrer"
              href={etherscanLink}
              className="link text-gray"
            >
              <u>View on Etherscan</u>
            </a>
          </div>
          <PrimaryButton
            text="Switch Account"
            onClick={() => {
              setShowModal("logOut", false);
              switchAccount();
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
