import { DappContextProvider } from 'contexts/DappContext/DappContext';
import { NotifyContextProvider } from 'contexts/notifyContext';
import { ModalContextProvider } from 'contexts/ModalContext';
import { LogOutModal } from 'modals/LogOutModal';
import { BrowserRouter, Route } from 'react-router-dom';


import ArtGalleryPage from 'pages/ArtGalleryPage/ArtGalleryPage';
import ArtistPage from 'pages/ArtistPage/ArtistPage';
import LandingPage from 'pages/LandingPage/LandingPage';
import RafflePage from 'pages/RafflePage/RafflePage';

import 'styles/tailwind.css';
import 'index.scss';
import 'styles/type.scss';
import 'App.css';
import 'styles/main.css';
import 'styles/navigation.scss';
import 'styles/modals.scss';
import 'styles/video-react.css';

const App = ({ }) => (
  <div className="App">
    <DappContextProvider>
      <NotifyContextProvider>
      <ModalContextProvider>
        <LogOutModal />
        <BrowserRouter>
          <Route path={'/art-gallery'} exact={true}>
            <ArtGalleryPage />
          </Route>
          <Route path={'/artist-preview'} exact={true}>
            <ArtistPage />
          </Route>
          <Route path={'/ChainLinkGod-eth'} exact={true}>
            <RafflePage />
          </Route>
          <Route path={'/'} exact={true}>
            <LandingPage/>
          </Route>
        </BrowserRouter>
      </ModalContextProvider>
      </NotifyContextProvider>
    </DappContextProvider>
  </div>
);

export default App;
