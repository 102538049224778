import tellerLogo from "assets/teller-logo-full.svg";
import bg from "assets/wave.svg";
import nft3 from "assets/nft-tier-3.jpg";
import linkGod from "assets/gallery/TellerChainlink.png";
import clock from "assets/ic20-time.svg";
import link from "assets/chainlink-link-logo.svg";
import rarible from "assets/ic24-rarible.svg";
import lock from "assets/ic20-lock.svg";
import { useHistory } from "react-router-dom";
import check from "assets/ic16-check.svg";
import ERC20 from "contracts/ERC20.json";

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import SecondaryButton from "components/SecondaryButton/SecondaryButton";
import { dappContext } from "contexts/DappContext/DappContext";
import NavLoginButton from "components/NavLoginButton/NavLoginButton";
import ProcessingScreen from "components/ProcessingScreen/ProcessingScreen";
import SuccessScreen from "components/SuccessScreen/SuccessScreen";
import { ethers } from "ethers";
import { Transition } from "@headlessui/react";

const PDTtoUTC = (date) => date + 7 * 60 * 60 * 1000; // PDT->UTC (+7hrs)
const BATCHES = [
  {
    // Start: 3pm PDT, 03.28.21 (Sunday)
    // End: 3pm PDT, 03.30.21 (Tuesday)
    startTimestamp: PDTtoUTC(Date.UTC(2021, 2, 28, 15, 0, 0)),
    expiryTimestamp: PDTtoUTC(Date.UTC(2021, 2, 30, 15, 0, 0)),
    price: "0.1",
    src: nft3,
    id: "2",
  },
  {
    // Start: 3pm PDT, 03.30.21 (Tuesday)
    // End: 5pm PDT, 03.30.21 (Tuesday)
    startTimestamp: PDTtoUTC(Date.UTC(2021, 2, 30, 15, 0, 0)),
    expiryTimestamp: PDTtoUTC(Date.UTC(2021, 2, 30, 17, 0, 0)),
    price: "1",
    src: linkGod,
    id: "1",
  },
];
const defaultNFTAmounts = { 1: 0, 2: 0, 3: 0 }
const RafflePage = () => {
  const LAUNCH_DATE = BATCHES[1].startTimestamp; //9am PDT, 03.27.21
  const SALE_END_TIME = BATCHES[BATCHES.length - 1].expiryTimestamp; // 11pm PDT, 04.01.21
  const [saleStage, setSaleStage] = useState(null);
  const [processing, setProcessing] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const { successScreen, loadingMessage } = copy;
  const { address, isLoggedIn, setTransactions, transactions } = useContext(
    dappContext
  );
  const history = useHistory();

  useEffect(() => {
    const timeNow = Date.now();
    let newSaleStage;
    if (timeNow < LAUNCH_DATE) {
      newSaleStage = 1;
    } else if (timeNow > SALE_END_TIME) {
      newSaleStage = 3;
    } else {
      newSaleStage = 2;
    }
    setSaleStage(newSaleStage);
    console.log(`Sale stage calculated: ${newSaleStage}`);
  }, [LAUNCH_DATE, SALE_END_TIME]);

  useEffect(() => {
    if (!isLoggedIn ||!address
      || transactions !== null) return;

    const updateTransactions = async (userAddress) => {
      let etherscanProvider = new ethers.providers.EtherscanProvider();
      let address = "0x95143890162bd671d77ae9b771881a1cb76c29a4";
      let startBlock = 12143085;
      let endBlock = etherscanProvider.getBlockNumber();
      try {
        const transactions = await etherscanProvider
          .getHistory(address, startBlock, endBlock)
          .then((txs) => {
            if (txs && txs.length > 0) {
              const purchases = txs
                .filter((tx) => {
                    return tx.from.toLowerCase() == userAddress.toLowerCase();
                })
                .reduce(
                  (accumulator, currentValue) => {
                    const price = currentValue.value / (1e18).toString();
                    // let batchID = BATCHES.find((batch) => batch.price == price).id;
                    let batchID
                    if (BATCHES[1].price == price) {
                      batchID = BATCHES[1].id;
                      accumulator[batchID] += 1;
                    }
                    return accumulator;
                  },
                  defaultNFTAmounts
                );
              return purchases;
            } else {
              return defaultNFTAmounts;
            }
          });
        setTransactions(transactions);
      } catch (err) {
        console.log(err);
      }
    };
    updateTransactions(address);
  }, [isLoggedIn, address]);

  const calculateTimeLeft = (saleStage) => {
    const endTime = saleStage === 1 ? LAUNCH_DATE : SALE_END_TIME;
    const difference = endTime - Date.now();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(saleStage));

  const calculateRaffleStart = (saleStage) => {
    const endTime = saleStage === 1 ? LAUNCH_DATE : SALE_END_TIME;
    const difference = LAUNCH_DATE - Date.now();
    let timeToStart = {};
    if (difference > 0) {
      timeToStart = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeToStart;
  };
  const [timeToStart, setTimeToStart] = useState(calculateRaffleStart(saleStage));

  useEffect(() => {
    const interval1 = setInterval(() => {
      setTimeLeft(calculateTimeLeft(saleStage));
      setTimeToStart(calculateRaffleStart(saleStage));
    }, 1000);

    return () => {
      clearInterval(interval1);
    };
  }, [saleStage]);

  return (
    <div className="flex bg-white text-center  min-h-screen">
      <div className="relative flex-1">
        <Banner />
        <NavBar />
        {!processing && !transactionHash && (
          <>
            <Container
              className={`flex flex-col items-center justify-center bg-cover my-8`}
              variant="main"
            >
              <p className="text-green-02 font-bold type-h1">Our Fortune </p>
              <p className="text-green-03 font-bold type-h1">Teller Raffle</p>
              <div className="faq-drawer__content type-p-sm font-light text-black text-center mt-10">
                <p className="mb-2">
                  Join our raffle for a chance to win 1 out of 100 limited edition ChainLinkGod.eth NFT artworks. All raffle winners will get exclusive access to Teller's Alpha Launch.
                </p>
              </div>
            </Container>
            <div className="relative">
              {(saleStage === "1" || saleStage === "2") && (
                <img
                  className="absolute w-full z-10 overflow-hidden bg-white 2xl:-mt-36 xl:mt-4 md:mt-4 sm:mt-8 mt-16"
                  src={bg}
                  alt=""
                />
              )}
              {saleStage === "3" && (
                <img
                  className="absolute w-full z-10 overflow-hidden bg-white 2xl:mt-10 xl:mt-24 md:mt-24 sm:mt-32 mt-24"
                  src={bg}
                  alt=""
                />
              )}
            </div>
            <Container
              bg="bg-white"
              className="flex flex-col items-center justify-center z-10"
              variant="main"
            >
              {(saleStage === 1 || saleStage === 2) && (
                  <Countdown timeLeft={timeLeft} saleStage={saleStage} />
              )}
            </Container>
            <Container
                bg="bg-white"
                className="mt-2 flex flex-wrap justify-evenly mb-4 gap-4"
                variant="main"
            >
              <PrimaryButton
                className="w-60"
                onClick={() => window.open('https://medium.com/teller-finance/alpha-launch-5286e239f67a', '_blank')}
              >
                Learn more
              </PrimaryButton>
              <SecondaryButton className="w-60" onClick={() => history.push('/')}>
                Go to main sale
              </SecondaryButton>
            </Container>
            <BatchCards
              BATCHES={BATCHES}
              setProcessing={setProcessing}
              setTransactionHash={setTransactionHash}
            />
            <FAQ />
          </>
        )}
        {transactionHash && processing === "txConfirmed" ? (
          <SuccessScreen
            onButtonClick={() => {
              window.location.assign(`https://nft.teller.finance`);
              setTransactionHash("");
              setProcessing("");
            }}
            title={successScreen.title}
            message={
              <div className="flex flex-col align-center justify-start gap-3  ">
                <span
                  className="link underline"
                  onClick={() => {
                    window.open(
                      "https://medium.com/teller-finance/credit-economics-behind-the-fortune-teller-nft-sale-b4bc76b5d574"
                    );
                  }}
                >
                  Learn more
                </span>
                Go to dashboard or click {"  "}
                {"  "} about how to use your NFT.
                <br />
                Your NFT will be available to be claimed after the sale has
                completed.
              </div>
            }
          />
        ) : (
          processing && (
            <ProcessingScreen hash={transactionHash} status={processing} />
          )
        )}
      </div>
    </div>
  );
};

export default RafflePage;

const FAQ = () => {
  const FAQs = [
    {
      question: "What is the Fortune Teller Raffle?",
      answer:
        "Our raffle is the product of our COO's annoying insistence of making due on his Twitter promises. It is also a show of our love for the ChainLink community and team as our partners in current and future integrations. Stay tuned for more on this later this year 👀. The raffle will offer participants a chance to win 1 out of 100 limited edition ChainLinkGod.eth NFT artworks.",
      hrefText: "  Learn More",
      href:
        "https://medium.com/teller-finance/credit-economics-behind-the-fortune-teller-nft-sale-b4bc76b5d574",
      id: "1",
    },
    {
      question: "What will the NFT offer?",
      answer:
        "To participate, you must contribute a minimum of 1 LINK to our raffle contract. Individuals can contribute as many times as they want to increase their chances of winning by submitting more raffle tickets.",
      id: "2",
    },
    {
      question: "How to participate?",
      answer:
        "To participate, you must contribute a minimum of 1 LINK to our raffle contract. Individuals can contribute as many times as they want to increase their chances of winning by submitting more raffle tickets.",
      id: "3",
    },
    {
      question: "How long will the sale be open for?",
      answer:
        "The sale starts at 3 PM PDT (+7 GMT) on 03/30, and will run for two hours before closing. I.e.: SPAM. THAT. QUEUE. ",
      id: "4",
    },
    {
      question: "How will I get my raffle ticket?",
      answer:
          "Your wallet transaction is, in fact, your ticket. Each wallet transaction per 1 LINK will go into our raffle pool, out of which we will select 100 winners. ",
      id: "5",
    },
    {
      question: "When will I receive my NFT?",
      answer:
          "NFTs will be distributed at the end of Teller's general NFT sale on April 9th, 2021. All NFTs can be claimed on our raffle website after the end of our general sale on 04/09.",
      hrefText: "Raffle website",
      href:
          "/ChainLinkGod-eth",
      id: "6",
    },
    {
      question: "How do I increase my chances of winning?",
      answer:
          "To increase your chances of winning, post your transaction proof on Twitter to @ivanprz04, and follow our artist @pixelactivist. ",
      id: "7",
    },
    {
      question: "Who is the artist? ",
      answer:
          "Corbin Bell is a renowned NFT artist from San Francisco, CA. He is also a graphic and digital artist and his work has been featured in the San Francisco Chronicle for his influence in the NFT space.",
      hrefText: "See here for more.",
      href:
          "/artist-preview",
      id: "8",
    },
  ];
  return (
    <Container className="flex flex-col faq-container text-left">
      <div>
        <p className="text-green-02 font-bold type-h1">Fortune Teller</p>
        <p className="text-green-03 font-bold type-h1 mb-6">FAQ</p>
      </div>

      {FAQs.map((item, i) => {
        return <FAQItem item={item} key={i} />;
      })}
    </Container>
  );
};
const FAQItem = ({ item }) => {
  return (
    <div className="faq-drawer">
      <input
        className="faq-drawer__trigger"
        id={`faq-drawer-${item.id}`}
        type="checkbox"
      />

      <label
        className="faq-drawer__title type-p-sm font-medium text-green-02 hover:text-green-02 mb-3"
        htmlFor={`faq-drawer-${item.id}`}
      >
        {item.question}
      </label>
      <div className="faq-drawer__content-wrapper">
        <div className="faq-drawer__content type-p-sm font-light text-black">
          <p>{item.answer}</p>
          {item.hrefText && (
            <u>
              <a
                className="hover:text-green-02"
                href={item.href}
                target="_blank"
              >
                {item.hrefText}
              </a>
            </u>
          )}
        </div>
      </div>
    </div>
  );
};
const RaribleCards = ({ BATCHES }) => {
  return (
    <div className="relative z-10 my-20">
      <div className="flex flex-col sm:hidden overflow-hidden">
        <div className="flex flex-row items-center justify-center space-x-4 mb-4">
          {BATCHES.slice(0, 3).map((nft) => (
            <NFTImage src={nft.src} />
          ))}
        </div>
        <div className="flex flex-row items-center justify-center space-x-4">
          {BATCHES.slice(3).map((nft) => (
            <NFTImage src={nft.src} />
          ))}
        </div>
      </div>
      <Container className="hidden sm:flex">
        <div className="flex flex-row space-x-4 lg:space-x-10 justify-center">
          {BATCHES.map((nft, i) => (
            <div className={i % 2 === 0 ? "mt-9" : ""}>
              <NFTImage src={nft.src} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};
const NFTImage = ({ src }) => {
  return (
    <img
      src={src}
      alt="Teller NFT"
      className="w-1/3 sm:w-full rounded opacity-70"
    />
  );
};
const BatchCards = ({
  BATCHES,
  setProcessing,
  setTransactionHash,
}) => {

  const [NFT, setNFT] = useState(BATCHES[1]);

  const recalculateNFTdate = () => {
    const timeNow = Date.now();
    const timeTillExpiry = new Date(NFT.expiryTimestamp) - timeNow;
    const timeTillStart = new Date(NFT.startTimestamp) - timeNow;
    NFT.expired = Boolean(timeTillExpiry < 0);
    NFT.locked = Boolean(timeTillStart > 0);

    NFT.timeLeft = {
      hours: NFT.expired
          ? 0
          : Math.round(timeTillExpiry / (1000 * 60 * 60)),
      minutes: NFT.expired
          ? 0
          : Math.floor((timeTillExpiry / 1000 / 60) % 60),
    };
    NFT.timeTillStart = {
      hours: NFT.expired
          ? 0
          : Math.round(timeTillStart / (1000 * 60 * 60)),
      minutes: NFT.expired
          ? 0
          : Math.floor((timeTillStart / 1000 / 60) % 60),
    };
    setNFT(NFT);
  }

  useEffect(() => {

    recalculateNFTdate();
    const interval = setInterval(() => {
      recalculateNFTdate();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div className="md:flex flex-col mb-24">
        <Container
          className="items-center justify-center z-20 mb-8"
          variant="main"
        >
            {NFT.timeLeft && NFT.timeTillStart && (
                <BatchCard
                    key={`BatchCard-${1}`}
                    nft={NFT}
                    setProcessing={setProcessing}
                    setTransactionHash={setTransactionHash}
                />
            )}
        </Container>
      </div>
    </>
  );
};

const BatchCard = ({ nft, setProcessing, setTransactionHash }) => {
  const {
    provider,
    notify,
    gnosisAddress,
    wallet,
    linkAddress,
    address,
    isLoggedIn,
    transactions,
    setTransactions,
  } = useContext(dappContext);

  const [showLoggedOutWarning, setShowLoggedOutWarning] = useState(false);

  useEffect(() => {
    if (isLoggedIn);
    setShowLoggedOutWarning(false);
  }, [isLoggedIn]);
  const completePurchase = useCallback(async () => {
    /** Load in signer, send ETH */
    const signer = provider.getSigner();
    const link = new ethers.Contract(linkAddress, ERC20, signer);
    const decimals = await link.decimals();
    console.log({link, decimals})
    let hash;
    let response;
    const balance = await link.balanceOf(address);
    if (balance.gt(ethers.utils.parseUnits(nft.price, decimals))) {
      try {
        response = await link.connect(signer).transfer(
            gnosisAddress,
            ethers.utils.parseUnits(nft.price, decimals)
        );
        hash = response.hash;
      } catch (e) {
        // Signature denied
        if (e.code === 4001) {
          notify.notification({
            type: "error",
            message: "Signature rejected",
            autoDismiss: 12000,
            onclick: () => {},
            eventCode: "4001",
          });
          setTransactionHash("");
          setProcessing("");
          return;
        }
        throw e;
      }
    } else {
      notify.notification({
        type: "error",
        message: "No Balance",
        autoDismiss: 12000,
        onclick: () => {},
        eventCode: "4001",
      });
      setTransactionHash("");
      setProcessing("");
      return;
    }


    setTransactionHash(hash);
    setProcessing("processing");

    /** Emitter memes */
    const { emitter } = notify.hash(response.hash);

    emitter.on("txSpeedUp", (e) => {
      setTransactionHash(response.hash);
      setProcessing("txSpeedUp");
      console.log(response.title, " transaction was sped up, new hash: ", hash);
    });

    emitter.on("txConfirmed", (e) => {
      setProcessing("txConfirmed");
      console.log(response.title, " transaction confirmed: ", hash);
      const updatedTransactions = transactions
        ? { ...transactions }
        : defaultNFTAmounts;
      const price = nft.price / 1e18;
      // let batchID = BATCHES.find((batch) => batch.price == price).id;
      let batchID = BATCHES[1].id;
      updatedTransactions[batchID] += 1;
      setTransactions(updatedTransactions);
    });

    emitter.on("txCancel", (e) => {
      setProcessing("txCancel");
      return {
        autoDismiss: 15000,
      };
    });

    emitter.on("all", (event) => {
      console.log(response.hash, " event: ", event.eventCode);
      return {
        autoDismiss: 15000,
      };
    });


    return await response.wait();
  }, [
    provider,
    gnosisAddress,
    notify,
    setProcessing,
    setTransactionHash,
    address,
    nft,
  ]);

  return (
    <div
      style={{ boxShadow: "3px 3px 20px rgba(87, 94, 96, 0.2)" }}
      className={`
      flex-col z-20 rounded px-5 py-6 col-span-6 sm:col-span-3 md:col-span-2 lg:col-span-4 flex-1 ${
        nft.expired && "opacity-50 cursor-default"
      }`}
    >
      <div className="flex flex-row justify-between type-p-lg text-grey-01 mb-3">
        <div className="flex flex-row items-center">
          <img
            src={nft.locked ? lock : clock}
            alt="Clock"
            className="h-6 mr-1"
          />
          {nft.expired && <div>Closed</div>}
          {!nft.expired && !nft.locked && (
            <div>
              {nft.timeLeft.hours >= 1
                ? `${nft.timeLeft.hours} hrs`
                : `${nft.timeLeft.minutes} mins`}{" "}
              left
            </div>
          )}
          {!nft.expired && nft.locked && (
              <div><b>Unlocking in </b>
                {nft.timeTillStart.hours >= 1
                    ? `${nft.timeTillStart.hours} hrs`
                    : `${nft.timeTillStart.minutes} mins`}{" "}
              </div>
          )}
        </div>
        <div className="flex flex-row items-center">
          <img src={link} alt="LINK" className="h-6 mr-1" />
          <div>{nft.price} LINK</div>
        </div>
      </div>

      {transactions && transactions[nft.id] > 0 && (
        <div className="flex flex-row justify-between type-p-lg text-grey-01 mb-3">
          <div className="flex flex-row items-center">
            <img src={check} className="h-6 mr-1" />
            <div>Tickets Purchased: {transactions[nft.id]}</div>
          </div>
          <div></div>
        </div>
      )}

      <div className="shiny">
        <img src={nft.src} alt="Teller NFT" className="w-full rounded" />
      </div>
      <div className="-mt-5 z-50">
        <PrimaryButton
          disabled={nft.expired || nft.locked}
          className="w-40 z-50 relative"
          onClick={() => {
            if (!isLoggedIn) {
              setShowLoggedOutWarning(true);
              return;
            }
            setProcessing('loading');
            completePurchase();
          }}
        >
          {`Enter for ${nft.price} LINK`}
        </PrimaryButton>
        {showLoggedOutWarning && (
          <div className="type-caption text-grey-02 mt-2">
            Please connect your wallet
          </div>
        )}
      </div>
    </div>
  );
};

const Countdown = ({ saleStage, timeLeft }) => {
  const history = useHistory();
  return (
    <>
      <p className="text-green-02 font-medium type-p-sm ">
        {saleStage === 1 ? "STARTING IN" : "TIME REMAINING"}
      </p>
      <div className="flex flex-row items-start mt-6 text-green-02 sm:mb-12">
        <div className="flex flex-col items-between">
          <p className="type-p-xxl mb-4 md:w-24 sm:w-16 lg:w-32">
            {timeLeft.days < 10 && "0"}
            {timeLeft.days}
          </p>
          <p className="text-green-01 font-medium type-p-xs">
            DAY{timeLeft.days > 1 ? "S" : ""}
          </p>
        </div>
        <p className="font-bold text-2xl lg:text-4xl mx-1 sm:mx-2 lg:mx-4 my-1 md:my-5">
          :
        </p>
        <div className="flex flex-col items-between">
          <p className="type-p-xxl mb-4 md:w-24 sm:w-16 lg:w-32">
            {timeLeft.hours < 10 && "0"}
            {timeLeft.hours}
          </p>
          <p className="text-green-01 font-medium type-p-xs">
            HOUR{timeLeft.hours > 1 ? "S" : ""}
          </p>
        </div>
        <p className="font-bold text-2xl lg:text-4xl mx-1 sm:mx-2 lg:mx-4 my-1 md:my-5">
          :
        </p>
        <div className="flex flex-col items-between">
          <p className="type-p-xxl mb-4 md:w-24 sm:w-16 lg:w-32">
            {timeLeft.minutes < 10 && "0"}
            {timeLeft.minutes}
          </p>
          <p className="text-green-01 font-medium type-p-xs">
            MINUTE{timeLeft.minutes > 1 ? "S" : ""}
          </p>
        </div>
        <p className="font-bold text-2xl lg:text-4xl mx-1 sm:mx-2 lg:mx-4 my-1 md:my-5">
          :
        </p>
        <div className="flex flex-col items-between">
          <p className="type-p-xxl mb-4 md:w-24 sm:w-16 lg:w-32">
            {timeLeft.seconds < 10 && "0"}
            {timeLeft.seconds}
          </p>
          <p className="text-green-01 font-medium type-p-xs">
            SECOND{timeLeft.seconds > 1 ? "S" : ""}
          </p>
        </div>
      </div>
    </>
  );
};

const NavBar = () => {
  return (
    <Container>
      <div className="flex flex-1 space-between nav-bar">
        <Nav />
        <MobileNav />
      </div>
    </Container>
  );
};

const Banner = ({ timeLeft, saleStage }) => {
  return (
    <>
      {saleStage !== 3 && (
        <Container bg="bg-tangerine-02">
          <div
            className="text-white type-p-lg"
            style={{ margin: "18px 0 18px 0" }}
          >
            Fortune Teller's Raffle {saleStage === 1 ? "Starts" : "Ends"} in
            {timeLeft.days > 0 &&
              ` ${timeLeft.days} day${timeLeft.days > 1 ? "s" : ""}${
                timeLeft.hours > 0 || timeLeft.minutes > 0 ? "," : ""
              }`}
            {timeLeft.hours > 0 &&
              ` ${timeLeft.hours} hour${timeLeft.hours > 1 ? "s" : ""}${
                timeLeft.minutes > 0 ? "," : ""
              }`}
            {timeLeft.minutes > 0 &&
              ` ${timeLeft.minutes} minute${timeLeft.minutes > 1 ? "s" : ""}`}
            . All NFTs will be distributed when the main sale has concluded.
          </div>
        </Container>
      )}
    </>
  );
};

const Nav = ({ firstLoad }) => {
  const { landing_page_copy } = copy;
  const { isLoggedIn, walletSelect, selectingWallet, address } = useContext(
    dappContext
  );

  return (
    <div className="hidden flex-1 md:flex flex-row items-center justify-evenly md:justify-center font-medium font-sans space-x-4 md:space-x-8 lg:space-x-16">
      <a
        href="/"
        className="whitespace-nowrap h-full w-16 md:w-20"
      >
        <img
          src={tellerLogo}
          alt="Teller logo"
          className="min-h-full max-h-full"
        />
      </a>
      {landing_page_copy.navigation.links.map((link) => {
        return (
          <a
            key={link.title}
            href={link.url}
            rel="noreferrer"
            target="_blank"
            className="flex-shrink sm:text-sm md:text-base"
          >
            <div className="hover:text-green-03 ">{link.title}</div>
          </a>
        );
      })}
      <a href="https://app.teller.finance" rel="noreferrer" target="_blank">
        <div className="border-2 text-green-03 border-teal py-2 px-7  rounded-full transition duration-200 ease-in-out transform hover:scale-105 my-6">
          App
        </div>
      </a>
      <NavLoginButton />
    </div>
  );
};

const MobileNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { landing_page_copy } = copy;
  return (
    <nav className={`md:hidden sticky flex flex-1`}>
      <div className={`relative flex items-center justify-between h-16 flex-1`}>
        <div className="items-center justify-center md:items-stretch md:justify-start">
          <a href="https://www.teller.finance/">
            <img
              src={tellerLogo}
              alt="Teller logo"
              className="block h-8 w-auto"
            />
          </a>
        </div>

        <div className="flex flex-row">
          <NavLoginButton />
          <div className="md:ml-8 ml-4 flex items-center md:hidden">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded="false"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <span className="sr-only">Open main menu</span>
              {!menuOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
      <Transition
        show={menuOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute my-16 py-6 bg-white md:hidden text-center mx-auto left-0 right-0">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a href="/" rel="noreferrer" target="_blank">
              <span className="inline-block px-3 py-2 font-medium hover:text-green-03 text-green-03">
                Fortune sale
              </span>
            </a>
            {landing_page_copy.navigation.links.map((link) => {
              return (
                <a
                  key={link.title}
                  href={link.url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className="block px-3 py-2 font-medium hover:text-green-03 whitespace-nowrap">
                    {link.title}
                  </div>
                </a>
              );
            })}
            <>
              <a
                href="https://app.teller.finance"
                rel="noreferrer"
                target="_blank"
              >
                <div className="mx-auto block my-4 border-2 text-green-03 border-teal py-2 px-7 rounded-full w-min">
                  App
                </div>
              </a>
            </>
          </div>
        </div>
      </Transition>
    </nav>
  );
};

const Container = ({
  children,
  className = "",
  bg = "",
  bgImage = null,
  variant = "",
}) => {
  return (
    <div className={`flex-1 z-10 ${bg} ${className}`}>
      <div
        className={`grid lg:grid-cols-12 grid-cols-6 gap-5 md:gap-6 lg:gap-16 px-6 md:px-8 lg:px-16 max-w-screen-8xl 8xl:mx-auto w-full ${bg} ${
          variant === "cards-1" ? "items-start" : ""
        }`}
        style={
          bgImage && {
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minWidth: "100%",
          }
        }
      >
        {variant === "cards-1" ? (
          <>{children}</>
        ) : variant === "cards-2" ? (
          <>
            <div className="hidden lg:col-span-2 md:col-span-1 md:block"> </div>
            {children}
            <div className="hidden lg:col-span-2 md:col-span-1 md:block"></div>
          </>
        ) : (
          <div
            className={`${
              variant === "main"
                ? "lg:col-span-6 sm:col-span-4 lg:col-start-4 sm:col-start-2 col-span-6"
                : "col-span-12"
            } flex flex-1 justify-center ${className}`}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

const copy = {
  landing_page_copy: {
    navigation: {
      links: [
        {
          title: "Docs",
          url: "https://teller.gitbook.io/teller-docs/",
        },
        { title: "Blog", url: " https://medium.com/teller-finance" },
        {
          title: "FAQ",
          url: "https://app.gitbook.com/@teller/s/teller-docs/protocol-1/faq",
        },
      ],
    },
  },
  successScreen: { title: "Purchase successful" },
  loadingMessage: { title: "Submitting purchase" },
};

const CTA = ({ children, className = "", disabled = false }) => {
  return (
    <div
      className={`${className} rounded-full text-white ${
        disabled
          ? "opacity-80 bg-tangerine-02 hover:bg-tangerine-02 cursor-default"
          : "cursor-pointer bg-tangerine-02 hover:bg-tangerine-hover"
      } py-4 whitespace-nowrap`}
    >
      {children}
    </div>
  );
};
