import { transactionTypeEnum } from "hooks/useTransactionHandler";
import React, {
  createContext,
  Dispatch,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";

const defaultTransactionsState = {
  transactions: [],
  addTransaction: (transaction) => null,
};
const TransactionsContext = createContext(defaultTransactionsState);

export const TransactionsContextProvider = ({ children }) => {
  const [transactions, setTransactions] = useState([]);
  const addTransaction = useCallback(
    (transaction) => {
      setTransactions((prevState) => [...prevState, transaction]);
    },
    [transactions],
  );

  const values = useMemo(() => {
    return {
      transactions,
      addTransaction,
    };
  }, [transactions, addTransaction]);

  return <TransactionsContext.Provider value={values}>{children}</TransactionsContext.Provider>;
};

/**
 *Custom hook used to add successful transactions to a globally accessed list
 */
export const useTransactionsContext = () => {
  const context = useContext(TransactionsContext);
  if (context === undefined) {
    throw new Error("useTransactions must be used within a useTransactionsProvider");
  }
  return context;
};
