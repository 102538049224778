import React from 'react';

import Container from 'components/Container/Container';
import MobileNav from 'components/MobileNav/MobileNav';
import Nav from 'components/Nav/Nav';

const links = [
  {
    title: "Documentation",
    url: "https://app.gitbook.com/@teller/s/teller-docs/",
  },
  {
    title: "Gallery",
    url: "/art-gallery",
  },
  {
    title: "Artists",
    url: "/artist-preview",
  },
  {
    title: "Blog",
    url: " https://medium.com/teller-finance",
  },
];

const NavBar = () => (
  <Container>
    <div className="flex flex-1 space-between nav-bar">
      <Nav links={links} />
      <MobileNav links={links} />
    </div>
  </Container>
);

export default NavBar;
