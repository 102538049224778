const { dappContext } = require("contexts/DappContext/DappContext");
const { useContext } = require("react");

export const useNetworkName = () => {
  const { network } = useContext(dappContext);
  switch (network) {
    case 4:
      return "rinkeby";
    case 1:
      return "mainnet";
    default:
      return "";
  }
};
