import React from 'react';

const Container = ({
  children,
  className = '',
  bg = '',
  bgImage = null,
  variant = '',
}) => (
  <div className={`flex-1 z-10 ${bg} ${className}`}>
    <div
      className={`grid lg:grid-cols-12 grid-cols-6 gap-5 md:gap-6 lg:gap-16 px-6 md:px-8 lg:px-16 max-w-screen-8xl 8xl:mx-auto w-full ${bg} ${
        variant === 'cards-1' ? 'items-start' : ''
      }`}
      style={
        bgImage && {
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          minWidth: '100%',
        }
      }
    >
      {variant === 'cards-1' ? (
        <>{children}</>
      ) : variant === 'cards-2' ? (
        <>
          <div className='hidden lg:col-span-2 md:col-span-1 md:block'> </div>
          {children}
          <div className='hidden lg:col-span-2 md:col-span-1 md:block'></div>
        </>
      ) : (
        <div
          className={`${
            variant === 'main'
              ? 'lg:col-span-6 sm:col-span-4 lg:col-start-4 sm:col-start-2 col-span-6'
              : 'col-span-12'
          } flex flex-1 justify-center ${className}`}
        >
          {children}
        </div>
      )}
    </div>
  </div>
);

export default Container;