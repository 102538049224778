import React, { createContext, useContext, useMemo, useReducer } from "react";
import { useCallback } from "react";


const defaultNotificationsState = {
  notifications: null,
  addNotification: () => null,
  removeNotification: () => null,
};

const NotificationsContext = createContext(defaultNotificationsState);

export const NotificationsContextProvider = ({ children }) => {
  const [s, dispatch] = useReducer(reducer, initialState);
  const addNotification = useCallback(
    (notification) => {
      const id = s.count + 1;
      dispatch({ type: "addNotification", payload: notification });
      setTimeout(() => {
        dispatch({ type: "removeNotification", payload: id });
      }, 5000);
    },
    [s.count],
  );

  const removeNotification = useCallback((id) => {
    dispatch({ type: "removeNotification", payload: id });
  }, []);
  const notifications = s.notifications;

  const value = useMemo(() => {
    return { notifications, addNotification, removeNotification };
  }, [notifications, addNotification, removeNotification]);

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

const initialState = {
  notifications: [],
  count: 0,
};
const reducer = (
  state,
  action
) => {
  switch (action.type) {
    case "addNotification": {
      const id = state.count + 1;
      return {
        notifications: [...state.notifications, { ...action.payload, id }],
        count: state.count + 1,
      };
    }
    case "removeNotification": {
      const filteredNotifications = state.notifications.filter(
        (notification) => notification.id != action.payload,
      );
      return { ...state, notifications: filteredNotifications };
    }
    default:
      return state;
  }
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error("useNotifications must be used within a useNotificationsProvider");
  }
  return context;
};
