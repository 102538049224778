import React, { useContext } from "react";
import { getEtherscanLink } from "../../actions/HelperFunctions";
import { dappContext } from "../../contexts/DappContext/DappContext";
const TransactionHashLink = ({ hash }) => {
    const { network } = useContext(dappContext);
    const href = getEtherscanLink(hash, network);
    return (<a target="_blank" rel="noreferrer" href={href} className="link text-gray">
      <u>here</u>
    </a>);
};
export default TransactionHashLink;
