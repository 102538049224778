import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  // useDeepCompareEffect,
} from "react";
import { ethers } from 'ethers';
import LoaderComplete from "assets/LoaderComplete.svg";
import ModalFooterText from "components/Modal/ModalFooterText";
import { useClaimNFT } from "actions/ClaimNFT";

import Button from "components/Button";
import FullScreenModal from "components/FullScreenModal";
import {
  ActionLoading,
  ActionSuccess,
} from "components/FullScreenModal/FullScreenModal";
import { useAddress, useContract } from 'hooks/useContract'

import { useclaimableNFTDistributorContract } from "actions/utils"
import { useTransactionHandler } from "hooks/useTransactionHandler";
import { useDeepCompareEffect } from "react-use";

const ClaimNFTModal = React.memo(
  ({ closeModal, merkleDetails, address, setIsClaimed }) => {
  const {
    claimCTA,
    claimCTADisabled,
    claiming,
    justClaimed,
    txHash,
  } = useClaimModal(merkleDetails, address, closeModal, setIsClaimed);

  const nftAddress = useAddress("ClaimableFortuneTeller", "contract");
  const openSeaLink = `https://opensea.io/assets/${nftAddress}/0`;
  if (justClaimed) {
    setIsClaimed(true)
  }

  return (
    <FullScreenModal
      onClose={() => {
        closeModal();
      }}
      footerCTA={
        <Button
          CTA={
            justClaimed
              ? () => {
                  closeModal();
                }
              : () => {
                  claimCTA();
                }
          }
          color="green"
          type="primary"
          text={justClaimed ? "Done" : claiming ? "Claiming.." : "Claim"}
          size="md"
          disabled={claimCTADisabled}
        />
      }
      footerCopy={
        claiming ? (
          <>
            <ModalFooterText
            text=""
            highlightedText=""
            />
          </>
        ) : justClaimed ? (
          <ModalFooterText
            text="View transaction on"
            highlightedText="Etherscan"
            href={`https://etherscan.io/tx/${txHash}`}
            />
        ) : (
          <ModalFooterText
            text=""
            highlightedText=""
            />
        )
      }
      mainContent={
        <div className="justify-center items-center lg:text-center flex-1 min-h-screen ">
          {justClaimed && !claiming && (
            <>
              <div className="min-h-screen flex flex-col justify-center items-center">
                <img
                  alt="Loader Complete"
                  src={LoaderComplete}
                  height="177"
                  width="177"
                  className="d-inline-block align-top mx-auto mb-5"
                />
                <div className="type-h4 text-green-02 type-medium text-center mb-3">
                  NFT Claimed
                </div>
                <div className="type-p-sm type-light text-black text-center lg:max-w-sm mx-auto whitespace-normal">
                  Your NFT is claimed.{" "}
                  <p>
                    View on <a className="text-green-03" href={`${openSeaLink}`} target="_blank">OpenSea</a>
                  </p>
                </div>
              </div>
              <ActionSuccess
                title="NFT Claimed"
                subTitle={
                  <>
                    {" "}
                    Your NFT is claimed{" "}
                    <p>
                    View on <a className="text-green-03" href={`${openSeaLink}`} target="_blank">OpenSea</a>
                  </p>
                  </>
                }
              />
            </>
          )}
          {claiming && !justClaimed && (
            <ActionLoading
              title="Claiming Your NFT"
              subTitle={
                <div>
                  <p>
                  Your NFT is currently being claimed.
                  </p>
                  {txHash && <p>
                    View your transaction on <a className="text-green-03" href={`https://etherscan.io/tx/${txHash}`} target="_blank">Etherscan</a>
                  </p>}
                </div>
              }
            />
          )}
        </div>
      }
    />
  );
  }
)

export default ClaimNFTModal;

const useClaimModal = ({ claims, root, tokenTotal }, address, closeModal, setIsClaimed) => {
  const claimableNFTDistributorContract = useclaimableNFTDistributorContract();
  const userNode = claims[address]
  const claimNFT = useClaimNFT({merkleRoot: root, nodeIndex: userNode.index, amount: userNode.amount, proofs: userNode.proof});
  const [claiming, setClaiming] = useState(null);
  const [justClaimed, setJustClaimed] = useState(null);
  const [txHash, setTxHash] = useState("");

  const onSuccess = (hash) => {
    setClaiming(null);
    setJustClaimed(hash);
    setIsClaimed(true)
  };
  const claimTransactionHandler = useTransactionHandler(
    "claimNFT",
    onSuccess,
    setClaiming,
    () => closeModal(),
    setTxHash
  );

  useDeepCompareEffect(() => {
    if (!claims || !root || !tokenTotal || !address) return null;
    let isMounted = true;
    (async () => {
      claimCTA()
      // await new Promise((resolve) => setTimeout(resolve, 3000));
      if (isMounted) setJustClaimed(false);
    })();
    return () => {
      isMounted = false;
    };
  }, [claims, root, tokenTotal, address]);

  const claimCTA = async () => {
    setClaiming("loading");
    await claimNFT(claimTransactionHandler);
  } 

  const claimCTADisabled = Boolean(
    claiming || !claimableNFTDistributorContract
  );


  return {
    claimCTA,
    claimCTADisabled,
    claiming,
    justClaimed,
    txHash,
  };
};
