import * as ClaimableNFTDistributor from "../ABIs/ClaimableNFTDistributor.json";
/**
 * A contract call wrapper that handles notifications and UI state updates that happen during the transaction loading and success stages, includes edge case handling like tx speedup, cancel and error
 * @param functionToWatch Contract call we want to track
 * @param transactionHandler ransaction handler object created with useTransactionHandler() hook
 */

import { useContract } from "hooks/useContract";

export const createNewTransactionWithHandler = async (
  functionToWatch: any,
  transactionHandler: any,
) => {
  const callTransaction = () => {
    try {
      functionToWatch()
        .on("transactionHash", (e: any) => {
          console.log({ e });
          transactionHandler.onHash(e);
        })
        .on("receipt", (e: any) => transactionHandler.onSuccess(e.transactionHash))
        .on("error", (e: any) => transactionHandler.onError(e.message));
    } catch (err) {
      transactionHandler.onError(err);
    }
  };
  return await callTransaction();
};

export const useclaimableNFTDistributorContract = () => useContract("ClaimableNFTDistributor", ClaimableNFTDistributor.abi, "contract");