import React from "react";

import CloseModalIcon from "assets/x.svg";
import LoadingAnimation from "components/LoadingAnimation";
import LoaderComplete from "assets/LoaderComplete.svg";

const FullScreenModal = ({
  onClose,
  footerCTA,
  footerCopy = <div></div>,
  mainContent,
}) => {
  return (
    <>
      <div
        className="bg-white z-40 fixed left-0 top-0 right-0 py-5 cursor-pointer px-6 flex justify-end"
        onClick={onClose}
      >
        <img src={CloseModalIcon} className="h-5 w-5 " />
      </div>
      <div className="bg-white bg-opacity-97 z-40 fixed left-0 top-0 h-screen right-0 mt-14 pb-44 flex-1 flex">
        {mainContent}
      </div>
      <div className="bg-white z-40 fixed left-0 bottom-0 border-top border-1 right-0 shadow-stickyBottom py-8 px-6">
        <div className="flex flex-row xs:flex-col-reverse flex-wrap xs:flex-wrap type-p-sm font-medium justify-between items-center xs:items-start">
          {footerCopy}
          {footerCTA}
        </div>
      </div>
    </>
  );
};

export default FullScreenModal;

export const ActionLoading = ({
  title = null,
  subTitle = null,
}) => {
  return (
    <div className="min-h-screen flex flex-col justify-center ">
      <LoadingAnimation />
      <div className="type-h4 text-green-02 type-medium text-center mb-3 mt-5">{title}</div>
      <div className="type-p-sm type-light text-center lg:max-w-sm mx-auto">
        {subTitle}
      </div>
    </div>
  );
};

export const ActionSuccess = ({
  title = null,
  subTitle = null,
}: {
  title?: string | React.ReactNode | null;
  subTitle?: string | React.ReactNode | null;
}) => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <img
        alt="Loader Complete"
        src={LoaderComplete}
        height="177"
        width="177"
        className="d-inline-block align-top mx-auto mb-5"
      />
      <div className="type-h4 text-green-02 type-medium text-center mb-3">{title}</div>
      <div className="type-p-sm type-light text-black text-center lg:max-w-sm mx-auto whitespace-normal">
        {subTitle}
      </div>
    </div>
  );
};
