import React from "react";
import loading_animation from "../../assets/loading_animation.json";
import success_animation from "../../assets/success_animation.json";
import Lottie from "react-lottie";
const LoadingAnimation = ({ isStopped, animation = "loading" }) => {
    const animationData = animation === "loading" ? loading_animation : success_animation;
    const isLooping = animation === "loading" ? true : false;
    return (<div style={animation === "loading" ? { margin: "-8px" } : {}}>
      <Lottie options={{
        loop: isLooping,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    }} isClickToPauseDisabled={true} width={animation === "loading" ? 36 : 65} height={animation === "loading" ? 36 : "auto"} isStopped={isStopped}/>
    </div>);
};
export default LoadingAnimation;
