import { createNewTransactionWithHandler } from "actions/utils";
import React, { useCallback, useContext, useMemo } from "react";
import { dappContext } from "contexts/DappContext/DappContext";
import { useclaimableNFTDistributorContract } from "actions/utils"


export const useClaimNFT = ({merkleRoot, nodeIndex, amount, proofs}) => {
  const {
    address
  } = useContext(dappContext);
  const claimableNFTDistributorContract = useclaimableNFTDistributorContract();
  const claimNFT = useCallback(
    async (transactionHandler) => {
      if (!claimableNFTDistributorContract) return;
      console.log({merkleRoot, address, nodeIndex, amount, proofs})
      await createNewTransactionWithHandler(
        () =>
          claimableNFTDistributorContract.methods
            .claim(merkleRoot, address, nodeIndex, amount, proofs)
            .send({ from: address }),
        transactionHandler,
      );
    },
    [merkleRoot, address, nodeIndex, amount, proofs, claimableNFTDistributorContract],
  );

  return claimNFT;
};
