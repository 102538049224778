import classNames from 'classnames';
import React, { useEffect } from 'react';
import 'utils/lazyLoad';

const LazyImage = ({ className, src, ...rest }) => {

  useEffect(() => {
    document.lazyLoadInstance.update();
  }, []);

  return (
    <img
      className={classNames(className, 'js-lazyLoad')}
      data-src={src}
      {...rest}
    />
  );
};

LazyImage.defaultProps = {
  className: undefined,
};

export default LazyImage;
