export function truncate(n) {
  const length = this.length;
  if (length <= n) {
    return this;
  }
  return `${this.substr(0, n - 1)}...${this.substr(length - 4, length)}`;
}

export const formatWalletAddress = (address) => {
  return truncate.apply(address, [6]);
};
