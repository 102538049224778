export const addresses = {
  Gnosis: {
    1: "0x95143890162Bd671D77aE9B771881a1cb76C29A4",
    4: "0x29B319d9b7b5934bc4771812b7B9247cAE598aeb",
  },
  Link: {
    1: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    4: "",
  }
};
