import React from "react";

export const ModalFooterText = ({ text, highlightedText, href = null }) => {
  return (
    <div className="flex flex-row flex-wrap type-p-sm font-medium">
      <h3 className="text-green-02 whitespace-pre xs:whitespace-normal">{`${text} `}</h3>

      <h3 className={`text-green-03 ${href ? "underline" : ""}`}>
        {href ? (
          <a href={href} target="_blank" rel="noreferrer">
            {highlightedText}
          </a>
        ) : (
          <>{highlightedText}</>
        )}
      </h3>
    </div>
  );
};

export default ModalFooterText;
